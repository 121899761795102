import React from "react";
import "../css/stylesReact.css";
import { fetchCMS } from "../fetch/cms/main";

export const TermsAndConditions = () => {
  const { data } = fetchCMS();
  const __html = data?.termsAndConditions;

  return (
    <container style={{ minHeight: "100vh", marginTop: "3rem" }}>
      <div className="CMS_CONTENT">
        <h5 style={{ fontSize: "2rem", fontWeight: "bold", color: "#0070c0" }}>
          TERMS & CONDITIONS
        </h5>
        <div
          className="termsAndConditionCMS"
          style={{ fontSize: "1.5rem", listStyleType: "circle" }}
          dangerouslySetInnerHTML={{ __html }}
        />
      </div>
    </container>
  );
};
