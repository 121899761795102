import axios from "axios";
import { toast } from "react-toastify";

const envVite = import.meta.env?.MODE;

const envUrl = !!envVite
  ? import.meta.env?.VITE_AXIOS_URL
  : process.env.REACT_APP_AXIOS_URL;

const env = envVite || process.env.NODE_ENV;

const URL = env == "development" ? "http://localhost:3000" + envUrl : envUrl;

// console.log(URL);
// console.log(process.env.NODE_ENV);

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = URL;

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("Auth");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log(error);
    const originalRequest = error.config;
    if (error.response.status === 401) {
      // router.push("/login");
      window.location.href = "/login";
      return Promise.reject(error);
    }
    if (error.response.data?.errors?.error?.includes("4 weeks")) {
      toast.warn("Vacancy expired 4 weeks ago");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
