import React from "react";

import { fetchCMS } from "../fetch/cms/main";

export const AboutUs = () => {
  const { isLoading, error, data } = fetchCMS();

  const aboutus = data?.works?.aboutus;

  // TODO: LOADING COMPONENT?

  if (isLoading) return <>"LOADING"</>;
  if (error) return <>error</>;

  return (
    <>
      <section class="about">
        <div class="container">
          <div class="row">
            {/* {[...aboutus?.array, aboutus?.array[0]]?.map((val) => ( */}
            {aboutus?.array?.map((val) => (
              <div
                class="col-lg-4 col-sm-6"
                style={{
                  textAlign: "center",
                  marginInline: "auto",
                }}
              >
                <div style={{ height: "15rem" }}>
                  <img
                    src={val.img}
                    alt=""
                    style={{ height: "8rem", marginBottom: "1rem" }}
                  />
                  <h6
                    style={{
                      fontSize: "3rem",
                      marginBottom: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {val.title}
                  </h6>
                </div>
                <div
                  style={{
                    height: "100%",
                    // backgroundColor: "blanchedalmond",
                  }}
                >
                  <div
                    className="para3rem"
                    dangerouslySetInnerHTML={{
                      __html: val.content,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section class="about2">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <img src={aboutus?.story?.img} alt="" />
            </div>
            <div
              class="col-lg-6"
              style={{
                height: "100%",
                // backgroundColor: "green",
                margin: "auto",
              }}
            >
              <h6
                style={{
                  fontSize: "3rem",
                  marginBottom: "1rem",
                  fontWeight: "bold",
                }}
              >
                {aboutus?.story?.title}
              </h6>

              <div
                className="innerParaJustify"
                style={{
                  fontSize: "3rem",
                  height: "100%",
                  margin: "auto",
                }}
                dangerouslySetInnerHTML={{ __html: aboutus?.story?.content }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
