import React, { useEffect } from "react";
import { fetchEconsultancyBookings } from "../../fetch/econsultancy/all";
import {
  dateFormatter,
  durationFinder,
  timeFormatter,
  idGenerator,
} from "../../utils/helper";
import { FreelancerBoxLoading } from "../../Components/Freelancer/FreelancerBoxLoading";
import { NoData } from "../../Components/NoData";
import { useNavigate } from "react-router-dom";

const EconsultationTable = () => {
  const { isLoading, error, data } = fetchEconsultancyBookings();
  const navigate = useNavigate();

  const styles = (isReceive) => {
    return { background: isReceive ? "#E7FFC8" : "white" };
  };

  return (
    <section style={{ margin: "1rem" }} className="company1 mb-5">
      <div className="container">
        <div className="bg-color">
          <div className="row">
            <div className="col-6">
              <h3 className="head">e-Consultation Bookings</h3>
            </div>
            <div className="col-6">
              <p className="title-tag">e-Consultant</p>
            </div>
            <div className="col-6 legend">
              <div>
                <div
                  style={{
                    background: "#E7FFC8",
                  }}
                ></div>
                <p>Booked by you</p>
              </div>
              <div>
                <div style={{ background: "white" }}></div>{" "}
                <p>You were booked</p>
              </div>
            </div>
          </div>
          {isLoading && !data?.econsultancyBookings?.length ? (
            <FreelancerBoxLoading />
          ) : (
            !data?.econsultancyBookings?.length && (
              <NoData description="No econsultation bookings" />
            )
          )}
          {!!data?.econsultancyBookings.length && (
            <table className="table">
              <tbody>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Organization</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>Time-Zone</th>
                  <th>Total Fees</th>
                  {/* <th>
                    Total Fees <br /> Paid/ <br /> Received
                  </th> */}
                  {/* <th>Status</th>
                  <th>My Notes</th> */}
                </tr>
                {data?.econsultancyBookings?.map((econsultancy, index) => (
                  <tr
                    className="table-row"
                    style={styles(
                      data?.userId == econsultancy.bookedBy ? true : false
                    )}
                  >
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          `/econsultancy/booking/preview/${econsultancy.id}`
                        )
                      }
                    >
                      {idGenerator(econsultancy?.id)}
                    </td>
                    {data?.userId == econsultancy.bookedBy ? (
                      <td>
                        {econsultancy.freelancer?.user?.firstName}{" "}
                        {econsultancy.freelancer?.user?.lastName}
                      </td>
                    ) : (
                      <td>{econsultancy.clientInformation?.name}</td>
                    )}

                    {data?.userId == econsultancy.bookedBy ? (
                      <td>{econsultancy.freelancer?.companyName}</td>
                    ) : (
                      <td>{econsultancy.clientInformation?.organization}</td>
                    )}

                    {data?.userId == econsultancy.bookedBy ? (
                      <td>{econsultancy.freelancer?.user?.email}</td>
                    ) : (
                      <td>{econsultancy.clientInformation?.email}</td>
                    )}

                    {data?.userId == econsultancy.bookedBy ? (
                      <td>{econsultancy.freelancer?.user?.phone}</td>
                    ) : (
                      <td>{econsultancy.clientInformation?.phone}</td>
                    )}

                    <td>
                      {dateFormatter(
                        econsultancy.bookingDetails?.preferenceDate
                      )}
                    </td>
                    <td>{econsultancy.bookingDetails?.startTime}</td>

                    <td>
                      {!!parseInt(
                        durationFinder(
                          econsultancy.bookingDetails?.startTime,
                          econsultancy.bookingDetails?.endTime
                        )
                      ) &&
                        parseInt(
                          durationFinder(
                            econsultancy.bookingDetails?.startTime,
                            econsultancy.bookingDetails?.endTime
                          )
                        ) + " h "}
                      {(durationFinder(
                        econsultancy.bookingDetails?.startTime,
                        econsultancy.bookingDetails?.endTime
                      ) %
                        1) *
                        60 &&
                        (durationFinder(
                          econsultancy.bookingDetails?.startTime,
                          econsultancy.bookingDetails?.endTime
                        ) %
                          1) *
                          60 +
                          " m"}
                    </td>
                    <td>{econsultancy.bookingDetails?.timezone}</td>
                    <td>${econsultancy.totalFees}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </section>
  );
};

export default EconsultationTable;
