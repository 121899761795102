import React from "react";

export const FreelancerBoxLoading = () => {
  return (
    <section className="mt-5 f-list-2">
      <div className="container">
        <div className="bg-col">
          <div className="row" style={{ height: "25rem" }}>
            <div
              className="col-lg-2 fist"
              style={{
                margin: "auto",
                width: "10rem",
                display: "flex",
                flexDirection: "column",
                gap: "3rem",
              }}
            >
              <Puff />
            </div>
            <div style={{ textAlign: "center" }}>
              <h5 style={{ fontSize: "1.5rem" }}>Loading...</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function Puff() {
  return (
    <>
      <svg
        // width="44"
        // height="44"
        viewBox="0 0 44 44"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#000"
      >
        <g fill="none" fillRule="evenodd" strokeWidth="2">
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="0s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="0s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
          <circle cx="22" cy="22" r="1">
            <animate
              attributeName="r"
              begin="-0.9s"
              dur="1.8s"
              values="1; 20"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1"
              repeatCount="indefinite"
            />
            <animate
              attributeName="stroke-opacity"
              begin="-0.9s"
              dur="1.8s"
              values="1; 0"
              calcMode="spline"
              keyTimes="0; 1"
              keySplines="0.3, 0.61, 0.355, 1"
              repeatCount="indefinite"
            />
          </circle>
        </g>
      </svg>
    </>
  );
}
