import { saveAs } from "file-saver";
import { toast } from "react-toastify";

export const handleDownload = async (url, filename, navigate) => {
  try {
    const fileName = filename.split(" ").join("-") + "-Mena-Freelancer";
    const response = await fetch(url);
    const blob = await response.blob();
    // console.log(blob);
    // console.log(blob.type);
    const mimeType = blob?.type;
    if (mimeType != "text/html") {
      const extension = getExtensionFromMimeType(mimeType);
      const saveAsFileName =
        fileName + `${extension?.length ? "." + extension : ""}`;
      //   console.log(saveAsFileName, extension);
      if (mimeType != "application/pdf") {
        toast.warn("Resume is not in pdf format");
        window.location.href = url;
        return;
        return window.open(url, "_blank");
      }
      saveAs(blob, saveAsFileName);
    } else {
      toast.error("Error downloading the resume");
    }
    return;
  } catch (error) {
    console.log(error);
    return toast.error("Error downloading the resume");
  }
};

const getExtensionFromMimeType = (mimeType) => {
  const parts = mimeType.split("/");
  if (parts.length === 2) {
    return parts[1];
  }
  return "";
};
