import { useEffect, useState } from "react";
import { fetchFreelancers, searchFreelancers } from "../fetch/freelancer/all";
import { FreelancerBox2 } from "../Components/Freelancer/FreelancerBox2";
import { FreelancerSearch } from "../Components/Freelancer/FreelancerSearch";
import { FreelancerBoxLoading } from "../Components/Freelancer/FreelancerBoxLoading";
import { fetchFreelancerProfile } from "../fetch/freelancer/profile";
import { fetchFavFreelancers } from "../fetch/freelancer/fav";
import { NoData } from "../Components/NoData";
import PaginationView from "../Components/Freelancer/PaginationView";
const Freelancer = () => {
  const [page, setPage] = useState(0);
  const [filter, setFilters] = useState();
  const [totalPages, setTotalPages] = useState(-1);

  const { isLoading, error, data } = filter
    ? searchFreelancers(filter)
    : fetchFreelancers({ page });

  useEffect(() => {
    if (!isNaN(data?.totalPages)) {
      if (data?.totalPages != totalPages) {
        setTotalPages(data?.totalPages);
      }
    }
  }, [data]);

  const nextPage = page < totalPages - 1;
  // prefetch uncomment for smoother transition to next page but with heavier load on server
  // filter
  //   ? searchFreelancers(
  //       nextPage
  //         ? { ...filter, page: filter?.page ? filter.page + 1 : 1 }
  //         : { ...filter, page: filter?.page ? filter.page : 1 }
  //     )
  //   : fetchFreelancers(nextPage ? { page: page + 1 } : { page });

  const { data: freelancerprofile } = fetchFreelancerProfile();
  const favoriteFreelancers = freelancerprofile?.data?.favoriteFreelancers;

  fetchFavFreelancers();

  useEffect(() => {
    if (filter?.page) {
      if (filter?.page != page) {
        setPage(filter?.page);
      }
    } else {
      if (page != 0) {
        setPage(0);
      }
    }
  }, [filter]);

  return (
    <>
      <FreelancerSearch setFilters={setFilters} />
      <PaginationView
        page={page}
        totalPages={totalPages}
        gotoPage={(goto) => {
          filter
            ? setFilters((prev) => ({
                ...prev,
                page: goto,
              }))
            : setPage(goto);
        }}
      />
      <section className="company1">
        <div className="container">
          <div>
            <h1
              className="head "
              style={{
                fontWeight: "bold",
                fontSize: "1.8rem",
                textAlign: "center",
              }}
            >
              {/* {!filter ? <> Freelancers</> : <>Search Result</>} */}
            </h1>
          </div>
        </div>
      </section>
      {isLoading && <FreelancerBoxLoading />}
      {!isLoading && !data?.data?.length && (
        <NoData description={"No freelancers found."} />
      )}

      {data?.data?.map((freelancer) => (
        <>
          <FreelancerBox2
            freelancer={freelancer}
            key={freelancer.id}
            fav={favoriteFreelancers?.includes(freelancer?.id)}
            preview={freelancerprofile?.data?.id == freelancer?.id}
          />
        </>
      ))}

      {/* show pagination in bottom if number of freelancers is more that 5 */}
      {data?.data?.length > 5 && (
        <PaginationView
          page={page}
          nextPage={nextPage}
          totalPages={totalPages}
          gotoPage={(goto) => {
            filter
              ? setFilters((prev) => ({
                  ...prev,
                  page: goto,
                }))
              : setPage(goto);
          }}
        />
      )}
    </>
  );
};

export default Freelancer;
