import React from "react";
import { fetchFreelancer } from "../../fetch/freelancer/all";
import { useParams } from "react-router-dom";
import { FreelancerBox2 } from "./FreelancerBox2";
import { FreelancerBoxLoading } from "./FreelancerBoxLoading";

import { NoData } from "../NoData";

const ViewFreelancer = () => {
  let { freelancerId } = useParams();
  const { data, isLoading, error } = fetchFreelancer(freelancerId);
  if (isLoading) return <FreelancerBoxLoading />;
  if (error) return <NoData description={"No freelancer found."} />;
  if (data) return <FreelancerBox2 freelancer={data} />;
};

export default ViewFreelancer;
