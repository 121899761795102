import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import ReCAPTCHA from "react-google-recaptcha";

import axiosInstance from "../../axios/axios";
import FormBuilder from "../../Components/FormBuilder";
import { fieldsData } from "../../utils/fields";
import { connect } from "react-redux";
import { sendRequest } from "../../redux/actions";
import Actions from "../../redux/actions/types";
import { toast } from "react-toastify";
import { queryClient } from "../../routes/Reactquery";

import RecaptchaComponent from "../../Components/Recaptcha/Recaptcha";
import { getParams } from "../../helper";

const Login = (props) => {
  const navigate = useNavigate();
  let input = {};
  const [isNotRobo, setIsNotRobo] = useState(false);
  const captchaRef = useRef(null);
  const [data, setData] = useState({});

  const isEmailVerified = getParams("emailverified") == "true";

  const loginUser = async () => {
    props
      .sendRequest(Actions.LOGIN, "/auth/login", {}, "POST", data)
      .then(async (res) => {
        if (
          res?.error?.response?.data?.message ==
          `User is not approved by the admin`
        ) {
          return toast.warning("User profile is under verification.");
        }
        if (res.error) toast.error(res?.error?.response?.data?.errors?.message);
        else {
          console.log(res?.payload?.data?.data);
          if (res?.payload?.data?.data?.token) {
            localStorage.setItem("Auth", res?.payload?.data?.data?.token);
          }
          console.log(res?.payload?.data?.data?.token);
          queryClient.invalidateQueries([`vacancies`]);
          queryClient.invalidateQueries([`freelancers`]);
          // await queryClient.invalidateQueries();
          if (isEmailVerified)
            return navigate("/freelancerprofile?firsttime=true");
          navigate("/freelancers");
        }
        //TODO: add login navigation
      })
      .catch((e) => {
        console.log("catch ", e);
      });
  };

  const mutation = useMutation(loginUser, {
    onSuccess: (data) => {},
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  async function Login(e) {
    e.preventDefault();
    if (!isNotRobo) return toast.warning("Please select I'm not a robot");
    const fields = fieldsData.SIGN_IN;
    for (var i = 0; i < fields.length; i++) {
      if (data[fields[i].name]) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (data[fields[i].name]?.length < fields[i]?.min) {
              console.log(fields[i]);
              toast.warn(
                <span>
                  <b>{fields[i]?.label}</b> should have minimum {fields[i].min}{" "}
                  characters
                </span>
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field =
          fields[i].name.split(".").length > 1
            ? fields[i].name.split(".")[1]
            : fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }
    mutation.mutate(data);
  }

  return (
    <>
      {isEmailVerified && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginInline: "3rem",
            color: "#0e71b9",
            fontSize: "1.5em",
            fontWeight: "bold",
            paddingTop: "30px",
          }}
        >
          You've successfully verified your email. You can now log in to
          continue using our services.
        </div>
      )}
      <section className="sign-log">
        <div className="container">
          <div className="box-center">
            <div className="box-bg">
              <h3>Account Login</h3>
              <form onSubmit={Login}>
                <div className="login-list-flex">
                  <div className="box">
                    <p> Email *</p>
                    <input
                      type="email"
                      name="email"
                      className="input"
                      required
                      onChange={handleChange}
                      onInput={handleChange}
                    />
                  </div>
                  <div className="box">
                    <p> Password *</p>
                    <input
                      type="password"
                      name="password"
                      className="input"
                      required
                      onChange={handleChange}
                      onInput={handleChange}
                    />
                  </div>
                  <div className="login-btn-flex">
                    <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                    <a href="#" onClick={() => navigate("/forgot")}>
                      Forgot Password
                    </a>
                  </div>
                  <div>
                    <button type="submit">Sign in</button>
                  </div>
                  <p className="title">
                    Doesn't have an account? &nbsp;
                    <a href="#" onClick={() => navigate("/signup")}>
                      Sign up
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  ...state.login,
});

export default connect(mapStateToProps, { sendRequest })(Login);
