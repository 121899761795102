import React from "react";
import "../css/stylesReact.css";
import { fetchCMS } from "../fetch/cms/main";

export const PrivacyCookies = () => {
  const { data } = fetchCMS();
  const html = data?.privacyandcookies;
  return (
    <container style={{ minHeight: "70vh", backgroundColor: "red" }}>
      <div
        className="CMS_CONTENT"
        style={{
          wordBreak: "break-word",
        }}
      >
        <h5 style={{ fontSize: "2rem", fontWeight: "bold", color: "#0070c0" }}>
          PRIVACY
        </h5>
        <div
          style={{ fontSize: "1.5rem", listStyleType: "circle" }}
          dangerouslySetInnerHTML={{ __html: html?.privacy }}
        />
        <h5 style={{ fontSize: "2rem", fontWeight: "bold", color: "#0070c0" }}>
          COOKIES
        </h5>
        <div
          style={{ fontSize: "1.5rem", listStyleType: "circle" }}
          dangerouslySetInnerHTML={{ __html: html?.cookies }}
        />
      </div>
    </container>
  );
};
