import React from "react";
import { useState } from "react";
import { fetchInput } from "../../fetch/input/main";
import { useOnClickOutside } from "../Hook/clickOutSide";
import { useRef } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const SkillsSelect = ({
  set,
  initialData,
  horizontal = true,
  emptyInitial,
  label = "Skills",
  propose = false,
  fixOverflow,
  info,
  letters,
  searchParam,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [filterOptions, setFilteredOptions] = useState([]);
  const [showSkill, setShowSkill] = useState(true);
  const { data } = fetchInput();

  const options = data?.keySkills?.sort();
  const inputRef = useRef(null);
  const inputFieldRef = useRef(null);
  useOnClickOutside(inputRef, () => {
    setShowSkill(true);
    setSearchTerm("");
  });

  const search = (searchTerm) => {
    const regex = [
      `^${searchTerm?.toLowerCase()}`,
      `${searchTerm?.toLowerCase()}`,
    ];
    let searchResults = [];
    regex.map((check) =>
      searchResults.push(
        ...options?.filter((option) =>
          new RegExp(check).test(option?.toLowerCase())
        )
      )
    );
    // setFilteredOptions(Array.from(new Set(searchResults.slice(0, 5))));
    setFilteredOptions(Array.from(new Set(searchResults)));
  };

  const handleInputChange = (e) => {
    setShowSkill(false);
    setSearchTerm(e.target.value);
    search(e.target.value);
  };

  const handleChangeSelect = (option) => {
    setShowSkill(true);

    if (selectedSkills?.includes(option)) {
      setSelectedSkills((prev) => prev.filter((val) => val != option));
    } else {
      if (selectedSkills?.length > 9) {
        toast.warn("10 skills selected already");
        return;
      }
      setSelectedSkills((prev) => [...prev, option]?.sort());
    }

    inputFieldRef.current.focus();
  };

  useEffect(() => {
    if (selectedSkills) set(selectedSkills);
  }, [selectedSkills]);

  useEffect(() => {
    console.log(initialData);
    console.log(selectedSkills);
    if (initialData && !selectedSkills.length) {
      console.log("FIRING");
      setSelectedSkills(initialData);
    }
  }, []);

  useEffect(() => {
    console.log("selectedSkills", selectedSkills);
    console.log("emp", emptyInitial);

    if (
      initialData?.length &&
      JSON.stringify(initialData) != JSON.stringify(selectedSkills)
    ) {
      setSelectedSkills(initialData);
    }
    if (emptyInitial) {
      if (!initialData?.length && selectedSkills?.length) {
        console.log("EMPTY");
        setSelectedSkills([]);
      }
    }
  }, [initialData]);

  function skillsOfLetter(letter) {
    const matchingValues = options?.filter((value) =>
      value?.startsWith(letter?.toUpperCase())
    );
    setFilteredOptions(matchingValues);
    setSearchTerm(letter);
    console.log(matchingValues);
  }

  const A2Z = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  if (data)
    return (
      <div className="box" ref={inputRef}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            gap: "3rem",
          }}
        >
          <div>
            <p>{label}</p>
            {info ? (
              <>
                <p style={{ fontWeight: "normal" }}>{info}</p>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <input
          type="text"
          name="city "
          className="input"
          id="checkbox-input"
          ref={inputFieldRef}
          autoComplete="off"
          value={searchTerm}
          onClick={() => {
            setShowSkill(false);
            // setSearchTerm("");
            setSearchTerm("");
          }}
          onChange={handleInputChange}
          placeholder="Search for skills."
          style={{ overflow: "hidden" }}
        />

        {letters && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p>
                Click on a letter to see all skills starting with that letter.
              </p>
              {propose && (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <a
                    style={{
                      color: "#345fb4",
                      cursor: "pointer",
                      fontSize: "1.1rem",
                    }}
                    href={`mailto:info@menafreelancer.com`}
                  >
                    Propose to add a new skill to skills database HERE
                  </a>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                gap: "2rem",
                flexWrap: "wrap",
                width: "100%",
                margin: "auto",
                justifyContent: "center",
              }}
            >
              {A2Z.map((letter) => (
                <button
                  type="button"
                  style={{
                    paddingInline: "1rem",
                    paddingBlock: "0.3rem",
                    backgroundColor: "lightblue",
                    borderRadius: "5px",
                    position: "relative",
                    zIndex: 1,
                    marginBlock: "1rem",
                    cursor: "pointer",
                    color: "black",
                    width: "2rem",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => skillsOfLetter(letter)}
                >
                  {letter}
                </button>
              ))}
            </div>
          </div>
        )}

        <div
          id="checkbox-list"
          style={{
            display: "flex",
            gap: ".5rem",
            flexWrap: "wrap",
            flexDirection: "column",
            width: "max-content",
            minWidth: !searchParam ? "100%" : "30rem",
            maxWidth: searchParam ? "10vw" : "100%",
            marginBottom: "1rem",
          }}
        >
          {searchTerm ? (
            <>
              <div class="">
                {/* <div class="bg-color"> */}
                <label style={{ color: "#8e8e8e" }}>
                  Clear the search input to see selected skills.
                </label>
                {/* </div> */}
              </div>
              {!filterOptions?.length && (
                <>
                  <p style={{ paddingBottom: "3px", fontSize: "1.3rem" }}>
                    {/* No matching skills found based on your input.  */}
                    we could not find a match for your searched skills.
                  </p>
                </>
              )}
              <div
                style={{
                  maxHeight: "30rem",
                  overflowY: filterOptions.length > 10 ? "scroll" : "hidden",
                  overflowX: "hidden",
                }}
              >
                {filterOptions?.map((option, index) => (
                  <div
                    key={option}
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      marginRight: "3rem",
                    }}
                  >
                    <input
                      name="city"
                      type="checkbox"
                      id={`option${index}`}
                      onChange={() => handleChangeSelect(option)}
                      checked={selectedSkills?.includes(option)}
                    />
                    <label
                      htmlFor={`option${index}`}
                      style={{ fontSize: "medium" }}
                    >
                      {option}
                    </label>
                  </div>
                ))}
              </div>
              <div className="">
                <div className="">
                  <div
                    type="div"
                    style={{ backgroundColor: "#00000000", border: "none" }}
                    onClick={() => {
                      setSearchTerm("");
                    }}
                  >
                    <a
                      style={{
                        width: "10rem",
                        padding: ".5rem",

                        backgroundColor: "#0e71b9",
                        color: "white",

                        display: "inline-block",
                        position: "relative",
                        color: " #fff",
                        // backgroundColor: "var(--bgcolor)",
                        border: " 1px solid var(--bgcolor)",
                        borderRadius: "8px",
                        zIndex: 1,
                        overflow: "hidden",
                        transition: "0.5s",
                        textTransform: "uppercase",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      Add skill{" "}
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: horizontal ? "row" : "column",
                gap: horizontal ? "1rem" : ".5rem",
                flexWrap: "wrap",
                marginBottom: "2rem",
                width: "70vw",
                maxWidth: fixOverflow ? "100%" : "",
              }}
            >
              {selectedSkills?.sort()?.map(
                (option, index) => (
                  // !showSkill && (

                  <div
                    key={option}
                    style={{
                      display: "flex",
                      gap: ".5rem",
                      padding: ".5rem",
                      backgroundColor: "#00000010",
                    }}
                  >
                    <input
                      name="city"
                      type="checkbox"
                      id={`option${index}`}
                      onChange={() => handleChangeSelect(option)}
                      checked={selectedSkills.includes(option)}
                    />
                    <label
                      htmlFor={`option${index}`}
                      style={{ fontSize: "small" }}
                    >
                      {option}
                    </label>
                  </div>
                )
                // )
              )}
            </div>
          )}
        </div>
      </div>
    );
};

export default SkillsSelect;
