import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const isLoggedIn = !!localStorage.getItem("Auth");

  const links = {
    facebook: "https://www.facebook.com/XLTechnologies/",
    instagram: "https://www.instagram.com/xltechnologies/",
    twitter: "https://twitter.com/xltechgbl",
    linkedin: "https://www.linkedin.com/company/mena-freelancer/",
    youtube:
      "https://www.youtube.com/channel/UCRz27T08WnCQDikPmFHYD3g/featured",
  };

  const FooterLinks = [
    {
      title: "For Business",
      links: [
        { href: "/freelancers", text: "Freelancers List" },
        { href: "/favorite-freelancers", text: "Favourite Freelancers" },
        { href: "/vacancies-posted", text: "Posted Vacancies" },
        { href: "/vacancies/create", text: "Post Vacancy" },

        // { href: "/", text: "How to Hire" },
        // { href: "/freelancers", text: "Talent Marketplace" },
        // { href: "#", text: "Project Catalog" },
        // { href: "#", text: "Talent Scout" },
        // { href: "/freelancers", text: "Hire an agency" },
        // { href: "/freelancers", text: "Hire Worldwide" },
      ],
    },
    {
      title: "For Freelancer",
      links: [
        { href: "/vacancies", text: "Vacancies List" },
        { href: "/vacancies-applied", text: "Applied To Vacancies" },
        { href: "/econsultancy/bookings", text: "e-Consultant Booking" },
        { href: "/invite", text: "Invite Friend" },

        // { href: "#", text: "How to Find work" },
        // { href: "#", text: "Direct contracts" },
        // { href: "#", text: "Find Freelance Jobs Worldwide" },
      ],
    },
    // {
    //   title: "Resources",
    //   links: [
    //     // { href: "#", text: "Help & Support" },
    //     // { href: "#", text: "Success Stories" },
    //     // { href: "#", text: "Resources" },
    //     // { href: "#", text: "Blog" },
    //     // { href: "#", text: "Community" },
    //     // { href: "#", text: "FreeBusiness tools" },

    // },
    {
      title: "Company",
      links: [
        { href: "/about", text: "About Us" },
        { href: "/contactus", text: "Contact Us" },
        { href: "/privacy-and-cookies", text: "Privacy and Cookies" },
        { href: "/terms-and-conditions", text: "Terms & Conditions" },
      ],
      // { href: "/invite", text: "Invite friend" },
    },
    {
      title: "My Account",
      links: [
        { href: "/profile", text: "Manage Account" },
        { href: "/freelancerprofile", text: "Freelancer Profile" },
        { href: "/econsultationsettings", text: "e-Consultation Settings" },
        {
          href: "/help-and-support",
          text: "Help and Support",
        },
        !isLoggedIn && { href: "/login", text: "Login" },
        isLoggedIn && { href: "/login", text: "Logout", logout: true },
        // !isLoggedIn && { href: "/signup", text: "Sign up" },
      ],
    },
  ];

  const navigation = useNavigate();
  return (
    <footer className="footer">
      <div className="container">
        <div className="cards row">
          {FooterLinks.map((section) => (
            <div key={section.title} className="box col-lg-2 col-md-2 col-sm-4">
              <h6
                style={{
                  color: "white",
                  fontSize: "2rem",
                  marginBottom: "2rem",
                }}
              >
                {section.title}
              </h6>
              <div className="links">
                {section.links.map((links, idx) => (
                  <a
                    key={`${idx}${links.href}${links.text}`}
                    style={{
                      color: "#ddd",
                      fontSize: "1.6rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (links.logout) {
                        localStorage.clear();
                      }
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      navigation(links.href);
                    }}
                  >
                    {links.text}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="media">
          <div className="row">
            <div className="col-12">
              <div className="media-box">
                <h6>follow us</h6>
                <a
                  href="#"
                  onClick={() => window.open(links.linkedin, "_blank")}
                >
                  <i className="fa-brands fa-linkedin-in"></i>
                </a>
                {/* <a href={links.facebook}>
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href={links.instagram}>
                  <i className="fa-brands fa-instagram"></i>
                </a>
                <a href={links.twitter}>
                  <i className="fa-brands fa-twitter"></i>
                </a>

                <a href={links.youtube}>
                  <i className="fa-brands fa-youtube"></i>
                </a> */}
              </div>
            </div>
            <div className="copyright">
              <p style={{ color: "white", fontSize: "1.3rem" }}>
                Copyright © 2023 MENA Freelancer.All Right Reserved
              </p>
              <a
                style={{ color: "white", fontSize: "1.3rem" }}
                href="https://xltechglobal.com/"
              >
                Powered By XL Technologies
              </a>
            </div>
            <div className="col-sm-6 col-12"></div>
            <div className="col-sm-6 col-12"></div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
