const PaginationView = ({ page, totalPages, gotoPage }) => {
  const buttonStyle = {
    backgroundColor: "#666",
    color: "white",
    paddingInline: ".5rem",
    // paddingBlock: ".7rem",
    fontSize: "1.2rem",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",

    backgroundColor: "#e8e8e8 ",
    color: "#8e8e8e ",
  };

  const prevExist = page > 0;
  const nextPage = page < totalPages - 1;

  const pageNums = !isNaN(totalPages) ? parseInt(totalPages) : 99999;

  function generateNumbersAround(targetNumber, range, totalPages) {
    const numbersArray = [];

    const missingAtEnd = range - (totalPages - targetNumber - 1);
    const addExtra = missingAtEnd >= 0 ? missingAtEnd : 0;

    console.log("addExtra", addExtra);

    for (let i = targetNumber - range - addExtra; i < targetNumber; i++) {
      if (i >= 0) {
        numbersArray.push(i);
      }
    }

    numbersArray.push(targetNumber);

    for (
      let i = targetNumber + 1;
      i <= targetNumber + range || numbersArray.length < 10;
      i++
    ) {
      if (i >= totalPages) break;
      numbersArray.push(i);
    }

    return numbersArray;
  }

  const range = 5;

  const resultArray = generateNumbersAround(page, range, pageNums);

  if (totalPages > 1)
    return (
      <div>
        <div
          style={{
            display: "flex",
            fontSize: "2rem",
            justifyContent: "center",
            textAlign: "center",
            gap: "2rem",
            marginBlock: "1rem",
            flexWrap: "wrap",
            // backgroundColor: "blue",
            alignItems: "center",
          }}
        >
          <button
            style={{
              ...buttonStyle,
              visibility: prevExist ? "visible" : "hidden",
              height: "3rem",
            }}
            onClick={() => {
              gotoPage(page - 1);
            }}
          >
            <span style={{ fontSize: "1.5rem", marginRight: ".5rem" }}>
              &lt;
            </span>
            previous
          </button>

          <div
            style={{
              minWidth: "20rem",
              display: "flex",
              // backgroundColor: "red",
              justifyContent: "center",
              gap: "1rem",
              height: "3rem",
            }}
          >
            {resultArray.map((pageNum) => (
              <>
                <button
                  style={{
                    ...buttonStyle,
                    display: "flex",
                    textAlign: "center",

                    justifyContent: "center",
                    width: "4rem",
                    backgroundColor: pageNum == page ? "#444" : "#e8e8e8 ",
                    color: pageNum == page ? "white" : "#8e8e8e ",
                    cursor: pageNum == page ? "default" : "pointer",
                  }}
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });

                    gotoPage(pageNum);
                  }}
                >
                  <span style={{ fontSize: "1.5rem", marginInline: ".5rem" }}>
                    {pageNum + 1}
                  </span>
                </button>
              </>
            ))}
          </div>

          {/* <div style={{ display: "flex", flexDirection: "column" }}>
          <label
            className="labelREACTCLICKABLE input"
            htmlFor="cars"
            style={{ fontSize: "1.2rem" }}
          >
            <select
              name="cars"
              id="cars"
              style={{ height: "2rem" }}
              className="input"
              onClick={(e) => {
                console.log(e.target.value);
                const pageNum = parseInt(e?.target?.value);
                if (isNaN(pageNum)) return;
                filter
                  ? setFilters((prev) => ({
                      ...prev,
                      page: pageNum,
                    }))
                  : setPage(pageNum);
              }}
            >
              {allPages.map((loopPage) => (
                <option value={loopPage} selected={page == loopPage}>
                  {loopPage + 1}
                </option>
              ))}
              <option selected value={""} hidden>
              </option>
            </select>
          </label>
        </div> */}
          {!!nextPage && (
            <>
              <button
                style={{ ...buttonStyle, height: "3rem" }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  gotoPage(page + 1);
                }}
              >
                next
                <span style={{ fontSize: "1.5rem", marginLeft: ".5rem" }}>
                  &gt;
                </span>
              </button>
            </>
          )}
        </div>
      </div>
    );
};

export default PaginationView;
