import { createBrowserRouter } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import NotFound from "../Components/Layout/NotFound";
import Signup from "../pages/auth/Signup";
import Login from "../pages/auth/Login";
import Contact from "../pages/ContactUs";
import Invite from "../pages/Invite";
import { AboutUs } from "../pages/AboutUs";
import Index from "../pages/Index";
import Freelancer from "../pages/Freelancer";
import FreelancerProfile from "../pages/FreelancerProfile";
import Profile from "../pages/Profile";
import VacanciesList from "../modules/Vacancy/VacanciesList";
import VacancyFormCreate from "../modules/Vacancy/VacancyFormCreate";
import VacancyForm from "../modules/Vacancy/VacancyForm";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import { PrivacyCookies } from "../pages/PrivacyAndCookies";
import FavFreelancers from "../pages/FavFreelancers";
import ForgotPassword from "../pages/auth/Forgot";
import VacancyFreelancer from "../modules/Vacancy/VacancyFreelancer";

import EconsultationBooking from "../modules/Econsultation/EconsultationBooking";
import EconsultationTable from "../modules/Econsultation/EconsultationTable";
import ViewFreelancer from "../Components/Freelancer/ViewFreelancer";
import { EconsultationPreview } from "../modules/Econsultation/EconsultationPreview";
import EconsultationMain from "../pages/Econsultationprofile";
import { HelpAndSupport } from "../pages/HelpAndSupport";

// import { DateSelector } from "../Components/Datepicker";
const router = createBrowserRouter([
  {
    path: "/signup",
    element: (
      <Layout
        swiper
        swiperData={{ page: "SignupPage", video: "video/production4.mp4" }}
      >
        <Signup />
      </Layout>
    ),
  },
  {
    path: "/login",
    element: (
      <Layout
        swiper
        swiperData={{ page: "LoginPage", video: "video/production3.mp4" }}
      >
        <Login />
      </Layout>
    ),
  },
  {
    path: "/forgot",
    element: (
      <Layout
        swiper
        swiperData={{ page: "LoginPage", video: "video/production3.mp4" }}
      >
        <ForgotPassword />
      </Layout>
    ),
  },
  {
    path: "/contactus",
    element: (
      <Layout swiper>
        <Contact />
      </Layout>
    ),
  },
  {
    path: "/invite",
    element: (
      <Layout
        swiper
        swiperData={{ page: "InvitePage", video: "video/production2.mp4" }}
      >
        <Invite />
      </Layout>
    ),
  },
  {
    path: "/about",
    element: (
      <Layout swiper>
        <AboutUs />
      </Layout>
    ),
  },
  {
    path: "/",
    element: (
      <Layout swiper>
        <Index />
      </Layout>
    ),
  },
  {
    path: "/freelancers",
    element: (
      <Layout>
        <Freelancer />
      </Layout>
    ),
  },
  {
    path: "/freelancer/:freelancerId",
    element: (
      <Layout>
        <ViewFreelancer />
      </Layout>
    ),
  },
  {
    path: "/econsultationsettings",
    element: (
      <Layout>
        <EconsultationMain />
      </Layout>
    ),
  },
  {
    path: "/freelancerprofile",
    element: (
      <Layout>
        <FreelancerProfile />
      </Layout>
    ),
  },
  {
    path: "/profile",
    element: (
      <Layout>
        <Profile />
      </Layout>
    ),
  },
  {
    path: "/vacancies",
    element: (
      <Layout>
        <VacanciesList mode="normal" />
      </Layout>
    ),
  },
  {
    path: "/vacancies/create",
    element: (
      <Layout>
        <VacancyFormCreate />
      </Layout>
    ),
  },
  {
    path: "/vacancies/:vacancyId",
    element: (
      <Layout>
        <VacancyForm mode="EDIT" />
      </Layout>
    ),
  },
  {
    path: "/vacancies-posted",
    element: (
      <Layout>
        <VacanciesList mode="posted" />
      </Layout>
    ),
  },
  {
    path: "/vacancies-posted/freelancers/:vacancyId",
    element: (
      <Layout>
        <VacancyFreelancer />
      </Layout>
    ),
  },
  {
    path: "/vacancies-applied",
    element: (
      <Layout>
        <VacanciesList mode="applied" />
      </Layout>
    ),
  },
  {
    path: "/econsultancy/booking/:freelancerId",
    element: (
      <Layout>
        <EconsultationBooking />
      </Layout>
    ),
  },

  {
    path: "/econsultancy/bookings",
    element: (
      <Layout>
        <EconsultationTable />
      </Layout>
    ),
  },
  {
    path: "/econsultancy/booking/preview/:econsultancyId",
    element: (
      <Layout>
        <EconsultationPreview />
      </Layout>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <Layout>
        <TermsAndConditions />
      </Layout>
    ),
  },
  {
    path: "/help-and-support",
    element: (
      <Layout>
        <HelpAndSupport />
      </Layout>
    ),
  },
  {
    path: "/privacy-and-cookies",
    element: (
      <Layout>
        <PrivacyCookies />
      </Layout>
    ),
  },

  {
    path: "/favorite-freelancers",
    element: (
      <Layout>
        <FavFreelancers />
      </Layout>
    ),
  },

  {
    path: "*",
    element: (
      <Layout>
        <NotFound />
      </Layout>
    ),
  },
]);

export default router;
