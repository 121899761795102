import React from "react";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { handleScrollUp } from "../../helper/scroll";
import { useMutation } from "react-query";
import axiosInstance from "../../axios/axios";
import { queryClient } from "../../routes/Reactquery";
import { toast } from "react-toastify";
import { fetchFreelancerProfile } from "../../fetch/freelancer/profile";

export const VerifyEmailButton = () => {
  const { data } = fetchProfile();
  const { data: freelancer, isLoading, error } = fetchFreelancerProfile();
  const email = data?.data?.user?.email;

  const profileMutation = useMutation(updateProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"], ["freelancerprofile"]);
    },
  });

  async function updateProfile() {
    await axiosInstance.get("/freelancer/generate-token");
  }
  if (isLoading) return;

  if (!freelancer?.data?.isUpdate?.mailVerified)
    return (
      <div>
        <a
          style={{
            cursor: "pointer",
            color: "blue",
            textDecoration: "underline",
          }}
          onClick={() => {
            if (profileMutation.isLoading) return;
            toast.promise(profileMutation.mutateAsync(), {
              pending: "Sending verification e-mail...",
              success:
                "Please check your email inbox (also check spam/junk folder).",
              error: "Some error has been occured",
            });
          }}
        >
          Verify E-mail
        </a>
      </div>
    );
};
