import { TIMES } from "./fields";

export const regexChecker = (val, regex) => {
  const regexList = {
    // email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
    phone: /^\+\d{8,}[0-9]$/g,
    default: /\w[a-z]/g,
  };
  switch (regex) {
    case regex: {
      return val.match(regexList[regex] ? regexList[regex] : regexList.default);
    }
  }
};

export const validateData = (data, errors, setErrors) => {
  Object.keys(data).map((val) => {
    if (regexChecker(data[val]?.trim(), val) == null) {
      setErrors({ ...errors, [val]: `Enter a valid ${val}` });
    } else {
      setErrors({ ...errors, [val]: null });
    }
  });
};

export const compareObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!(key in obj2) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const checkRegisteredUser = () => {
  const auth = localStorage.getItem("Auth");
  return auth ? true : false;
};

export const dateFormatter = (date) => {
  return date
    ? new Date(date).toLocaleString("en-gb", {
        day: "numeric",
        month: "long",
        year: "numeric",
      })
    : "";
};

export const timeFormatter = (time) => {
  return time ? new Date(time).toLocaleTimeString("en-US") : "";
};

export const durationFinder = (startTime, endTime) => {
  if (startTime && endTime) {
    let noOfhalfHours = parseFloat(
      (TIMES.indexOf(endTime) - TIMES.indexOf(startTime)) / 2
    );
    return noOfhalfHours;
  }
  return "";
};

export const isLoggedIn = () => !!localStorage.getItem("Auth");

export const idGenerator = (econsultancyId) => {
  const initialValue = 0;
  if (econsultancyId) {
    const returnval = econsultancyId
      ?.split("")
      .filter((x) => !isNaN(x))
      .reduce((ac, cr) => ac + cr, initialValue);
    return returnval.slice(-5);
  }
  return "";
};

export const totalFeeCalc = (t1, t2, rate) => {
  let total = 0;
  if (t1 && t2 && rate) {
    let hours = durationFinder(t1, t2);
    total = hours * rate;
    return total;
  } else {
    return "NIL";
  }
};
