import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryClient } from "../../routes/Reactquery";

import axiosInstance from "../../axios/axios";
import { fieldsData } from "../../utils/fields";
import { toast } from "react-toastify";
import { fetchInput } from "../../fetch/input/main";
import RecaptchaComponent from "../../Components/Recaptcha/Recaptcha";
import { DefaultOption } from "../../utils/components";

export default function SignUP(props) {
  const [errors, setErrors] = useState({});
  const [isNotRobo, setIsNotRobo] = useState(false);
  const [formData, setFormData] = useState({});
  const fields = fieldsData.SIGN_UP;

  const navigate = useNavigate();

  const { isLoading, error, data } = fetchInput();

  const postUser = async () =>
    await axiosInstance.post("/auth/register", formData);

  const mutation = useMutation(postUser, {
    onSuccess: (res) => {
      if (res?.data?.data?.token) {
        localStorage.setItem("Auth", res?.data?.data?.token);
      }

      queryClient.invalidateQueries([`vacancies`]);
      queryClient.invalidateQueries([`freelancers`]);
      setTimeout(() => {
        // if (res?.data?.data?.token) return navigate("/vacancies/create");
        return navigate("/freelancerprofile");
      }, 2000);
    },
    onError: (err) => {
      if (err?.response?.data?.errors?.message)
        toast.warn(err?.response?.data?.errors?.message);
      if (err?.response.data?.errors?.error)
        toast.warn(err?.response.data?.errors?.error);
    },
  });
  useEffect(() => {}, [formData]);
  const handleSignUp = (e) => {
    e.preventDefault();
    if (!isNotRobo) return toast.warning("Please select I'm not a robot");
    if (formData?.password != formData?.cnfpassword) {
      toast.error("Password and Confirm password are not same");
      return;
    }
    const fields = fieldsData.SIGN_UP;
    console.log(formData);
    for (var i = 0; i < fields.length; i++) {
      if (formData[fields[i].name]) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (formData[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                `${fields[i].name} should have minimum ${fields[i].min} characters`
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field =
          fields[i].name.split(".").length > 1
            ? fields[i].name.split(".")[1]
            : fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }
    toast.promise(mutation.mutateAsync(), {
      pending: "Signing up...",
      success:
        "Sign up successful, Please check your email inbox (also check spam/junk folder).",
      error: "Registration failed",
    });
  };

  const changeData = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <>
      <section className="sign-log">
        <div className="container">
          <div className="box-center">
            <div className="box-bg">
              <h3>sign up</h3>
              <form onSubmit={handleSignUp}>
                <div className="list-flex">
                  <div className="box">
                    <p>First Name *</p>
                    <input
                      type="name"
                      name="firstName"
                      className="input"
                      required
                      onChange={changeData}
                      maxLength={60}
                    />
                  </div>
                  <div className="box">
                    <p>Last Name *</p>
                    <input
                      type="name"
                      name="lastName"
                      className="input"
                      required
                      onChange={changeData}
                      maxLength={60}
                    />
                  </div>
                  <div className="box">
                    <p>Email *</p>
                    <input
                      type="email"
                      name="email"
                      className="input"
                      required
                      onChange={changeData}
                      maxLength={60}
                    />
                  </div>
                  <div className="box">
                    <p>Phone * (Include country code eg: 971)</p>
                    <input
                      type="number"
                      name="phone"
                      minlength="7"
                      style={{ WebkitAppearance: "none" }}
                      className="input"
                      required
                      onChange={changeData}
                      maxLength={20}
                    />
                  </div>
                  <div className="box">
                    <p>Country *</p>
                    <select
                      id="input"
                      style={{
                        marginTop: "1rem",
                        width: "100%",
                        height: "50%",
                        padding: "1rem",
                        borderRadius: "5px",
                        fontSize: "1.2rem",
                      }}
                      name="country"
                      onChange={changeData}
                      required
                    >
                      <option selected hidden disabled value={""}>
                        Select a Country
                      </option>
                      {data?.country?.map((cou) => (
                        <>
                          <option key={cou} value={cou}>
                            {cou}
                          </option>
                          {cou == "Yemen" && (
                            <option value="" disabled="disabled">
                              ------------------------------------------------------------------------------
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="box">
                    <p>City *</p>
                    <input
                      type="name"
                      name="city"
                      className="input"
                      onChange={changeData}
                      required
                    />
                  </div>
                  <div className="box">
                    <p>Password *</p>
                    <input
                      type="password"
                      name="password"
                      className="input"
                      required
                      onChange={changeData}
                      minlength={6}
                    />
                    <p style={{ fontSize: "10px", fontWeight: "bold" }}>
                      Minimum 6 characters
                    </p>
                  </div>
                  <div className="box">
                    <p>Confirm Password *</p>
                    <input
                      type="password"
                      name="cnfpassword"
                      className="input"
                      onChange={changeData}
                      required
                      minlength={6}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    // background: "white",
                    width: "max-content",
                    margin: "auto",
                    marginBottom: "2rem",
                  }}
                >
                  <div className="check-btn" style={{ display: "flex" }}>
                    <input
                      type="checkbox"
                      id="terms"
                      name="vehicle1"
                      value="Car"
                      required
                    />
                    <label htmlFor="terms">
                      I Agree to the
                      <a
                        href="#"
                        onClick={() => {
                          window.open("/terms-and-conditions", "_blank");
                        }}
                      >
                        &nbsp;Term of Use and Privacy Policy
                      </a>
                    </label>
                  </div>
                  <div>
                    <div className="login-btn-flex" style={{ margin: "0px" }}>
                      <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                    </div>
                  </div>
                  <div
                    className="check-btn"
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <input
                      type="checkbox"
                      id="ihvebusi"
                      checked={formData?.Iam?.includes("Business")}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          Iam: e.target.checked ? ["Business"] : [],
                        })
                      }
                    />
                    <label htmlFor="ihvebusi" style={{ maxWidth: "90%" }}>
                      I am a business user and only will post vacancies
                    </label>
                  </div>
                </div>

                <button type="submit">Sign up</button>
                <p className="title">
                  Already have a account?{" "}
                  <a href="#" onClick={() => navigate("/login")}>
                    Log In
                  </a>
                </p>

                <p className="title">
                  NEXT STEPS:
                  <br />
                  1) Verify your email (check your junk box)
                  <br />
                  2) Complete your profile (if you are a freelancer)
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
