import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";
export function fetchInput() {
  const isLoggedIn = !!localStorage.getItem("Auth");

  const fetchData = async () =>
    await axiosInstance
      .get(isLoggedIn ? "/input" : "/unauth/input")
      .then((res) => res.data.data);

  const { isLoading, error, data } = useQuery({
    queryKey: ["inputData"],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: true,
  });

  return { isLoading, error, data };
}
