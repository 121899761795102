const initialState = {
  data: null,
  loading: false,
  error: null,
};

/**
 * Get Data Reducer
 * @param {*} state
 * @param {*} action
 */
export default function testCommon(TYPE) {
  return (state = initialState, action) => {
    const SUCCESS = `${TYPE}_SUCCESS`;
    const FAIL = `${TYPE}_FAIL`;

    switch (action.type) {
      case TYPE:
        return { ...state, loading: true };
      case SUCCESS:
        return {
          ...state,
          loading: false,
          error: {},
          data: action.payload.data,
        };
      case FAIL:
        return {
          ...state,
          loading: false,
          data: {},
          error: action.error.response.data,
        };
      default:
        return state;
    }
  };
}
