import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import Actions from "../actions/types";

export default combineReducers({
  login: commonReducer(Actions.LOGIN),
  signup: commonReducer(Actions.SIGN_UP),
  inviteFriend: commonReducer(Actions.INVITE_FRIEND),
  contactUs: commonReducer(Actions.CONTACT_US),
});
