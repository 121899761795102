import React, { useEffect, useRef, useState } from "react";
import "../../css/stylesReact.css";
import { useLocation, useNavigate } from "react-router-dom";

import { useOnClickOutside } from "../Hook/clickOutSide";

const fields = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "Freelancers List",
    link: "/freelancers",
    navKeys: ["freelancer"],
    dontBoldOn: ["vacancies-posted"],
  },
  {
    title: "Vacancies List",
    link: "/vacancies",
    navKeys: ["vacancy"],
    dontBoldOn: ["/vacancies/create"],
  },

  {
    title: "Post Vacancy",
    link: "/vacancies/create",
    navKeys: ["vacancy"],
  },
  {
    title: "Invite friend",
    link: "/invite",
  },
  {
    title: "About Us",
    link: "/about",
  },
  {
    title: "Contact us",
    link: "/contactus",
  },
  {
    title: "Sign up",
    link: "/signup",
    hideWhileLoggedIn: true,
  },
  {
    title: "Login",
    link: "/login",
    hideWhileLoggedIn: true,
  },
];

function Nav() {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const navRef = useRef(null);

  const path = useLocation().pathname;
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsActive(false);
    });

    return () => {
      window.removeEventListener("scroll", () => {
        setIsActive(false);
      });
    };
  }, []);
  useOnClickOutside(navRef, () => setIsActive(false));

  const isLoggedIn = !!localStorage.getItem("Auth");
  return (
    <nav className="nav">
      <div className="container">
        <div className="menu-box" ref={navRef}>
          <div className={`navbar-main ${isActive ? `active` : ``}  `}>
            {!isLoggedIn
              ? fields.map((link) => (
                  <Link
                    key={link.link}
                    link={link}
                    setIsActive={setIsActive}
                    current={path}
                  />
                ))
              : fields.map(
                  (link) =>
                    !link.hideWhileLoggedIn && (
                      <Link
                        key={link.link}
                        link={link}
                        setIsActive={setIsActive}
                        current={path}
                      />
                    )
                )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginInline: "1rem",
            }}
          >
            <div onClick={() => setIsActive((prev) => !prev)}>
              <BarsIcon />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

function Link({ link, setIsActive, current }) {
  const navigate = useNavigate();
  console.log(current);

  let forceNoBold = false;

  //dont bold some nav link or certain pages,
  //for eg: freelancer list should not be bold inside vacancies posted
  if (link?.dontBoldOn?.length) {
    link?.dontBoldOn?.map((donton) => {
      if (current?.includes(donton)) forceNoBold = true;
    });
  }

  //dont bold home while not on home
  if (current != "/") if (link?.link == "/") forceNoBold = true;

  const isBold =
    (current?.includes(link.link) || current?.includes(link?.navKeys)) &&
    !forceNoBold;

  return (
    <a
      className="hoverBG"
      key={link.title}
      onClick={() => {
        setIsActive(false);
        navigate(link.link);
      }}
      style={{
        cursor: "pointer",
        whiteSpace: "nowrap",
        fontWeight: isBold ? "bold" : "",
      }}
    >
      {link.title}
    </a>
  );
}

function BarsIcon() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (screenWidth < 768)
    return (
      <div style={{ height: "2rem", width: "2rem", cursor: "pointer" }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <path
            fill="white"
            d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
          />
        </svg>
      </div>
    );
}

export default Nav;
