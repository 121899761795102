import React, { useEffect, useState } from "react";
import { fetchFreelancerProfile } from "../../fetch/freelancer/profile";
import { toast } from "react-toastify";
import { timeValues } from "../../utils/fields";
import { fetchInput } from "../../fetch/input/main";
import { useNavigate } from "react-router-dom";
const EconsultationForm = ({ freelancerMutation }) => {
  const { data, isLoading } = fetchFreelancerProfile();
  const { data: inputData } = fetchInput();
  const [value, setValue] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (!value) setValue(data?.data);
  }, [data]);

  const econsult = "E-Consultant";
  const isEconsultant = value?.Iam?.includes(econsult);
  const eConsultation = value?.eConsultation;

  function toggleEconsultant() {
    if (isEconsultant) {
      setValue((prev) => ({
        ...prev,
        Iam: prev.Iam.filter((iam) => iam !== econsult),
      }));
    } else {
      setValue((prev) => ({ ...prev, Iam: [...prev?.Iam, econsult] }));
    }
  }

  function changeValue(e) {
    setValue((prev) => ({
      ...prev,
      eConsultation: { ...prev.eConsultation, [e.target.name]: e.target.value },
    }));
  }

  function toggleSend(e) {
    setValue((prev) => ({
      ...prev,
      eConsultation: {
        ...prev.eConsultation,
        [e.target.name]: e.target.checked,
      },
    }));
  }

  const defaultValues = {
    WelcomeMessage: `I hope that you are well.
Thank you for your interest to meet me and for your booking.
                          
I will contact you shortly to confirm the meeting time and dates.
I wish you a great day.`,
    ConferenceMessage: `Thank you for your interest to meet remotely.

Please find included in this message my personal conference meeting link.
Kindly use it to meet as per our agreed and confirmed date and time.
I wish you a great day.`,
    PaymentMessage: `Thank you for the opportunity to cooperate with you.

Please find included in this message my personal payment link.
Kindly pay my service fees directly to my account.
    
Thank you again for your business.`,
  };

  if (data)
    return (
      <section className="f-list-4">
        <div className="container">
          <div className="bg-col">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                console.log("IN");
                // if (isInSync) return toast.warn("There are no");

                if (!value?.eConsultation?.availability?.length) {
                  return toast.error(
                    "Please select atleast one day as available."
                  );
                }
                const eConsultation = {
                  ...defaultValues,
                  ...value?.eConsultation,
                };

                toast.promise(
                  freelancerMutation.mutateAsync({
                    eConsultation,
                    Iam: value?.Iam,
                  }),
                  {
                    pending: "E-Consultation details are updating",
                    success: "E-Consultation details updated",
                    error: "Some error has been occured ",
                  }
                );
              }}
            >
              <div className="heder-content">
                <h6>e-Consultation Settings</h6>
                <div className="a-btns">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/econsultancy/bookings");
                    }}
                  >
                    <a>My Booking Page</a>
                  </button>{" "}
                  <button type="submit" style={{}}>
                    <a>Save e-Consultation Settings</a>
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id="participate"
                    onChange={toggleEconsultant}
                    checked={isEconsultant}
                  />
                  <label htmlFor="participate">
                    I would like to Participate in e-Consultant Service
                  </label>
                </div>
              </div>

              {
                <div className="row">
                  <div className="small-box">
                    <span>e-Consultant</span>
                  </div>
                  <div className="box" style={{ paddingRight: "2rem" }}>
                    <p>Welcome Message To Client who e-Book *</p>

                    <textarea
                      style={{
                        height: "16rem",
                        width: "100%",
                        marginBlock: ".4rem",
                        padding: "1rem",
                        borderRadius: "10px",
                        fontSize: "1.3rem",
                      }}
                      // rows={4}
                      name="WelcomeMessage"
                      id=""
                      defaultValue={defaultValues.WelcomeMessage}
                      // value={value?.profileSummary}
                      maxLength={800}
                      required
                      onChange={changeValue}
                      // className="input"
                      value={eConsultation?.WelcomeMessage}
                    ></textarea>
                    {/* <input
                      type="name"
                      name="WelcomeMessage"
                      onChange={changeValue}
                      value={eConsultation?.WelcomeMessage}
                      required
                    /> */}
                  </div>
                  <div className="subtitle">
                    <div className="sub-text">
                      <label>800 Max Characters</label>
                    </div>
                  </div>

                  <div className="box" style={{ marginBottom: "1rem" }}>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="sendConferenceInfo"
                        name="sendConferenceInfo"
                        defaultValue="Car"
                        onChange={toggleSend}
                        checked={eConsultation?.sendConferenceInfo}
                      />
                      <label htmlFor="sendConferenceInfo">
                        Send my conference details for e-Consultation booking
                      </label>
                    </div>
                  </div>
                  {eConsultation?.sendConferenceInfo && (
                    <div className="row">
                      <div className="col-lg-5 col-md-6 col-sm-6">
                        <div className="box">
                          <p>Conference Platform Name (e.g. Zoom) *</p>
                          <input
                            type="name"
                            className="input"
                            name="ConferencePlatform"
                            onChange={changeValue}
                            value={eConsultation?.ConferencePlatform}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-6">
                        <div className="box">
                          <p>Conference Meeting Link *</p>
                          <input
                            type="name"
                            className="input"
                            name="ConferenceLink"
                            onChange={changeValue}
                            value={eConsultation?.ConferenceLink}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="box">
                          <p>Conference Message &amp; Additional Details *</p>
                          <textarea
                            type="name"
                            // className="input"
                            name="ConferenceMessage"
                            onChange={changeValue}
                            value={eConsultation?.ConferenceMessage}
                            required
                            maxLength={800}
                            style={{
                              height: "16rem",
                              width: "100%",
                              marginBlock: ".4rem",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontSize: "1.3rem",
                            }}
                            defaultValue={defaultValues.ConferenceMessage}
                          />
                        </div>
                        <div className="subtitle">
                          <div className="sub-text">
                            <label>800 Max Characters</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="box" style={{ marginBottom: "1rem" }}>
                    <div className="checkbox">
                      <input
                        type="checkbox"
                        id="sendPaymentInfo"
                        name="sendPaymentInfo"
                        defaultValue="Car"
                        onChange={toggleSend}
                        checked={eConsultation?.sendPaymentInfo}
                      />
                      <label htmlFor="sendPaymentInfo">
                        Send my payment details for e-Consultation service{" "}
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-9"> */}
                  {eConsultation?.sendPaymentInfo && (
                    <div className="row ">
                      <div className="col-lg-5 col-md-6 col-sm-6">
                        <div className="box">
                          <p>Payment Platform Name (e.g. PayPal) *</p>
                          <input
                            type="name"
                            className="input"
                            name="PaymentPlatform"
                            onChange={changeValue}
                            value={eConsultation?.PaymentPlatform}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-lg-7 col-md-6 col-sm-6">
                        <div className="box">
                          <p>Payment Link *</p>
                          <input
                            type="name"
                            className="input"
                            name="PaymentLink"
                            onChange={changeValue}
                            value={eConsultation?.PaymentLink}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="box">
                          <p>Payment Message &amp; Additional Details *</p>
                          <textarea
                            type="name"
                            // className="input"
                            name="PaymentMessage"
                            onChange={changeValue}
                            value={eConsultation?.PaymentMessage}
                            required
                            maxLength={800}
                            style={{
                              height: "16rem",
                              width: "100%",
                              marginBlock: ".4rem",
                              padding: "1rem",
                              borderRadius: "10px",
                              fontSize: "1.3rem",
                            }}
                            defaultValue={defaultValues.PaymentMessage}
                          />
                        </div>
                        <div className="subtitle">
                          <div className="sub-text">
                            <label>800 Max Characters</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* </div> */}
                  <div className="col-12">
                    <div className="heder-content">
                      <h6>e-Consultant Available Booking Schedule & Rate</h6>
                    </div>
                  </div>

                  <div className="col-lg-5 col-md-6" style={{}}>
                    <div className="row  box-flex ">
                      <div className="col-lg-3 col-4">
                        <p>Day</p>
                      </div>
                      <div className="col-lg-4 col-4 box">
                        <p>From Hour: *</p>
                      </div>
                      <div className="col-lg-4 col-4 box">
                        <p>To Hour: *</p>
                      </div>

                      {[
                        "Sunday",
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        // "Thursday",
                        // "Friday",
                        // "Saturday",
                      ].map((day) => (
                        <SelectDay
                          day={day}
                          value={value}
                          setValue={setValue}
                          key={day}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6" style={{}}>
                    <div
                      className="row  box-flex "
                      style={{ marginTop: "1rem" }}
                    >
                      {[
                        // "Sunday",
                        // "Monday",
                        // "Tuesday",
                        // "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                      ].map((day) => (
                        <SelectDay
                          day={day}
                          value={value}
                          setValue={setValue}
                          key={day}
                        />
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-7">
                    <div className="row">
                      <div className="box col-lg-12 col-md-6 col-sm-6 ">
                        <p>Time Zone: *</p>

                        <select
                          onChange={changeValue}
                          name="timezone"
                          className="input"
                          required
                        >
                          <option selected hidden disabled value={""}>
                            Select a timezone.
                          </option>
                          {inputData?.timezones?.map((tz) => (
                            <option
                              name={tz}
                              value={tz}
                              selected={tz == eConsultation?.timezone}
                              key={tz}
                            >
                              {tz}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box col-lg-12 col-md-6 col-sm-6 ">
                        <p>e-Consultation Hourly Rate *</p>

                        <select
                          onChange={changeValue}
                          name="hourlyRate"
                          className="input"
                          required
                        >
                          <option selected hidden disabled value="">
                            Choose your Hourly rate.
                          </option>
                          {inputData?.hourlyRate?.map((tz) => (
                            <option
                              name={tz}
                              value={tz}
                              selected={tz == eConsultation?.hourlyRate}
                              key={tz}
                            >
                              {tz}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <p className="second-titel" style={{ textAlign: "end" }}>
                      * Required Field
                    </p>
                  </div>
                </div>
              }
              <div
                className="heder-content"
                style={{
                  justifyContent: "end",
                  marginBottom: "0px",
                  marginTop: "2rem",
                }}
              >
                <div className="a-btns">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/econsultancy/bookings");
                    }}
                  >
                    <a>My Booking Page</a>
                  </button>{" "}
                  <button type="submit" style={{}}>
                    <a>Save e-Consultation Settings</a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
};

const SelectDay = ({ day, value, setValue }) => {
  const currentAvailability = value?.eConsultation?.availability;
  const dayChecked = currentAvailability?.find((val) => val.day == day);

  function toggleDay(e) {
    if (!dayChecked)
      setValue((prev) => ({
        ...prev,
        eConsultation: {
          ...prev.eConsultation,
          availability: [
            ...prev?.eConsultation?.availability,
            { day, from: "09:30 AM", to: "06:00 PM" },
          ],
        },
      }));
    else {
      setValue((prev) => ({
        ...prev,
        eConsultation: {
          ...prev.eConsultation,
          availability: prev?.eConsultation?.availability.filter(
            (days) => days.day != day
          ),
        },
      }));
    }
  }

  function setTime(e) {
    console.log(e.target.name);
    let newAvailability = currentAvailability.map((val) =>
      val.day == day ? { ...val, [e.target.name]: e.target.value } : val
    );
    console.log(newAvailability);
    setValue((prev) => ({
      ...prev,
      eConsultation: { ...prev.eConsultation, availability: newAvailability },
    }));
  }

  function timesForTo() {
    const current = timeValues.find((t) => t.value == dayChecked?.from);
    return timeValues.filter((t) => current?.weight < t?.weight);
  }

  return (
    <>
      <div className="col-lg-3 col-4">
        <div className="checkbox">
          <input
            type="checkbox"
            id={day}
            name={day}
            checked={!!dayChecked}
            onChange={toggleDay}
          />
          <label htmlFor={day} className="day-time">
            {day}
          </label>
        </div>
      </div>
      <div className="col-lg-4 col-4">
        <div className="box">
          <select
            name="from"
            onChange={setTime}
            className="input"
            required
            disabled={!dayChecked}
          >
            {timeValues.slice(0, -1).map((time) => (
              <option
                name={time.value}
                value={time.value}
                selected={dayChecked?.from == time.value}
                key={time.value}
              >
                {time.value}
              </option>
            ))}

            <option selected={!dayChecked?.from} hidden disabled>
              HH:MM
            </option>
          </select>
        </div>
      </div>
      <div className="col-lg-4 col-4">
        <div className="box">
          <select
            name="to"
            onChange={setTime}
            className="input"
            required
            disabled={!dayChecked}
          >
            <option selected={!dayChecked?.to} hidden defaultChecked disabled>
              HH:MM
            </option>
            {timesForTo().map((time) => (
              <option
                name={time.value}
                value={time.value}
                selected={dayChecked?.to == time.value}
                key={time.value}
              >
                {time.value}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default EconsultationForm;
