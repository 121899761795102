import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import rootReducer from "./reducers";
import { toast } from "react-toastify";

const envVite = import.meta.env?.MODE;

const envUrl = !!envVite
  ? import.meta.env?.VITE_AXIOS_URL
  : process.env.REACT_APP_AXIOS_URL;

const env = envVite || process.env.NODE_ENV;
const URL = env == "development" ? "http://localhost:3000" + envUrl : envUrl;

const client = axios.create({
  baseURL: URL,
  headers: {
    Authorization: localStorage.getItem("Auth"),
  },
  responseType: "json",
});

const middlewareConfig = {
  interceptors: {
    request: [
      {
        async success(func, req) {
          req.headers.Authorization = localStorage.getItem("Auth");
          console.log("interceptors request", req); // contains information about request object
          return req;
        },
        error(error) {
          return error;
        },
      },
    ],
    response: [
      {
        success(func, res) {
          console.log("AXIOS RESPONSE = ", res); // contains information about request object
          return Promise.resolve(res);
        },
        error(func, error) {
          console.log("network error");
          console.log(error);
          if (error?.response?.data?._code === 403) {
            toast(error?.response?.data?._message, {
              className: "toast-danger",
              progressClassName: "toast-danger_bar",
            });
          }
          if (error.response.status && error.response.status === 401) {
            console.log("Unauthorized User Logout");
            window.location.href = "/login";
          }
          return Promise.reject(error);
        },
      },
    ],
  },
};
const inititalState = {};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  inititalState,
  composeEnhancers(
    applyMiddleware(thunk, axiosMiddleware(client, middlewareConfig))
  )
);

export default store;
