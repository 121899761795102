import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { checkRegisteredUser, dateFormatter } from "../../utils/helper";
import Loading from "../../Components/Loading";
import axiosInstance from "../../axios/axios";
import { toast } from "react-toastify";
import { Puff } from "../../Components/Layout/Loading";
import { queryClient } from "../../routes/Reactquery";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { fetchVacancies } from "../../fetch/vacancy/all";

const publishStyles = (bg) => {
  return {
    background: bg ? " #8aff8a" : " red",
    padding: "5px 20px",
    color: bg ? "black" : "white",
    fontWeight: "600",
    borderRadius: "6px",
  };
};

const vacancyDetailsStyle = {
  display: "flex",
  justifyContent: "center",
  margin: "1rem",
};

const VacancyBox = (props) => {
  let a = props;
  let newProps = {};

  const navigate = useNavigate();

  const [isApply, setIsApply] = useState(false);
  const [fav, setFav] = useState({
    isFav: false,
    isLoading: false,
  });
  const isLoggedIn = !!localStorage.getItem("Auth");

  const {
    isLoading: isProfileLoading,
    error: profileError,
    data: profileData,
  } = fetchProfile();
  fetchVacancies(1, {});
  const setProperty = (obj, path, value) => {
    const [head, ...rest] = path.split(".");
    return {
      ...obj,
      [head]: rest.length
        ? setProperty(obj[head], rest.join("."), value)
        : value,
    };
  };

  const handleFileURL = (val) => {
    if (val) {
      return val;
      // let contentType = "application/pdf";
      // const file = new Blob([new Uint8Array(val?.data)], { type: contentType });
      // return URL.createObjectURL(file);
    } else {
      return "#";
    }
  };

  const handleFav = () => {
    setFav({ ...fav, isLoading: true });
    console.log(fav);
    setTimeout(() => {
      setFav({ ...fav, isLoading: false, isFav: true });
    }, 3000);
  };

  const handleFreelancers = (id) => {
    if (props.mode == "posted") {
      navigate(`/vacancies-posted/freelancers/${id}`);
    } else {
      return false;
    }
  };

  Object.keys(a).map((x) => {
    let nestedProperty = x.split(".");
    let val = a[x];
    if (nestedProperty.length > 1) {
      let nestedNewProps = {};
      newProps = _.merge(newProps, setProperty(nestedNewProps, x, val));
    }
  });
  props = { ...props, ...newProps };
  const handleApply = () => {
    if (profileData?.data?.user?.id == props?.user?.id) {
      toast.warn("You cannot apply for your own vacancy");
      return;
    }
    const isLoggedIn = !!localStorage.getItem("Auth");
    if (isLoggedIn) {
      setIsApply(true);
      axiosInstance
        .put(`/vacancy/apply`, { vacancyId: props.id })
        .then(async (res) => {
          setIsApply(false);
          toast.success("Applied to Vacancy");
          await queryClient.invalidateQueries(["profile"]);
          await queryClient.invalidateQueries(["vacancies"]);
          setTimeout(() => {
            navigate("/vacancies-applied");
          }, 1000);
        })
        .catch((err) => toast.error("Applying failed"));
    } else {
      navigate("/login");
    }
  };

  const handleDelete = async (time) => {
    await axiosInstance
      .delete(`/vacancy/time`, { data: { time: time } })
      .then(async (res) => {
        await queryClient.invalidateQueries(["profile"]);
        toast.success("Vacancy deleted successfully");
      })
      .catch();
  };
  return (
    <>
      <div
        className="container"
        style={{
          display: "flex",
          padding: "1rem",
          justifyContent: "space-between",
          // padding: "0px",
        }}
      >
        <div></div>
        {props.noBtn && (
          <div style={vacancyDetailsStyle}>
            <h2>
              {props.mode == "posted" && "Posted on "}
              {props.mode == "posted" &&
                dateFormatter(props.postedDate?.slice(0, 10))}
              {props.mode == "applied" && "Applied on "}
              {props.mode == "applied" && dateFormatter(props.on?.slice(0, 10))}
            </h2>
          </div>
        )}
        {props.mode == "posted" ? (
          <div className="btn">
            <button
              className="btn3 edit-btn"
              onClick={() => navigate(`/vacancies/${props?.id}`)}
            >
              <b>Update</b>
            </button>
          </div>
        ) : (
          props.mode == "applied" && (
            <div className="btn">
              <button
                style={{ backgroundColor: "red", border: "1px solid red" }}
                className="btn1 edit-btn"
                onClick={() => handleDelete(props.on)}
              >
                <b>Delete</b>
              </button>
            </div>
          )
        )}
      </div>
      <section className="vacan-1">
        <div className="container">
          <div className="bg-col">
            <form action="" style={{ textAlign: "justify" }}>
              <div className="row">
                <div
                  className="box-flex"
                  style={{ justifyContent: "flex-start", margin: "1rem 0rem" }}
                >
                  {!["normal", "applied"].includes(props.mode) &&
                    props?.publish &&
                    props?.isApproved &&
                    !props?.isPreview && (
                      <a
                        className="abtn2"
                        style={publishStyles(props?.publish)}
                        href="#"
                      >
                        ✅ Published
                      </a>
                    )}
                  {!props?.publish &&
                    props?.isApproved &&
                    !props?.isPreview && (
                      <a
                        className="abtn2"
                        style={publishStyles(props?.publish)}
                        href="#"
                      >
                        Not Published
                      </a>
                    )}

                  {props?.title == undefined && !props?.isPreview && (
                    <a className="abtn2" style={publishStyles(false)} href="#">
                      Vacancy Deleted
                    </a>
                  )}
                  {new Date(dateFormatter(props.expiryDate)) <=
                    new Date(dateFormatter(new Date().toISOString())) &&
                    !props?.isPreview && (
                      <a
                        className="abtn2"
                        style={publishStyles(false)}
                        href="#"
                      >
                        Vacancy Expired <br></br>
                      </a>
                    )}
                  {!props?.isApproved &&
                    !props?.isPreview &&
                    props?.title != undefined &&
                    !props?.isPreview && (
                      <a className="abtn2" style={publishStyles(false)}>
                        Not approved
                      </a>
                    )}
                </div>
                {!(props?.title == undefined && !props?.isPreview) && (
                  <div className="box col-lg-2 col-md-4 col-sm-4">
                    <h3>Location</h3>
                    <p>
                      <b>City:</b> {props.location?.city}
                      <br />
                      <b>Country:</b> {props.location?.country} <br />
                      <b>Work Station:</b> {props.location?.workstation} <br />
                      <b>Travel Required: </b>
                      {props.location?.travelRequired
                        ? props.location?.travelRequired
                        : "UNKNOWN"}
                    </p>
                    <h3>Expiry Date</h3>
                    <p>{dateFormatter(props?.expiryDate)}</p>
                    <div>
                      {!props?.noBtn && (
                        <a
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              profileData?.data?.user?.id == props?.user?.id
                                ? "gray"
                                : "#444444",
                          }}
                          className="abtn1"
                          onClick={handleApply}
                        >
                          {isApply ? <Loading /> : "Apply"}
                        </a>
                      )}
                    </div>
                  </div>
                )}
                {!(props?.title == undefined && !props?.isPreview) && (
                  <div
                    className="box col-lg-5 col-md-8 col-sm-8"
                    style={{ paddingRight: "2rem" }}
                  >
                    <div className="box-aline">
                      <h3>{props?.title}</h3>
                      <p>
                        {props?.requiredSkills?.map((sk, index) => (
                          <>
                            {sk}
                            {index == props?.requiredSkills.length - 1 ? (
                              <span>. </span>
                            ) : (
                              <span>, </span>
                            )}
                          </>
                        ))}
                      </p>
                      <h3>Vacancy Requirements</h3>
                      <p>{props?.requirements}</p>
                    </div>
                  </div>
                )}
                {!(props?.title == undefined && !props?.isPreview) && (
                  <div className="box col-lg-2 col-sm-6">
                    <div className="box-aline">
                      <h3>Candidate</h3>
                      <p>
                        <b>Min. Experience:</b>{" "}
                        {props?.candidate?.minExperience} <br />
                        <b>Language:</b> {props?.candidate?.firstLanguage}
                        {props?.candidate?.secondLanguage
                          ? ` &  ${props?.candidate?.secondLanguage}`
                          : " "}{" "}
                        <br /> <b>Nationality:</b>{" "}
                        {props?.candidate?.nationality
                          ? props?.candidate?.nationality
                          : "Any"}
                      </p>
                      <h3>Payment</h3>
                      <p>
                        <b>Hourly Rate:</b> {props?.payment?.hourlyRate}
                        <br />
                        {props?.payment?.payType && (
                          <>
                            <b>Pay Type:</b> {props?.payment?.payType} <br />
                          </>
                        )}
                        {props?.payment?.flights && (
                          <>
                            <b>Flights: </b>
                            {props?.payment?.flights}
                            <br />
                          </>
                        )}
                        {props.payment?.hotel && (
                          <>
                            <b>Hotel: </b>
                            {props.payment?.hotel}
                            <br />
                          </>
                        )}
                      </p>
                    </div>
                    <div
                      className="box-flex"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      {props?.isTrainer && <a className="abtn2">Trainer</a>}
                      {props?.isConsultant && (
                        <a className="abtn3">Consultant</a>
                      )}
                    </div>
                  </div>
                )}
                {!(props?.title == undefined && !props?.isPreview) && (
                  <div className="col-lg-3 col-sm-6">
                    <div className="box-aline">
                      <div className="box-flex">
                        <h3>Time</h3>
                        {/* {fav?.isLoading ? (
                      <Puff />
                    ) : (
                      <img
                        style={{
                          cursor: "pointer",
                          filter: !fav?.isFav && "grayscale(100%)",
                        }}
                        onClick={handleFav}
                        src="/icons/star.svg"
                        alt=""
                      />
                    )} */}
                      </div>
                      <p>
                        <b>Start Date:</b>{" "}
                        {dateFormatter(props?.time?.startDate)} <br />
                        <b>Time Period:</b> {props?.time?.timePeriod}
                        <br /> <b>Min. Availability:</b>{" "}
                        {props?.time?.minAvailability}
                        <br /> <b>Project Phase:</b> {props?.time?.projectPhase}
                      </p>
                      <h3>Contact</h3>
                      <p>
                        <HoverText value={props.contact?.name} title={"Name"} />
                        <HoverText
                          value={props.contact?.email}
                          title={"Email"}
                        />

                        {
                          <HoverText
                            value={props?.contact?.phone}
                            title={"Phone"}
                          />
                        }

                        {/* 
                        {props?.isPhoneRegistered &&
                          checkRegisteredUser() &&
                          !props?.isPhonePublic && (
                            <span>
                              <b>Phone:</b> {props.contact?.phone}
                            </span>
                          )} */}
                      </p>
                      {!!props?.file &&
                        (checkRegisteredUser() ? (
                          <a
                            href="#"
                            onClick={() => window.open(props?.file, "_blank")}
                          >
                            <h3>
                              Download More Vacancy Info.{" "}
                              <img
                                src="/icons/arrow.svg"
                                alt=""
                                style={{ marginBottom: ".5rem" }}
                              />
                            </h3>
                          </a>
                        ) : (
                          <span
                            style={{ fontWeight: "normal", cursor: "pointer" }}
                            onClick={() => navigate("/login")}
                          >
                            <h3>
                              Download More Vacancy Info.{" "}
                              <img
                                src="/icons/arrow.svg"
                                alt=""
                                style={{ marginBottom: ".5rem" }}
                              />
                            </h3>
                          </span>
                        ))}
                    </div>
                  </div>
                )}
                {props?.mode == "posted" && !!props?.appliedBy?.length && (
                  <div style={{ fontSize: "small" }}>
                    Applied by{" "}
                    {
                      <a
                        onClick={() => handleFreelancers(props?.id)}
                        className="freelancer-no"
                      >
                        {props?.appliedBy?.length}
                      </a>
                    }
                    {props?.appliedBy?.length == 1
                      ? ` freelancer`
                      : ` freelancers`}
                  </div>
                )}
                {props?.title == undefined && !props?.isPreview && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <h2>
                      <i>Vacancy was removed</i>
                    </h2>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

function HoverText({ value, title }) {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("Auth");

  if (value)
    return (
      <>
        <li className={!isLoggedIn && `hidetext`}>
          <b>{title}:&nbsp;</b>
          <span style={{ fontWeight: "normal" }}>
            {isLoggedIn ? <span> {value}</span> : <>************</>}
          </span>
          <div
            className="insideTooltip "
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/login")}
          >
            Please login to view
          </div>
        </li>
      </>
    );
}

export default VacancyBox;
