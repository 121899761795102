import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";

export function fetchVacancies(page, search) {
  const isSearch = Object.keys(search).some((x) => (search[x] ? true : false));
  let searchArr = [];
  Object.keys(search).map((x) => searchArr.push(search[x]));
  const isLoggedIn = !!localStorage.getItem("Auth");
  const url = (auth, search, page) => {
    if (auth) {
      if (search) {
        return `/vacancy/search?page=${page}`;
      } else {
        return `/vacancy/all?page=${page}`;
      }
    } else {
      if (search) {
        return `/unauth/vacancy/search?page=${page}`;
      } else {
        return `/unauth/vacancies?page=${page}`;
      }
    }
  };
  const fetchData = async () =>
    isSearch
      ? await axiosInstance
          .post(url(isLoggedIn, isSearch, page), search)
          .then((res) => res.data.data)
      : await axiosInstance
          .get(url(isLoggedIn, isSearch, page))
          .then((res) => res.data.data);
  const { isLoading, error, data } = useQuery({
    queryKey: [`vacancies`, isLoggedIn, page, searchArr.toString()],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  });

  return { isLoading, error, data };
}

export function fetchFreelancerByVacancy(page, vacancyId) {
  const fetchData = async () =>
    await axiosInstance
      .get(`/vacancy/freelancers/${vacancyId}?page=${page}`)
      .then((res) => res?.data?.data);

  const { isLoading, error, data } = useQuery({
    queryKey: [`freelancersByVacancy`, page, vacancyId],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  });

  return { isLoading, error, data };
}
