import React, { useEffect, useState } from "react";
import { fetchInput } from "../../fetch/input/main";
import { checkRegisteredUser } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { KeyskillsSelect } from "../../Components/FreelancerProfile/KeySkills";
import { DefaultOption } from "../../utils/components";
import SkillsSelect from "../../Components/Freelancer/SkillsSelect";

const VacancySearch = ({ apiCall }) => {
  const navigate = useNavigate();

  const [selectedSkills, setSelectedSkills] = useState([]);

  const [data, setData] = useState(null);
  const {
    data: inputData,
    error: inputError,
    loading: inputLoad,
  } = fetchInput();

  const handlePostVacancy = (e) => {
    e.preventDefault();
    !checkRegisteredUser() ? navigate("/login") : navigate("/vacancies/create");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let searchData = {};
    data &&
      Object.keys(data)?.map((x) => {
        if (!data[x].includes("Any")) {
          searchData = { ...searchData, [x]: data[x] };
        }
      });
    setData(searchData);
    apiCall({ ...searchData, keywords: selectedSkills });
  };
  const handleReset = (e) => {
    document.getElementById("form").reset();
    setData();
    setSelectedSkills([]);
    console.log(selectedSkills);
  };

  function skillVals() {
    if (selectedSkills?.length) {
      return selectedSkills[0]?.skills[0]?.slice(0, 20) + ".....";
    } else {
      return "Select keywords..";
    }
  }
  const handleChange = (e) => {
    if (e.target.value == "none") {
      setData({
        ...data,
        [e.target.name]: `Any`,
      });
      return;
    }
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div>
      <div className="container">
        <div className="bg-col">
          <h6>Search For Vacancies</h6>
          <form onSubmit={handleSubmit} id="form">
            <div className="list-flex">
              <SkillsSelect
                set={setSelectedSkills}
                initialData={selectedSkills}
                emptyInitial
                label={"Key Word (s) *"}
              />
              <div className="box">
                <p>Country</p>
                <select
                  name="country"
                  onChange={handleChange}
                  className="input"
                  value={data?.country ? data?.country : "none"}
                  required=""
                >
                  <DefaultOption name="country" />
                  {inputData?.country?.map((cou) => (
                    <>
                      <option key={cou} value={cou}>
                        {cou}
                      </option>
                      {cou == "Yemen" && (
                        <option value="" disabled="disabled">
                          ------------------------------------------------------------------------------
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
              <div className="box">
                <p>City</p>
                <input
                  type="name"
                  onChange={handleChange}
                  name="city"
                  className="input"
                  placeholder="Enter city"
                  required=""
                />
              </div>
              <div className="box">
                <p>Language</p>
                <select
                  name="language"
                  onChange={handleChange}
                  className="input"
                  required=""
                  value={data?.language ? data?.language : "none"}
                >
                  <DefaultOption name="language" />
                  {inputData?.language?.map((cou) => (
                    <option key={cou} value={cou}>
                      {cou}
                    </option>
                  ))}
                </select>
              </div>
              <div className="box">
                <p>Nationality</p>
                <select
                  name="nationality"
                  onChange={handleChange}
                  className="input"
                  required=""
                  value={data?.nationality ? data?.nationality : "none"}
                >
                  <DefaultOption name="nationality" />

                  {inputData?.country?.map((cou) => (
                    <>
                      <option key={cou} value={cou}>
                        {cou}
                      </option>
                      {cou == "Yemen" && (
                        <option value="" disabled="disabled">
                          ------------------------------------------------------------------------------
                        </option>
                      )}
                    </>
                  ))}
                </select>
              </div>
              <div className="box">
                <p>Sort By *</p>
                <select
                  name="sortBy"
                  onChange={handleChange}
                  className="input"
                  value={data?.sortBy}
                  required=""
                >
                  <DefaultOption />
                  <option value="">Most Recent</option>
                  <option value="isConsultant">Consultants</option>
                  <option value="econsultants">eConsultants</option>
                  <option value="isTrainer">Trainers</option>
                  <option value="-1">Onsite</option>
                  <option value="1">Remote</option>
                </select>
              </div>
            </div>
            <div className="buttons">
              <div className="left-btn">
                <button type="submit">
                  <a className="abtn1">Search</a>
                </button>
                <button>
                  <a className="abtn2" onClick={handleReset}>
                    Reset
                  </a>
                </button>
              </div>
              <p style={{ fontSize: "1.2rem", color: "#8e8e8e" }}>
                For more results keep search broader
              </p>
              {/* <div>
                <p>For more results keep search broader</p>
              </div> */}
              <div className="right-btn">
                <button onClick={handlePostVacancy}>
                  <a className="abtn3">Post Vacancy</a>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

function SkillsSelectModal({ close, setSelectedSkills, selectedSkills }) {
  return (
    <div
      style={{
        position: "fixed",

        top: 0,
        height: "100vh",
        width: "100vw",
        backgroundColor: "#aaa",
        zIndex: "1001",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "fixed" }}>
        <div style={{ width: "70rem" }}>
          <section className="f-list-1">
            <div className="container">
              <div className="bg-col">
                <form action="" method="post">
                  <div className="list-flex">
                    <div className="box">
                      <SkillsSelect set={setSelectedSkills} horizontal />
                    </div>
                  </div>
                  <div className="buttons">
                    <div
                      className="left-btn"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <a className="abtn1" href="#" onClick={close}>
                        set
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default VacancySearch;
