export const DefaultOption = ({ name }) => {
  if (name) {
    return (
      <>
        <option selected value={"none"}>
          {/* Select {["a", "e", "i", "o", "u"].includes(name[0]) ? "an " : " "} */}
          {name ? `Any ${name}` : "Any"}
        </option>
      </>
    );
  }
  return null;
};

export const DefaultOptionTime = ({ name }) => {
  if (name) {
    return (
      <option selected hidden disabled value={"none"}>
        {name}
      </option>
    );
  }
  return null;
};
