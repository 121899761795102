import React from "react";

const NotFound = () => {
  return (
    <div>
      {" "}
      <section className="sign-log">
        <div className="container">
          <div className="box-center">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                // backgroundColor: "red",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <h1 style={{ fontSize: "12rem" }}>404</h1>
              <p style={{ fontSize: "2rem" }}>Page not found</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFound;
