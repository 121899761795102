import { isEmpty } from "../validation";
import { validation } from "../utils/constants";

export const ClassNames = (classes) => {
  return classes.join(" ");
};

/**
 * if Object Elements Empty
 * @param {*} data
 */
export const ifObjectElementsEmpty = (data) => {
  let errors = {};
  Object.keys(data).forEach((element) => {
    if (isEmpty(data[element])) {
      errors[element] = { label: validation.ISEMPTY };
    } else {
      delete errors[element];
    }
  });
  return errors;
};
export const calculatePercentage = (total, discount) => {
  const percentage = (discount / total) * 100;
  return Math.max(0, percentage.toFixed(2));
};

export const formatDate = (date) => {
  if (typeof date === undefined || typeof date === "undefined") {
    return "";
  } else {
    date = new Date(date);
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var hrs = date.getHours();
    var minutes = date.getMinutes();

    return (
      monthNames[monthIndex] +
      " " +
      day +
      " , " +
      +year +
      "  at  " +
      (hrs % 12) +
      ":" +
      minutes +
      (hrs / 12 >= 1 ? "PM" : "AM")
    );
  }
};

/**
 * Get nested prop value of object
 * @param {*} obj
 * @param {*} prop
 * @return {*}
 */
export const getObjectProp = (obj, prop) => {
  if (typeof obj === "string") {
    return obj;
  }
  if (typeof obj === "undefined") {
    return false;
  }
  const _index = prop.indexOf(".");
  if (_index > -1) {
    return getObjectProp(
      obj[prop.substring(0, _index)],
      prop.substr(_index + 1)
    );
  }
  return obj?.[prop];
};

export const formatData = (data) => {
  const ifImage = Object.keys(data).filter((val) => data[val] instanceof File);
  if (ifImage && ifImage[0]) {
    let data_backd = data;
    data = new FormData();
    Object.keys(data_backd).forEach((key) => {
      if (typeof data_backd[key] !== "object") {
        data.append(key, JSON.stringify(data_backd[key]));
      } else data.append(key, data_backd[key]);
    });
  }
  return data;
};

export const fullName = (firstName, lastName) =>
  !lastName ? firstName : `${firstName} ${lastName}`;

export const capsFirstLetter = (string) =>
  string[0].toUpperCase() + string.slice(1);

export const getPathEnv = (link) => {
  // const env = process.env.NODE_ENV;
  // if (env == "development") {
  //   return `http://localhost:3000/${link}`;
  // } else {
  //   return link;
  // }
  if (!link) return false;
  return `${window.location.origin}/${link}`;
};

export const getParams = (param) => {
  const searchParams = new URLSearchParams(document.location.search);
  return searchParams.get(param);
};
