import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../axios/axios";
import RecaptchaComponent from "../../Components/Recaptcha/Recaptcha";
import { isLoggedIn } from "../../utils/helper";

function Password() {
  const navigation = useNavigate();

  const [isNotRobo, setIsNotRobo] = useState(false);

  const [input, setInput] = useState({
    email: "",
  });

  const updatePwd = async () =>
    await axiosInstance.post("/unauth/password/new", {
      email: input?.email,
    });

  const profileMutation = useMutation(updatePwd, {
    onSuccess: () => {
      toast.success("Password updated, Please check your email.");
      setTimeout(() => {
        navigation("/login");
      }, 1500);
    },
    onError: (err) => {
      if (err?.response?.data?.errors?.message) {
        toast.error(err?.response?.data?.errors?.message?.toLocaleLowerCase());
      } else {
        toast.error("Some error occured");
      }
    },
  });

  function changeInput(e) {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  // const captchaRef = useRef(null);

  // const captchaKey =
  //   import.meta.env?.VITE_RECAPTCHA_KEY || process.env.REACT_APP_RECAPTCHA_KEY;

  // const checkToken = async (token) =>
  //   await axiosInstance
  //     .post(`/auth/verifyrecaptcha`, {
  //       secret: captchaKey,
  //       token,
  //     })
  //     .then((res) => res.data);

  // const tokenMutation = useMutation(checkToken, {
  //   onSuccess: (token) => {
  //     if (token?.success) {
  //       captchaRef.current.reset();
  //       console.log("CAPTCHA SUCCESS");
  //       profileMutation.mutate();
  //     }
  //   },
  // });

  function forget(e) {
    e.preventDefault();
    // if (!input.email) return toast.warning("Please input your email.");
    // let token = captchaRef.current.getValue();
    // if (!token) return toast.warning("Please complete the captcha");
    // tokenMutation.mutate(token);
    // // console.log(token);

    if (!isNotRobo) return toast.warning("Please select I'm not a robot");
    profileMutation.mutate();
  }
  const loggedin = isLoggedIn();
  return (
    <>
      <section class="sign-log">
        <div class="container">
          <div class="box-center">
            <div class="box-bg">
              <h3>Forgot your password?</h3>
              <form onSubmit={forget}>
                <div class="login-list-flex">
                  <div className="box ">
                    <p>Enter your email</p>

                    <input
                      type="email"
                      name="email"
                      className="input"
                      value={input.email}
                      onChange={changeInput}
                    />
                  </div>
                </div>
                <div class="login-btn-flex">
                  <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                  {!loggedin && (
                    <div style={{ justifyContent: "center", gap: "2rem" }}>
                      <a href="#" onClick={() => navigation("/login")}>
                        Login{" "}
                      </a>
                      <a href="#" onClick={() => navigation("/signup")}>
                        Sign Up{" "}
                      </a>
                    </div>
                  )}
                </div>
                {/* <p class="log-title">* Required Field</p> */}
                <button type="submit">Reset </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Password;
