import React from "react";
import { useParams } from "react-router-dom";
import { fetchEconsultancyBooking } from "../../fetch/econsultancy/all";
import { FreelancerBox2 } from "../../Components/Freelancer/FreelancerBox2";
import { dateFormatter, timeFormatter } from "../../utils/helper";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { FreelancerBoxLoading } from "../../Components/Freelancer/FreelancerBoxLoading";
export const EconsultationPreview = () => {
  const { econsultancyId } = useParams();

  const { isLoading, error, data } = fetchEconsultancyBooking(econsultancyId);
  const {
    isLoading: profileLoading,
    error: profileError,
    data: profile,
  } = fetchProfile();

  return (
    <div>
      {isLoading && <FreelancerBoxLoading />}
      {data && data?.bookedBy == profile?.id && (
        <FreelancerBox2
          freelancer={data?.freelancer}
          econsultBtn={false}
          fav={profile?.data?.favoriteFreelancers?.some(
            (fav) => fav == data?.freelancer?.id
          )}
        />
      )}
      {data && data?.bookedBy != profile?.id && (
        <FreelancerBox2
          freelancer={data?.freelancer}
          econsultBtn={false}
          fav={profile?.data?.favoriteFreelancers?.some(
            (fav) => fav == data?.freelancer?.id
          )}
          preview
        />
      )}
      <section className="company1">
        <div className="container">
          <div className="bg-color">
            <div className="row">
              <div className="col-6">
                <h3 className="head">Client Information &amp; Requirements</h3>
              </div>
              <div className="col-6">
                <p className="title-requ">* Required Field</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="box col-md-6">
                    <p>Name *</p>
                    <input
                      type="name"
                      name="clientInformation.name"
                      className="input"
                      disabled
                      defaultValue={data?.clientInformation?.name}
                      required=""
                      placeholder="Enter name"
                    />
                    <div className="box col-md-6"></div>
                    <p>Email *</p>
                    <input
                      type="email"
                      name="clientInformation.email"
                      className="input"
                      disabled
                      required=""
                      defaultValue={data?.clientInformation?.email}
                      placeholder="Enter email"
                    />
                    <div className="checkbox">
                      <div className="bg-color">
                        <label>Email Confirmed</label>
                      </div>
                    </div>
                  </div>
                  <div className="box col-md-6">
                    <p>Organization</p>
                    <input
                      type="text"
                      name="clientInformation.organization"
                      className="input"
                      disabled
                      required=""
                      placeholder="Enter organization"
                      defaultValue={data?.clientInformation?.organization}
                    />
                    <p>Phone</p>
                    <input
                      type="name"
                      name="clientInformation.phone"
                      className="input"
                      disabled
                      defaultValue={data?.clientInformation?.phone}
                      required=""
                      placeholder="Enter phone"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="box ">
                  <p>Requirements *</p>
                  <textarea
                    disabled
                    name="clientInformation.requirements"
                    id=""
                    defaultValue={data?.clientInformation?.requirements}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="company1">
        <div className="container">
          <div className="bg-color">
            <div className="row">
              <div className="col-6">
                <h3 className="head">Requested Booking Date &amp; Time</h3>
              </div>
              <div className="col-6">
                <p className="title-requ">* Required Field</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <div className="box">
                  <p>Select Preferred Date *</p>
                  <input
                    type="text"
                    name="bookingDetails.preferenceDate"
                    min={new Date().toISOString().split("T")[0]}
                    className="input"
                    disabled
                    defaultValue={dateFormatter(
                      data?.bookingDetails?.preferenceDate
                    )}
                  />
                  <div className="subtitle">
                    <div className="sub-text">
                      <label>Pending on freelancer confirmation</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="box ">
                  <p>Client Time Zone *</p>
                  <input
                    name="bookingDetails.timezone"
                    className="input"
                    disabled
                    type="text"
                    defaultValue={data?.bookingDetails?.timezone}
                    required=""
                  ></input>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="box ">
                  <p>Meeting Start Time *</p>
                  <input
                    name="bookingDetails.startTime"
                    className="input"
                    disabled
                    defaultValue={data?.bookingDetails?.startTime}
                    required=""
                  ></input>
                  <div className="subtitle">
                    <div className="sub-text">
                      <label>Based on client time-zone</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="box">
                  <p>Meeting End Time * </p>
                  <input
                    name="bookingDetails.endTime"
                    className="input"
                    disabled
                    defaultValue={data?.bookingDetails?.endTime}
                    required=""
                  ></input>
                  <div className="subtitle">
                    <div className="sub-text">
                      <label>Based on client time-zone</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="box ">
                  <p>Propose Alternative Date &amp; Time</p>
                  <input
                    type="Propose Alternative Date & Time"
                    name="bookingDetails.proposeAlternateDate"
                    className="input"
                    disabled
                    defaultValue={data?.bookingDetails?.proposeAlternateDate}
                    placeholder=""
                    required=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
