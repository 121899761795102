export const fieldsData = {
  SIGN_UP: [
    {
      name: "firstName",
      type: "text",
      min: 3,
    },

    {
      name: "email",
      type: "email",
      min: 3,
    },
    {
      name: "phone",
      type: "text",
      min: 7,
    },
    {
      name: "country",
      type: "select",
      min: 3,
    },
    {
      name: "city",
      type: "text",
      min: 3,
    },
    {
      name: "password",
      type: "text",
      min: 6,
    },
    {
      name: "cnfpassword",
      type: "text",
      min: 6,
    },
  ],
  SIGN_IN: [
    {
      label: "Email",
      name: "email",
      type: "text",
      min: 4,
    },
    {
      label: "Password",
      name: "password",
      type: "text",
      min: 4,
    },
  ],
  INVITE_FRIEND: [
    {
      label: "Email",
      name: "email1",
      type: "text",
      extra: "Add multiple emails with ; in between or use another field below",
    },
  ],
  CONTACT_US: [
    {
      name: "name",
      type: "text",
      min: 3,
    },
    {
      name: "email",
      type: "text",
    },
    {
      name: "subject",
      type: "text",
    },
    {
      name: "inquiry",
      type: "textarea",
    },
  ],
  COUNTRY: [
    {
      label: "Algeria",
      value: "Algeria",
    },
    {
      label: "Bahrain",
      value: "Bahrain",
    },
    {
      label: "Egypt",
      value: "Egypt",
    },
    {
      label: "Iraq",
      value: "Iraq",
    },
    {
      label: "Jordan",
      value: "Jordan",
    },
    {
      label: "Kuwait",
      value: "Kuwait",
    },
    {
      label: "Lebanon",
      value: "Lebanon",
    },
    {
      label: "Libya",
      value: "Libya",
    },
    {
      label: "Mauritania",
      value: "Mauritania",
    },
    {
      label: "Morocco",
      value: "Morocco",
    },
    {
      label: "Oman",
      value: "Oman",
    },
    {
      label: "Palestine",
      value: "Palestine",
    },
    {
      label: "Qatar",
      value: "Qatar",
    },
    {
      label: "Saudi Arabia",
      value: "Saudi Arabia",
    },
    {
      label: "Sudan",
      value: "Sudan",
    },
    {
      label: "Syria",
      value: "Syria",
    },
    {
      label: "Tunisia",
      value: "Tunisia",
    },
    {
      label: "Turkey",
      value: "Turkey",
    },
    {
      label: "UAE",
      value: "UAE",
    },
    {
      label: "Yemen",
      value: "Yemen",
    },
    {
      label: "Afghanistan",
      value: "Afghanistan",
    },
    {
      label: "Albania",
      value: "Albania",
    },
    {
      label: "Andorra",
      value: "Andorra",
    },
    {
      label: "Angola",
      value: "Angola",
    },
    {
      label: "Antigua ",
      value: "Antigua",
    },
    {
      label: "Barbuda",
      value: "Barbuda",
    },
    {
      label: "Argentina",
      value: "Argentina",
    },
    {
      label: "Armenia",
      value: "Armenia",
    },
    {
      label: "Australia",
      value: "Australia",
    },
    {
      label: "Austria",
      value: "Austria",
    },
    {
      label: "Azerbaijan",
      value: "Azerbaijan",
    },
    {
      label: "Bahamas",
      value: "Bahamas",
    },
    {
      label: "Bangladesh",
      value: "Bangladesh",
    },
    {
      label: "Barbados",
      value: "Barbados",
    },
    {
      label: "Belarus",
      value: "Belarus",
    },
    {
      label: "Belgium",
      value: "Belgium",
    },
  ],
  LANGUAGE: [
    {
      label: "English",
      value: "English",
    },
    {
      label: "Arabic",
      value: "Arabic",
    },
    {
      label: "Dutch",
      value: "Dutch",
    },
    {
      label: "French",
      value: "French",
    },
    {
      label: "German",
      value: "German",
    },
    {
      label: "Hindi",
      value: "Hindi",
    },
    {
      label: "Italian",
      value: "Italian",
    },
    {
      label: "Portuguese",
      value: "Portuguese",
    },
    {
      label: "Punjabi",
      value: "Punjabi",
    },
    {
      label: "Spanish",
      value: "Spanish",
    },
    {
      label: "Urdu",
      value: "Urdu",
    },
    {
      label: "Akan",
      value: "Akan",
    },
    {
      label: "Amharic",
      value: "Amharic",
    },
    {
      label: "Assamese",
      value: "Assamese",
    },
    {
      label: "Awadhi",
      value: "Awadhi",
    },
    {
      label: "Azeri",
      value: "Azeri",
    },
    {
      label: "Baluchi",
      value: "Baluchi",
    },
    {
      label: "Belorussian",
      value: "Belorussian",
    },
    {
      label: "Bengali",
      value: "Bengali",
    },
    {
      label: "Bhojpuri",
      value: "Bhojpuri",
    },
    {
      label: "Burmese",
      value: "Burmese",
    },
    {
      label: "Cantonese",
      value: "Cantonese",
    },
    {
      label: "Cebuano",
      value: "Cebuano",
    },
    {
      label: "Chinese",
      value: "Chinese",
    },
    {
      label: "Chittagonian",
      value: "Chittagonian",
    },
    {
      label: "Czech",
      value: "Czech",
    },
    {
      label: "Fulani",
      value: "Fulani",
    },
    {
      label: "Greek",
      value: "Greek",
    },
    {
      label: "Gujarati",
      value: "Gujarati",
    },
    {
      label: "Hausa",
      value: "Hausa",
    },
    {
      label: "Hungarian",
      value: "Hungarian",
    },
    {
      label: "Igbo",
      value: "Igbo",
    },
    {
      label: "Japanese",
      value: "Japanese",
    },
    {
      label: "Javanese",
      value: "Javanese",
    },
  ],
  NATIONALITY: [
    {
      label: "Algeria",
      value: "Algeria",
    },
    {
      label: "Bahrain",
      value: "Bahrain",
    },
    {
      label: "Egypt",
      value: "Egypt",
    },
    {
      label: "Iraq",
      value: "Iraq",
    },
    {
      label: "Jordan",
      value: "Jordan",
    },
    {
      label: "Kuwait",
      value: "Kuwait",
    },
    {
      label: "Lebanon",
      value: "Lebanon",
    },
    {
      label: "Libya",
      value: "Libya",
    },
    {
      label: "Mauritania",
      value: "Mauritania",
    },
    {
      label: "Morocco",
      value: "Morocco",
    },
    {
      label: "Oman",
      value: "Oman",
    },
    {
      label: "Palestine",
      value: "Palestine",
    },
    {
      label: "Qatar",
      value: "Qatar",
    },
    {
      label: "Saudi Arabia",
      value: "Saudi Arabia",
    },
    {
      label: "Sudan",
      value: "Sudan",
    },
    {
      label: "Syria",
      value: "Syria",
    },
    {
      label: "Tunisia",
      value: "Tunisia",
    },
    {
      label: "Turkey",
      value: "Turkey",
    },
    {
      label: "UAE",
      value: "UAE",
    },
    {
      label: "Yemen",
      value: "Yemen",
    },
    {
      label: "Afghanistan",
      value: "Afghanistan",
    },
    {
      label: "Albania",
      value: "Albania",
    },
    {
      label: "Andorra",
      value: "Andorra",
    },
    {
      label: "Angola",
      value: "Angola",
    },
    {
      label: "Antigua &amp; Barbuda",
      value: "Antigua &amp; Barbuda",
    },
    {
      label: "Argentina",
      value: "Argentina",
    },
    {
      label: "Armenia",
      value: "Armenia",
    },
    {
      label: "Australia",
      value: "Australia",
    },
    {
      label: "Austria",
      value: "Austria",
    },
    {
      label: "Azerbaijan",
      value: "Azerbaijan",
    },
    {
      label: "Bahamas",
      value: "Bahamas",
    },
    {
      label: "Bangladesh",
      value: "Bangladesh",
    },
    {
      label: "Barbados",
      value: "Barbados",
    },
    {
      label: "Belarus",
      value: "Belarus",
    },
    {
      label: "Belgium",
      value: "Belgium",
    },
  ],
  VACANCY_SORTBY: [
    {
      label: "Years of Experience",
      value: "yearsOfExperience",
    },
  ],

  VACANCIES_SEARCH: [
    {
      label: "Keywords",
      name: "keywords",
      type: "text",
    },
    {
      label: "Country",
      name: "country",
      type: "select",
    },
    {
      label: "City",
      name: "city",
      type: "text",
    },
    {
      label: "Language",
      name: "language",
      type: "select",
    },
    {
      label: "Nationality",
      name: "nationality",
      type: "select",
    },
    {
      label: "Sort By",
      name: "vacancy_sortby",
      type: "select",
    },
  ],
  VACANCY_CREATE: [
    {
      name: "title",
      type: "text",
      min: 4,
    },
    {
      name: "publish",
      type: "select",
    },
    {
      name: "expiryDate",
      type: "date",
    },
    {
      name: "requirements",
      type: "text",
      min: 5,
    },
    {
      name: "candidate.minExperience",
      type: "select",
    },
    {
      name: "candidate.firstLanguage",
      type: "select",
    },
    {
      name: "location.city",
      type: "text",
      min: 3,
    },
    {
      name: "location.country",
      type: "select",
    },
    {
      name: "location.workstation",
      type: "select",
    },
    {
      name: "location.travelRequired",
      type: "select",
    },
    {
      name: "time.startDate",
      type: "date",
    },
    {
      name: "time.timePeriod",
      type: "select",
    },
    {
      name: "time.minAvailability",
      type: "select",
    },
    {
      name: "time.projectPhase",
      type: "select",
    },
    {
      name: "payment.hourlyRate",
      type: "select",
    },
    {
      name: "payment.payType",
      type: "select",
    },
    {
      name: "payment.flights",
      type: "select",
    },
    {
      name: "payment.hotel",
      type: "select",
    },
    {
      name: "contact.name",
      type: "text",
      min: 4,
    },
    {
      name: "contact.email",
      type: "text",
      min: 6,
    },
  ],
  ECONSULTATION_BOOKING: [
    {
      name: "clientInformation.name",
      type: "text",
      min: "3",
    },
    {
      name: "clientInformation.email",
      type: "text",
      min: "3",
    },
    {
      name: "clientInformation.requirements",
      type: "text",
      min: "4",
    },
    {
      name: "bookingDetails.preferenceDate",
      type: "date",
    },
    {
      name: "bookingDetails.startTime",
      type: "select",
    },
    {
      name: "bookingDetails.endTime",
      type: "select",
    },
  ],
};

export const TIMES = [
  "12:00 AM",
  "12:30 AM",
  "01:00 AM",
  "01:30 AM",
  "02:00 AM",
  "02:30 AM",
  "03:00 AM",
  "03:30 AM",
  "04:00 AM",
  "04:30 AM",
  "05:00 AM",
  "05:30 AM",
  "06:00 AM",
  "06:30 AM",
  "07:00 AM",
  "07:30 AM",
  "08:00 AM",
  "08:30 AM",
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
  "03:00 PM",
  "03:30 PM",
  "04:00 PM",
  "04:30 PM",
  "05:00 PM",
  "05:30 PM",
  "06:00 PM",
  "06:30 PM",
  "07:00 PM",
  "07:30 PM",
  "08:00 PM",
  "08:30 PM",
  "09:00 PM",
  "09:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const timeValues = [
  {
    value: "12:00 AM",
    weight: 0,
  },
  {
    value: "12:30 AM",
    weight: 1,
  },
  {
    value: "01:00 AM",
    weight: 2,
  },
  {
    value: "01:30 AM",
    weight: 3,
  },
  {
    value: "02:00 AM",
    weight: 4,
  },
  {
    value: "02:30 AM",
    weight: 5,
  },
  {
    value: "03:00 AM",
    weight: 6,
  },
  {
    value: "03:30 AM",
    weight: 7,
  },
  {
    value: "04:00 AM",
    weight: 8,
  },
  {
    value: "04:30 AM",
    weight: 9,
  },
  {
    value: "05:00 AM",
    weight: 10,
  },
  {
    value: "05:30 AM",
    weight: 11,
  },
  {
    value: "06:00 AM",
    weight: 12,
  },
  {
    value: "06:30 AM",
    weight: 13,
  },
  {
    value: "07:00 AM",
    weight: 14,
  },
  {
    value: "07:30 AM",
    weight: 15,
  },
  {
    value: "08:00 AM",
    weight: 16,
  },
  {
    value: "08:30 AM",
    weight: 17,
  },
  {
    value: "09:00 AM",
    weight: 18,
  },
  {
    value: "09:30 AM",
    weight: 19,
  },
  {
    value: "10:00 AM",
    weight: 20,
  },
  {
    value: "10:30 AM",
    weight: 21,
  },
  {
    value: "11:00 AM",
    weight: 22,
  },
  {
    value: "11:30 AM",
    weight: 23,
  },
  {
    value: "12:00 PM",
    weight: 24,
  },
  {
    value: "12:30 PM",
    weight: 25,
  },
  {
    value: "01:00 PM",
    weight: 26,
  },
  {
    value: "01:30 PM",
    weight: 27,
  },
  {
    value: "02:00 PM",
    weight: 28,
  },
  {
    value: "02:30 PM",
    weight: 29,
  },
  {
    value: "03:00 PM",
    weight: 30,
  },
  {
    value: "03:30 PM",
    weight: 31,
  },
  {
    value: "04:00 PM",
    weight: 32,
  },
  {
    value: "04:30 PM",
    weight: 33,
  },
  {
    value: "05:00 PM",
    weight: 34,
  },
  {
    value: "05:30 PM",
    weight: 35,
  },
  {
    value: "06:00 PM",
    weight: 36,
  },
  {
    value: "06:30 PM",
    weight: 37,
  },
  {
    value: "07:00 PM",
    weight: 38,
  },
  {
    value: "07:30 PM",
    weight: 39,
  },
  {
    value: "08:00 PM",
    weight: 40,
  },
  {
    value: "08:30 PM",
    weight: 41,
  },
  {
    value: "09:00 PM",
    weight: 42,
  },
  {
    value: "09:30 PM",
    weight: 43,
  },
  {
    value: "10:00 PM",
    weight: 44,
  },
  {
    value: "10:30 PM",
    weight: 45,
  },
  {
    value: "11:00 PM",
    weight: 46,
  },
  {
    value: "11:30 PM",
    weight: 47,
  },
];
