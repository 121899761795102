import { useMutation } from "react-query";
import axiosInstance from "../../axios/axios";
import { fullName, getPathEnv } from "../../helper";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../routes/Reactquery";
import { Puff } from "../Layout/Loading";
import { toast } from "react-toastify";
import { fetchFreelancerForEconsultancy } from "../../fetch/freelancer/all";
import { fetchFavFreelancers } from "../../fetch/freelancer/fav";
import { fetchFreelancerProfile } from "../../fetch/freelancer/profile";

import { handleDownload } from "../Tools/Download";

export function FreelancerBox2({
  freelancer,
  fav,
  preview,
  invalidateFav,
  econsultBtn = true,
}) {
  const navigate = useNavigate();

  const isLoggedIn = !!localStorage.getItem("Auth");

  invalidateFav?.length &&
    fetchFreelancerForEconsultancy(
      freelancer?.user?.id ? freelancer?.user?.id : invalidateFav[1],
      window.location.pathname.includes("econsultancy") || isLoggedIn
    );
  fetchFreelancerProfile();

  const user = freelancer?.user;
  const freelancerExp = freelancer?.experience;

  const IamStyles = {
    cursor: "default",
    // fontSize: "1.1rem",
    fontWeight: "bold",
    border: "0px solid #345fb4",
  };

  function ViewResume() {
    if (isLoggedIn) {
      let url;
      let freelancerFullName = fullName(
        freelancer?.user?.firstName,
        freelancer?.user?.lastName
      );
      if (freelancer?.resume?.path) {
        url = getPathEnv(freelancer?.resume?.path);
        // return window.open(getPathEnv(freelancer?.resume?.path), "_blank");
      } else if (freelancer?.resume) {
        url = URL.createObjectURL(freelancer?.resume);
        // return window.open(URL.createObjectURL(freelancer?.resume), "_blank");
      }
      if (url)
        return handleDownload(url, `Resume-${freelancerFullName}`, navigate);
      else return toast.warning(" This freelancer did not provide a resume.");
    } else {
      toast.warning("Redirecting to login page.");
      setTimeout(() => {
        navigate("/login");
      }, 500);
    }
  }

  async function favFreelancer() {
    await axiosInstance.post("/freelancer/favorite", {
      id: freelancer?.id,
      fav: !fav,
    });
    await queryClient.invalidateQueries(["freelancerprofile"]);
    await queryClient.invalidateQueries(["profile"]);
  }

  const favMutation = useMutation(favFreelancer, {
    onSuccess: async () => {
      toast.success(
        ` Successfully ${
          fav ? `removed a` : `added a new`
        } favorite freelancer `
      );
      await queryClient.invalidateQueries([
        "freelancerforeconsultancy",
        freelancer?.user?.id,
      ]);

      await queryClient.invalidateQueries([`favfreelancers`]);
    },
    onError: async () => {
      toast.error("Some error occured.");
    },
  });

  const LiOne = [
    {
      caption: "Experience",
      data: freelancerExp?.totalExperience,
    },
    {
      caption: "Degree",
      data: freelancerExp?.degree,
    },
    {
      caption: "Nationality",
      data: freelancerExp?.nationality,
    },
    {
      caption: "Residency",
      data: freelancerExp?.residencyVisa,
    },
    {
      caption: "Hourly Rate",
      data: freelancerExp?.hourlyRate,
    },
    {
      caption: "Language",
      data: freelancerExp?.secondLanguage
        ? `${freelancerExp?.firstLanguage}, ${freelancerExp?.secondLanguage}.`
        : freelancerExp?.firstLanguage,
    },
  ];
  const LiTwo = [
    { caption: "Available", data: freelancerExp?.availability },
    { caption: "Start date", data: freelancerExp?.startDate },
    { caption: "Work station", data: freelancerExp?.workStation },
    { caption: "Travel willingness", data: freelancerExp?.travelWillingness },
  ];
  const isEconsultant = freelancer?.Iam?.includes("E-Consultant");

  const freelancerResumePath =
    isLoggedIn && freelancer?.resume?.path ? freelancer?.resume?.path : false;
  return (
    <>
      <section className="mt-5 f-list-2">
        <div className="container">
          <div className="bg-col" style={{ backgroundColor: "white" }}>
            <div className="row">
              <div className="col-lg-2 fist" style={{ margin: "auto" }}>
                <h5>{fullName(user?.firstName, user?.lastName)}</h5>

                <img
                  style={{
                    height: "12rem",
                    width: "12rem",
                    borderRadius: "100%",
                    marginBlock: "1rem",
                    aspectRatio: "1/1",
                    objectFit: "cover",
                  }}
                  loading="lazy"
                  decoding="async"
                  src={
                    freelancer?.file
                      ? URL.createObjectURL(freelancer?.file)
                      : getPathEnv(freelancer?.profilePhoto?.path) ||
                        "/images/freelance-list/profile_ 1.png"
                  }
                  alt=""
                />
                <h6>{freelancer?.companyName}</h6>
                <p>
                  {user?.city} {user?.country}
                </p>
              </div>
              <div
                className="col-lg-6 second"
                style={{
                  fontSize: "1.3rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  className="box-flex"
                  style={{
                    // backgroundColor: "blue",
                    marginBlock: ".4rem",
                  }}
                >
                  <h4>{freelancer?.profileHeader}</h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      maxHeight: "3rem",
                    }}
                  >
                    <div
                      style={{
                        height: "3rem",
                        width: "3rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {favMutation.isLoading ? (
                        <Puff />
                      ) : (
                        <img
                          style={{
                            cursor: "pointer",
                            filter: !fav && "grayscale(100%)",
                          }}
                          src="/icons/star.svg"
                          alt=""
                          onClick={(e) => {
                            if (preview)
                              return toast.warning(
                                "You cannot favorite yourself."
                              );
                            if (favMutation?.isLoading) return;
                            favMutation.mutate();
                          }}
                        />
                      )}
                    </div>
                    <ImageLink
                      icon={"/icons/web.svg"}
                      link={freelancer?.personalLink}
                      isLoggedIn={isLoggedIn}
                      preview={preview}
                    />
                    <ImageLink
                      icon={"/icons/linkdin.svg"}
                      link={freelancer?.linkedinLink}
                      isLoggedIn={isLoggedIn}
                      preview={preview}
                    />
                  </div>
                </div>
                <div
                  // className="border-line"
                  style={{
                    marginTop: "2.4rem",
                    display: "flex",
                    // backgroundColor: "yellow",
                    flexDirection: "column",
                    height: "66%",
                    overflow: "hidden",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ minHeight: "7rem", textAlign: "justify" }}>
                    {freelancer?.profileSummary ? (
                      <span style={{}}>{freelancer?.profileSummary}</span>
                    ) : (
                      <span style={{ color: "#444444", fontStyle: "italic" }}>
                        This freelancer did not provide a profile summary.
                      </span>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    width: "99%",
                    paddingBlock: "1rem",

                    borderRadius: "2rem",
                    backgroundColor: "#000000010",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <>
                      {!!freelancer?.skills.length ? (
                        <>
                          <span style={{ fontWeight: "bold" }}>Skills: </span>
                          {freelancer?.skills?.map((l, index) => (
                            <span key={l}>
                              {l}
                              {freelancer?.skills?.length - 1 != index ? (
                                <>, </>
                              ) : (
                                <>.</>
                              )}
                            </span>
                          ))}
                        </>
                      ) : (
                        <span style={{ color: "#444444", fontStyle: "italic" }}>
                          This freelancer did not provide any skills.
                        </span>
                      )}
                    </>
                  </div>
                </div>

                {freelancer?.Iam?.length > 0 && (
                  <div
                    className="a-buttos"
                    style={{ justifyContent: "flex-start" }}
                  >
                    {freelancer?.Iam?.includes("Trainer") && (
                      <a
                        style={{
                          ...IamStyles,
                          backgroundColor: "#f88a3b",
                          color: "black",
                        }}
                      >
                        Trainer
                      </a>
                    )}
                    {freelancer?.Iam?.includes("Consultant") && (
                      <a
                        style={{
                          ...IamStyles,
                          backgroundColor: "#e0d03e",
                          color: "black",
                        }}
                      >
                        Consultant
                      </a>
                    )}
                    {freelancer?.Iam?.includes("E-Consultant") && (
                      <a
                        style={{
                          ...IamStyles,
                          backgroundColor: "#345fb4",
                          color: "white",
                        }}
                      >
                        e-Consultant
                      </a>
                    )}
                  </div>
                )}
              </div>
              <div className="col-lg-4 third">
                <div className="list-flex">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "3rem",
                      // backgroundColor: "yellow",
                      width: "100%",
                      justifyContent: "space-between",
                      marginInline: "5rem",
                    }}
                  >
                    <div
                      className="box"
                      onClick={() => {
                        if (freelancer?.resume?.size > 0) {
                          return window.open(
                            URL.createObjectURL(freelancer?.resume),
                            "_blank"
                          );
                        }

                        if (!freelancer?.resume?.path) {
                          return toast.warn(
                            "This freelancer did not provide a resume."
                          );
                        }

                        return window.open(
                          getPathEnv(freelancer?.resume?.path),
                          "_blank"
                        );

                        if (!isLoggedIn) {
                          navigate("/login");
                          return toast.warn("Please login first.");
                        }
                        if (!freelancer?.resume?.path) {
                          return toast.warn(
                            "This freelancer did not provide a resume."
                          );
                        }
                      }}
                    >
                      <a
                        className="comp-abtn1"
                        style={{ cursor: "pointer" }}
                        // href={freelancerResumePath}
                        // download={!!freelancer?.resume?.path}
                      >
                        Resume
                      </a>
                    </div>
                    <div className="box">
                      <a
                        className="comp-abtn2"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#444444",
                          borderColor: "#444444",
                        }}
                        href={isLoggedIn && `mailto:${user?.email}`}
                        onClick={() => !isLoggedIn && navigate("/login")}
                      >
                        Message
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <ul>
                      {LiOne.map((li, idx) => (
                        <LiStyled
                          caption={li.caption}
                          data={li.data}
                          key={("liOne", idx)}
                        />
                      ))}
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul>
                      {LiTwo.map((li, idx) => (
                        <LiStyled
                          caption={li.caption}
                          data={li.data}
                          key={("liOne", idx)}
                        />
                      ))}

                      {/* <li>Freelancer Time Zone: Dubai</li> */}

                      <li className={!isLoggedIn && `hidetext`}>
                        <>
                          Email:&nbsp;
                          <span style={{ fontWeight: "normal" }}>
                            {isLoggedIn ? (
                              <span> {user?.email}</span>
                            ) : (
                              <>************</>
                            )}
                          </span>
                          <div
                            className="insideTooltip "
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/login")}
                          >
                            Please login to view
                          </div>
                        </>
                      </li>

                      <li className={`hidetext`}>
                        <>
                          Phone:&nbsp;
                          {isLoggedIn ? (
                            user?.showPhoneRegistered ? (
                              <span style={{ fontWeight: "normal" }}>
                                {user?.phone}
                              </span>
                            ) : (
                              <>
                                <span style={{ fontWeight: "normal" }}>
                                  ***********
                                </span>
                                <div
                                  className="insideTooltip "
                                  style={{ cursor: "pointer" }}
                                >
                                  Phone number not available.
                                </div>
                              </>
                            )
                          ) : user?.showPhonePublic ? (
                            <span style={{ fontWeight: "normal" }}>
                              {user?.phone}
                            </span>
                          ) : (
                            <>
                              <span style={{ fontWeight: "normal" }}>
                                ************
                              </span>
                              <div
                                className="insideTooltip "
                                style={{ cursor: "pointer" }}
                                onClick={() => navigate("/login")}
                              >
                                Please login to view
                              </div>
                            </>
                          )}
                        </>
                      </li>
                    </ul>
                    <div>
                      {econsultBtn && (
                        <button
                          class="abtn3"
                          type="button"
                          style={{
                            backgroundColor: !isEconsultant && "gray",
                            border: !isEconsultant && "1px solid gray",
                            maxWidth: "100%",
                          }}
                          onClick={() => {
                            if (preview)
                              return toast.warning(
                                "You cannot book yourself for e-Consultation."
                              );
                            if (!isEconsultant)
                              return toast.warning(
                                "This freelancer does not provide e-Consultant Services"
                              );
                            isLoggedIn
                              ? navigate(
                                  `/econsultancy/booking/${freelancer?.user?.id}`
                                )
                              : navigate("/login");
                          }}
                        >
                          Book e-Consultation
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

function LiStyled({ caption, data }) {
  return (
    <li style={{}}>
      {caption}:
      <span style={{ fontWeight: "normal" }}> {data || "UNKNOWN"}</span>
    </li>
  );
}

function ImageLink({ preview, isLoggedIn, link, icon }) {
  const navigate = useNavigate();
  return (
    <>
      {link && (
        <img
          onClick={() => {
            // if (preview) return;
            // if (isLoggedIn) {
            setTimeout(() => {
              if (link.startsWith("http://") || link.startsWith("https://")) {
                window.open(link, "_blank");
              } else {
                const modifiedLink = "http://" + link;
                window.open(modifiedLink, "_blank");
              }
            });
            // } else navigate("/login");
          }}
          style={{ cursor: "pointer" }}
          src={icon}
          alt=""
        />
      )}
    </>
  );
}
