import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";

export function fetchFreelancerProfile() {
  const isLoggedIn = !!localStorage.getItem("Auth");

  const fetchData = async () =>
    await axiosInstance.get("/freelancer").then((res) => res.data.data);

  const { isLoading, error, data } = useQuery({
    queryKey: ["freelancerprofile", localStorage.getItem("Auth")],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isLoggedIn,
  });

  return { isLoading, error, data };
}
