import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../routes/Reactquery";
import { useOnClickOutside } from "../../Hook/clickOutSide";

export function ProfileMenuModal({ setMenuOpen, refComp }) {
  const navigate = useNavigate();

  const Links = [
    { title: "My Account", link: "/profile" },
    { title: "Freelancer Profile", link: "/freelancerprofile" },
    { title: "e-Consultation Settings", link: "/econsultationsettings" },
    { title: "Favorite Freelancers", link: "/favorite-freelancers" },
    { title: "Applied To Vacancies", link: "/vacancies-applied" },
    { title: "Posted Vacancies", link: "/vacancies-posted" },
    { title: "e-Consultant Booking", link: "/econsultancy/bookings" },
    { title: "Help and Support", link: "/help-and-support" },
    { title: "Logout", link: "/login" },
  ];

  useOnClickOutside(refComp, () => setMenuOpen(false));

  useEffect(() => {
    function handleEscape(event) {
      if (event.keyCode === 27) {
        console.log("ESCAPE");
        setMenuOpen(false);
      }
    }

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        top: "7rem",
        zIndex: "1001",
        paddingBottom: "4rem",
      }}
    >
      <div
        className="dropShadow"
        style={{
          width: "140%",
          backgroundColor: "#04225e",
          color: "white",
          style: "flex",
          paddingBottom: "1rem",
          flexDirection: "column",
          marginInline: "2rem",
        }}
      >
        {Links.map((link) => (
          <div
            key={link.title}
            className="profileLink"
            href="#"
            style={{ paddingLeft: "3rem", fontSize: "1.25rem" }}
            onClick={async () => {
              setMenuOpen(false);
              if (link.title === "Logout") {
                localStorage.clear();
                queryClient.invalidateQueries(["profile"]);
              }
              navigate(link?.link);
            }}
          >
            {link.title}
          </div>
        ))}
      </div>
    </div>
  );
}
