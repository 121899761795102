import React, { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios/axios";
import FormBuilder from "../Components/FormBuilder";
import { fetchCMS } from "../fetch/cms/main";
import { fetchFreelancerProfile } from "../fetch/freelancer/profile";
import { fetchInput } from "../fetch/input/main";
import { fetchProfile } from "../fetch/profile/myProfile";
import { queryClient } from "../routes/Reactquery";
import { compareObjects } from "../utils/helper";
import { toast } from "react-toastify";
import { EmailCnf } from "../Components/Profile/EmailCnf";
import { handleScrollDown } from "../helper/scroll";
import SecureComponent from "../Components/SecureComponent";
import { VerifyEmailButton } from "../Components/Profile/VerifyEmailButton";

function Container() {
  return (
    <>
      <SecureComponent>
        <>
          <Profile />
          <Password />
          {/* <EmailCnf /> */}
        </>
      </SecureComponent>
    </>
  );
}
function Profile() {
  const navigation = useNavigate();
  const [user, setUser] = useState({});

  const { data, isLoading, error } = fetchProfile();
  const { data: freelancer } = fetchFreelancerProfile();

  const {
    data: inputAPI,
    isLoading: inputIsLoading,
    error: inputError,
  } = fetchInput();

  function ChangeInput(e) {
    e.preventDefault();
    setUser({ ...user, [e.target.name]: e.target.value });
  }

  if (!isLoading && !user) {
    if (data == null) navigation("/login");
    // if (data?.data?.user) setUser(data?.data?.user);
  }
  const profileMutation = useMutation(updateProfile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["profile"], ["freelancerprofile"]);
      await queryClient.invalidateQueries(["freelancerprofile"]);
    },
  });

  function sync() {
    let flag = true;
    if (!!Object?.keys(user).length) {
      Object?.keys(user)?.map((key) => {
        if (user?.[key] != data?.data?.user?.[key]) flag = false;
      });
      return flag;
    } else return true;
  }
  const isInSync = sync();
  const approved = freelancer?.data?.isUpdate?.approve;
  const mailVerified = freelancer?.data?.isUpdate?.mailVerified;

  async function updateProfile() {
    await axiosInstance.post("/user/myprofile", { ...user });
  }

  if (!!data && !!inputAPI)
    return (
      <>
        <section className="f-list-3" style={{ marginBlock: "2rem" }}>
          <div className="container">
            <div className="bg-col">
              <div className="heder-content">
                <h6>My Account</h6>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (isInSync) return;
                  toast.promise(profileMutation.mutateAsync(), {
                    pending: "Account is updating",
                    success: "Account updated",
                    error: "Some error has been occured ",
                  });
                }}
              >
                <div className="list-flex">
                  <div className="box  ">
                    <p>First Name *</p>

                    <input
                      type="name"
                      name="firstName"
                      defaultValue={data?.data?.user?.firstName}
                      className="input"
                      required
                      onChange={ChangeInput}
                      disabled={isLoading}
                      maxLength={60}
                    />
                  </div>
                  <div className="box ">
                    <p>Last name *</p>

                    <input
                      type="name"
                      defaultValue={data?.data?.user?.lastName}
                      name="lastName"
                      className="input"
                      required
                      disabled={isLoading}
                      onChange={ChangeInput}
                      maxLength={60}
                    />
                  </div>
                  <div className="box ">
                    <p>Email *</p>

                    <input
                      type="email"
                      name="email"
                      defaultValue={data?.data?.user?.email}
                      className="input"
                      onChange={ChangeInput}
                      required
                      disabled={isLoading}
                      maxLength={60}
                    />
                    <div class="subtitle">
                      {mailVerified ? (
                        <div
                          class="checkbox"
                          style={{
                            display: "flex",
                            gap: ".5rem",
                            cursor: !mailVerified && "pointer",
                          }}
                          onClick={() => {
                            if (!mailVerified) handleScrollDown();
                          }}
                        >
                          <label
                            style={{
                              fontSize: "1.2rem",
                              marginRight: "2rem",
                              cursor: !mailVerified && "pointer",
                            }}
                          >
                            Email Confirmed
                          </label>
                          <input
                            type="checkbox"
                            id="vehicle2"
                            name="vehicle2"
                            defaultValue="Car"
                            checked={freelancer?.data?.isUpdate?.mailVerified}
                            readOnly
                            style={{ cursor: !mailVerified && "pointer" }}
                          />
                        </div>
                      ) : (
                        <VerifyEmailButton />
                      )}
                    </div>
                  </div>
                </div>
                <div className="list-flex">
                  <div className="box ">
                    <p>Phone</p>

                    <input
                      type="name"
                      name="phone"
                      defaultValue={data?.data?.user?.phone}
                      className="input"
                      required
                      onChange={ChangeInput}
                      disabled={isLoading}
                      maxLength={60}
                    />
                    <div class="subtitle">
                      <div>
                        <label
                          htmlFor="publicshow"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "2rem",
                            textAlign: "end",
                          }}
                        >
                          Show phone to public
                        </label>
                        <input
                          type="checkbox"
                          id="publicshow"
                          name="publicshow"
                          defaultValue="Car"
                          defaultChecked={data?.data?.user?.showPhonePublic}
                          onChange={(e) =>
                            setUser((user) => ({
                              ...user,
                              showPhonePublic: e.target.checked,
                            }))
                          }
                        />
                      </div>
                      <div>
                        <label
                          htmlFor="regshow"
                          style={{
                            fontSize: "1.2rem",
                            marginRight: "2rem",
                            textAlign: "end",
                          }}
                        >
                          Show phone to registered users
                        </label>
                        <input
                          type="checkbox"
                          id="regshow"
                          name="regshow"
                          defaultValue="Car"
                          defaultChecked={data?.data?.user?.showPhoneRegistered}
                          onChange={(e) =>
                            setUser((user) => ({
                              ...user,
                              showPhoneRegistered: e.target.checked,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="box ">
                    <p>Country *</p>

                    <select
                      style={{
                        backgroundColor:
                          inputIsLoading || isLoading ? "#cacaca" : "#cacaca",
                      }}
                      name="country"
                      defaultValue={data?.data?.user?.country}
                      className="input"
                      required
                      onChange={ChangeInput}
                      disabled={isLoading || inputIsLoading}
                    >
                      {inputAPI?.country?.map((val) => (
                        <>
                          <option key={val} value={val}>
                            {val}
                          </option>
                          {val == "Yemen" && (
                            <option value="" disabled="disabled">
                              ------------------------------------------------------------------------------
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="box ">
                    <p>City *</p>

                    <input
                      type="name"
                      name="city"
                      className="input"
                      onChange={ChangeInput}
                      //   placeholder="Performance"
                      disabled={isLoading}
                      defaultValue={data?.data?.user?.city}
                      required
                    />
                  </div>
                </div>

                <div className="heder-content">
                  {/* <h6>Manage Profile</h6> */}
                  <div
                    className="a-btns"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        backgroundColor: "#00000000",
                        border: "0px solid black",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      {/* <a href="#">Preview Profile</a> */}
                      <a
                        style={{
                          backgroundColor: isInSync && "#909090",
                          border: `1px solid #909090`,
                        }}
                      >
                        Save Account
                      </a>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    );
}

function Password() {
  const { data, isLoading, error } = fetchProfile();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    current: ``,
    new: "",
    newConfirm: "",
  });
  // if (data && !user) {
  //   setUser(data?.data?.user);
  // }

  async function updatePwd() {
    await axiosInstance.post("/user/password/update", input);
  }
  const profileMutation = useMutation(updatePwd, {
    onSuccess: () => {
      toast.success("Password updated");
    },
    onError: (e) => {
      if (e?.response?.data?.errors?.error == "invalidCurrentPwd")
        return toast.error("Invalid current password");

      toast.error("Some error occured");
    },
  });

  function changeInput(e) {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const allFilled = input.current && input.new && input.newConfirm;
  const pwdIsEql = input.new == input.newConfirm;
  const newIsSame = input.current == input.new;

  return (
    <>
      <section className="f-list-3" style={{ marginBlock: "2rem" }}>
        <div className="container">
          <div className="bg-col">
            <div className="heder-content">
              <h6>Password</h6>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();

                if (!allFilled)
                  return toast.warning("Please enter all fields.");

                if (!pwdIsEql)
                  return toast.warning("Passwords does not match.");

                if (newIsSame)
                  return toast.warning(
                    "New password cannot be same as the old one."
                  );

                profileMutation.mutate();
              }}
            >
              <div className="list-flex">
                <div className="box  ">
                  <p>Current Password</p>

                  <input
                    type="password"
                    name="current"
                    className="input"
                    value={input.current}
                    onChange={changeInput}
                    placeholder="Enter your current password here."
                  />
                  <div>
                    <a
                      onClick={() => navigate("/forgot")}
                      style={{
                        fontSize: "1.1rem",
                        cursor: "pointer",
                        color: "blue",
                      }}
                    >
                      Forgot your password?
                    </a>
                  </div>
                </div>
              </div>
              <div className="list-flex">
                <div className="box  ">
                  <p>New Password</p>

                  <input
                    type="password"
                    name="new"
                    className="input"
                    value={input.new}
                    onChange={changeInput}
                    placeholder="Enter your new password here."
                  />
                </div>
                <div className="box  ">
                  <p>Confirm New password</p>

                  <input
                    type="password"
                    name="newConfirm"
                    className="input"
                    value={input.newConfirm}
                    onChange={changeInput}
                    placeholder="Please confirm your new password."
                  />
                </div>
              </div>
              {!pwdIsEql && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "100%",
                    color: "red",
                    fontSize: "1.3rem",
                    marginRight: "2rem",
                  }}
                >
                  <label>Please check the typed data</label>
                </div>
              )}
              <div className="heder-content">
                {/* <h6>Manage Profile</h6> */}
                <div
                  className="a-btns"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#00000000",
                      border: "0px solid black",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    <a
                      style={{
                        backgroundColor: (!allFilled || !pwdIsEql) && "#909090",
                        border: `1px solid #909090`,
                      }}
                    >
                      Save Password
                    </a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default Container;
