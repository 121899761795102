import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FreelancerBox2 } from "../../Components/Freelancer/FreelancerBox2";
import axiosInstance from "../../axios/axios";
import { FreelancerBoxLoading } from "../../Components/Freelancer/FreelancerBoxLoading";
import { fetchFreelancerByVacancy } from "../../fetch/vacancy/all";
import { fetchFavFreelancers } from "../../fetch/freelancer/fav";

const VacancyFreelancer = () => {
  let { vacancyId } = useParams();

  const [page, setPage] = useState(1);

  const { isLoading, error, data } = fetchFreelancerByVacancy(page, vacancyId);
  const nextPage = data?.doesNextExist;
  fetchFavFreelancers();

  return (
    <div>
      <div
        className="container"
        style={{ marginTop: "1.5rem", color: "rgb(17, 115, 186)" }}
      >
        <h2>Applied Freelancers</h2>
      </div>
      <div
        style={{
          display: "flex",
          fontSize: "2rem",
          justifyContent: "center",
          textAlign: "center",
          gap: "1rem",
          margin: "1rem",
        }}
      >
        {page > 1 && (
          <button
            className="page-btn"
            onClick={() => setPage((prev) => (prev != 0 ? prev - 1 : 0))}
          >
            &larr;
          </button>
        )}
        <div className="page-btn">{!data?.data?.length == 0 && page}</div>
        {!!nextPage && (
          <button
            className="page-btn"
            onClick={() =>
              setPage((prev) => (data?.data?.length > 0 ? prev + 1 : prev))
            }
          >
            →
          </button>
        )}
      </div>
      {data?.data?.map((freelancer, index) => (
        <FreelancerBox2 key={index} freelancer={freelancer} />
      ))}
      {isLoading && <FreelancerBoxLoading />}
    </div>
  );
};

export default VacancyFreelancer;
