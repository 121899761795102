import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Nav from "./Nav";
import SwiperComponent from "./Slider/SwiperMain";

const Layout = ({ swiper, children, swiperData }) => {
  return (
    <>
      <Header />
      <Nav />
      {swiper && (
        <SwiperComponent
          key={swiperData?.page}
          video={swiperData?.video}
          page={swiperData?.page}
        />
      )}
      <div style={{ minHeight: "50vh" }}>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
