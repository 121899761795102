import React from "react";
import { useMutation } from "react-query";
import axiosInstance from "../axios/axios";
import { fetchFreelancerProfile } from "../fetch/freelancer/profile";
import { fetchInput } from "../fetch/input/main";
import { queryClient } from "../routes/Reactquery";
import { getParams } from "../helper";
import EconsultationForm from "../modules/Econsultation/EconsultationForm";
import SecureComponent from "../Components/SecureComponent";
import { FreelancerBoxLoading } from "../Components/Freelancer/FreelancerBoxLoading";

const EconsultationMain = () => {
  const { isLoading } = fetchFreelancerProfile();
  const {
    data: input,
    isLoading: inputIsLoading,
    error: inputError,
  } = fetchInput();
  const isEmailVerified = getParams("firsttime") == "true";

  const freelancerMutation = useMutation(UpdateFreelancer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["freelancerprofile"]);
      queryClient.invalidateQueries(["profile"]);
    },
  });

  async function UpdateFreelancer(value) {
    if (value?.updatedAt == value?.createdAt) {
      value = { ...value, isUpdate: { applied: true } };
    }
    await axiosInstance.post("/freelancer", value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  if (isLoading || inputIsLoading)
    return (
      <>
        <FreelancerBoxLoading />;
      </>
    );
  else
    return (
      <SecureComponent>
        <>
          {isEmailVerified && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginTop: "1rem",
                color: "#0e71b9",
                fontSize: "1.5em",
                fontWeight: "bold",
                paddingTop: "2rem",
                marginInline: "3rem",
              }}
            >
              Update your freelancer profile to increase your chances of getting
              hired. It's easy and can help you stand out from the competition!
            </div>
          )}
          <EconsultationForm freelancerMutation={freelancerMutation} />
        </>
      </SecureComponent>
    );
};

export default EconsultationMain;
