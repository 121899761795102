import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../axios/axios";
import { fetchFreelancerProfile } from "../fetch/freelancer/profile";
import { fetchInput } from "../fetch/input/main";
import { queryClient } from "../routes/Reactquery";
import { DateSelectorFreelancer } from "../Components/FreelancerProfile/Datepicker";
import { toast } from "react-toastify";
import { capsFirstLetter, fullName, getParams, getPathEnv } from "../helper";
import { Preview } from "../Components/Freelancer/Preview";
import SkillsSelect from "../Components/Freelancer/SkillsSelect";
import SecureComponent from "../Components/SecureComponent";

import { FreelancerBoxLoading } from "../Components/Freelancer/FreelancerBoxLoading";
import { FreelancerBox2 } from "../Components/Freelancer/FreelancerBox2";
import ConfirmationModal from "../Components/Layout/ConfirmationModal";
import ImageCropper from "../Components/Tools/ImageCrop";
import { handleDownload } from "../Components/Tools/Download";
import FirstTimeWarning from "../Components/Tools/FirstTimeWarning";

const FreelancerProfileMain = () => {
  const { isLoading } = fetchFreelancerProfile();
  const {
    data: input,
    isLoading: inputIsLoading,
    error: inputError,
  } = fetchInput();
  // const isLoading = true;
  const isEmailVerified = getParams("firsttime") == "true";

  const freelancerMutation = useMutation(UpdateFreelancer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["freelancerprofile"]);
      queryClient.invalidateQueries(["profile"]);
    },
  });

  async function UpdateFreelancer(value) {
    if (value?.updatedAt == value?.createdAt) {
      value = { ...value, isUpdate: { applied: true } };
    }
    await axiosInstance.post("/freelancer", value, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
  if (isLoading || inputIsLoading)
    return (
      <>
        <FreelancerBoxLoading />;
      </>
    );
  else
    return (
      <SecureComponent>
        <>
          {isEmailVerified && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // marginTop: "1rem",
                color: "#0e71b9",
                fontSize: "1.5em",
                fontWeight: "bold",
                paddingTop: "2rem",
                marginInline: "3rem",
              }}
            >
              Update your freelancer profile to increase your chances of getting
              hired. It's easy and can help you stand out from the competition!
            </div>
          )}
          <FreelancerProfile freelancerMutation={freelancerMutation} />
          {/* <EconsultationForm
            freelancerMutation={freelancerMutation}
            setIam={setIam}
            iAm={iAm}
          /> */}
        </>
      </SecureComponent>
    );
};

const FreelancerProfile = ({ freelancerMutation }) => {
  const navigate = useNavigate();
  const [value, setValue] = useState();
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [confirmWindow, setConfirmWindow] = useState(false);
  const [CropView, SetCropView] = useState(false);

  const [pfpSource, setPfpSource] = useState();

  const [navigateWarn, setNavigateWarn] = useState(false);
  // if (!isLoggedIn) navigation("/login");

  const linkedInRef = useRef();

  const toggleWarning = () => {
    setConfirmWindow("navigation");
  };

  const {
    data: input,
    isLoading: inputIsLoading,
    error: inputError,
  } = fetchInput();

  const { data, isLoading } = fetchFreelancerProfile();

  const freelancer = data?.data;
  const user = freelancer?.user;

  function changeValue(e) {
    e.preventDefault();
    setValue((val) => ({ ...val, [e.target.name]: e.target.value }));
  }
  function changeValueSub(e) {
    setValue((val) => ({
      ...val,
      experience: { ...val?.experience, [e.target.name]: e.target.value },
    }));
  }

  function ViewResume() {
    let url;
    let freelancerFullName = fullName(
      freelancer?.user?.firstName,
      freelancer?.user?.lastName
    );
    if (value?.resume?.fileName) {
      url = getPathEnv(value?.resume?.path);
    } else if (value?.resume) {
      const urlObj = URL.createObjectURL(value?.resume);
      url = new URL(urlObj).href;
      return window.open(url, "_blank");
    }
    if (url)
      return handleDownload(url, `Resume-${freelancerFullName}`, navigate);

    return toast.warn("Please load your resume first");
  }

  function IamCheckBox(e) {
    if (e.target.checked) {
      {
        setValue((prev) => ({
          ...prev,
          Iam: [...prev?.Iam, e.target.name],
        }));
      }
    } else {
      setValue((prev) => ({
        ...prev,
        Iam: prev?.Iam.filter((val) => val != e.target.name),
      }));
    }
  }

  useEffect(() => {
    if (data)
      setValue({
        ...value,
        skills: selectedSkills,
      });
  }, [selectedSkills]);

  useEffect(() => {
    if (data) {
      setSelectedSkills(freelancer.skills);
    }
  }, [data]);

  // useEffect(() => {
  //   // console.log(value?.resume?.name);
  //   if (linkedInRef.current) {
  //     linkedInRef.current.selectionStart = linkedInRef.current.value.length;
  //     linkedInRef.current.selectionEnd = linkedInRef.current.value.length;
  //   }
  // }, [value]);

  if (!value & !isLoading) {
    if (data) {
      const newdata = { ...data.data };
      delete newdata.eConsultation;
      setValue(newdata);
    }
  }

  const fields = [
    {
      title: "Total experience",
      name: "totalExperience",
      input: input?.experiences,
    },
    {
      title: "Degree",
      name: "degree",
      input: input?.degree,
    },
    { title: "Nationality", name: "nationality", input: input?.country },
    { title: "Residency Visa", name: "residencyVisa", input: input?.country },
    { title: "1st Language", name: "firstLanguage", input: input?.language },
    {
      title: "Publish Profile",
      name: "publish",
      input: input?.publishProfile,
      hideAny: true,
      default: "yes",
      warningState: "no",
      safeState: "yes",
    },

    { title: "Availability", name: "availability", input: input?.availability },
    { title: "Work Station", name: "workStation", input: input?.workStation },
    {
      title: "Travel Willingness",
      name: "travelWillingness",
      input: input?.travelWillingness,
    },
    { title: "Hourly Rate", name: "hourlyRate", input: input?.hourlyRate },
    {
      title: "2nd Language",
      name: "secondLanguage",
      input: input?.language,
      notrequired: true,
    },
  ];

  useEffect(() => {
    //for applying default values to state
    if (!data) return;
    const freelancerData = data.data;
    const fExperienceSavedData = freelancerData.experience;
    fields.forEach((field) => {
      if (field?.default) {
        if (!value?.experience?.[field.name]) {
          setValue((prev) => ({
            ...prev,
            experience: { ...prev.experience, [field.name]: field.default },
          }));
        }
      }
    });
  }, [data]);
  const defaultValues = {
    emailVacancies: true,
  };

  function validateAndSubmit() {
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (!field?.notrequired) {
        if (!value?.experience?.[field.name]) {
          return toast.error(`${field.title} is required.`);
        }
      }
    }

    try {
      if (value?.linkedinLink?.length) {
        if (
          value?.linkedinLink?.startsWith("http://") ||
          value?.linkedinLink?.startsWith("https://")
        ) {
          new URL(value?.linkedinLink);
        } else {
          new URL("http://" + value?.linkedinLink);
        }
      }
    } catch (err) {
      return toast.error(
        "Invalid LinkedIn Link, please include https:// in the link"
      );
    }
    try {
      if (value?.personalLink?.length)
        if (
          value?.personalLink?.startsWith("http://") ||
          value?.personalLink?.startsWith("https://")
        ) {
          new URL(value?.personalLink);
        } else {
          new URL("http://" + value?.personalLink);
        }
    } catch (err) {
      return toast.error(
        "Invalid Personal Link, please include https:// in the link"
      );
    }
    const submitData = { ...defaultValues, ...value };

    //  on empty state of skills, pass a value
    //  to backend to tell skills array in empty
    if (!value?.skills?.length) {
      submitData.skills = "empty";
      return toast.error(`Please add atleast 1 skill.`);
    }

    if (!value?.resume || value?.resume == "delete") {
      return toast.error(`Resume is required.`);
    }
    delete submitData.eConsultation;

    if (value?.experience?.publish == "no")
      toast.warn(
        "Your profile wont be visible to other users since you selected no in publish profile."
      );

    toast.promise(freelancerMutation.mutateAsync(submitData), {
      pending: "Saving Freelancer profile...",
      success: "Freelancer profile Saved",
      error: "Some error has been occured ",
    });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isCrop = searchParams.get("crop");
    if (!isCrop) {
      SetCropView(false);
    }
  }, [location.search]);

  if (data && input)
    return (
      <>
        <section class="f-list-3" style={{ marginBlock: "2rem" }}>
          <div class="container">
            {/* <FirstTimeWarning
              note={"Dont forget to save the profile before closing the page"}
              local={"freelancerprofile"}
            /> */}
            <div class="bg-col">
              <form
                style={{ fontSize: "1.2rem" }}
                onSubmit={async (e) => {
                  e.preventDefault();
                  validateAndSubmit();
                }}
              >
                {confirmWindow == "navigation" && (
                  <ConfirmationModal
                    message={"go to My Account."}
                    hint={"This action will discard all unsaved changes."}
                    onConfirm={() => {
                      navigate("/profile");
                    }}
                    close={() => {
                      setConfirmWindow(false);
                    }}
                  />
                )}
                <div class="heder-content">
                  <h6>Freelancer Profile</h6>

                  <div
                    class="a-btns"
                    style={{
                      display: "flex",
                      marginTop: "3rem",
                      alignItems: "flex-start",
                    }}
                  >
                    <Preview button="Preview profile">
                      <FreelancerBox2
                        freelancer={{
                          ...freelancer,
                          ...value,
                          file: value?.file && value?.file,
                          resume: value?.resume,
                        }}
                        preview
                      />
                    </Preview>
                    <button
                      style={{
                        backgroundColor: "#00000000",
                        border: "0px solid black",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      <a>Save Profile</a>
                    </button>
                  </div>
                </div>
                <div className="subtitle">
                  <div className="titel-color">
                    <h6
                      className="title-requ  "
                      style={{
                        textAlign: "left",
                        marginBlock: "1rem",
                        color: "#8e8e8e",
                        fontSize: "12px",
                      }}
                    >
                      Only fully completed profile will be approved
                    </h6>
                  </div>
                </div>
                {/* PROFILE */}
                <div class="list-flex">
                  <div class="box  hoverParent" onClick={toggleWarning}>
                    <p>First Name *</p>
                    <HoverMenu />
                    <input
                      type="name"
                      name="check in"
                      value={user?.firstName}
                      disabled
                      class="input"
                      required
                    />
                  </div>
                  <div class="box hoverParent" onClick={toggleWarning}>
                    <p>Last Name *</p>
                    <HoverMenu />
                    <input
                      type="name"
                      disabled
                      value={user?.lastName}
                      name="check in"
                      class="input"
                      required
                    />
                  </div>
                  <div class="box hoverParent" onClick={toggleWarning}>
                    <p>Email *</p>
                    <HoverMenu />
                    <input
                      disabled
                      type="email"
                      name="check in"
                      value={user?.email}
                      class="input"
                      required
                    />
                    <div class="subtitle">
                      <div
                        // class="checkbox"
                        style={{ display: "flex" }}
                      >
                        {/* <div class="bg-color"> */}
                        {/* <label>Email Confirmed</label> */}

                        <label
                          style={{ fontSize: "1.2rem", marginRight: "2rem" }}
                        >
                          Email Confirmed
                        </label>
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="Car"
                          checked={freelancer?.isUpdate?.mailVerified}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="box hoverParent" onClick={toggleWarning}>
                    <p>Phone</p>
                    <HoverMenu />
                    <input
                      type="name"
                      disabled
                      name="check in"
                      value={user?.phone}
                      class="input"
                      required
                    />

                    <div class="subtitle">
                      <div>
                        <label style={{ fontSize: "1.2rem", textAlign: "end" }}>
                          Show phone to public
                        </label>
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="Car"
                          checked={user?.showPhonePublic}
                          readOnly
                        />
                      </div>
                      <div>
                        <label style={{ fontSize: "1.2rem", textAlign: "end" }}>
                          Show phone to registered users
                        </label>
                        <input
                          type="checkbox"
                          id="vehicle2"
                          name="vehicle2"
                          value="Car"
                          checked={user?.showPhoneRegistered}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div class="box hoverParent " onClick={toggleWarning}>
                    <p>Country *</p>
                    <HoverMenu />
                    <select
                      disabled
                      name="Country"
                      value={user?.country}
                      class="input greyImp"
                      required
                    >
                      {input?.country?.map((val) => (
                        <>
                          <option key={val} value={val}>
                            {val}
                          </option>
                          {val == "Yemen" && (
                            <option value="" disabled="disabled" key={"---"}>
                              ------------------------------------------------------------------------------
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                  <div class="box hoverParent" onClick={toggleWarning}>
                    <p>City *</p>
                    <HoverMenu />
                    <input
                      disabled
                      type="name"
                      name="check in"
                      class="input"
                      //   placeholder="Performance"
                      value={user?.city}
                      required
                    />
                  </div>
                </div>
                {/* PROFILE END */}

                <div class="row">
                  <div class="col-lg-2 col-md-4 col-sm-4">
                    <div
                      class="box photos"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        maxHeight: "100%",
                        // backgroundColor: "green",
                      }}
                    >
                      <p>Photo </p>
                      {/* {JSON.stringify(value?.profilePhoto)} */}
                      {/* {JSON.stringify(value)} */}
                      <div
                        style={{
                          // backgroundColor: "green",
                          // height: "100%",
                          // width: "100%",
                          marginTop: "1rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {value?.file || value?.profilePhoto?.path ? (
                          <div
                            style={{
                              width: "100%",

                              height: "27.5rem",
                              margin: "auto",
                              marginBottom: ".4rem",
                              backgroundColor: "white",
                              // marginBlock: "1rem",
                              borderRadius: "4px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "2rem",
                              }}
                            >
                              <img
                                style={{
                                  width: "17rem",
                                  height: "17rem",
                                  objectFit: "cover",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // backgroundColor: "blue",
                                  borderRadius: "100%",
                                  padding: ".5rem",
                                }}
                                src={
                                  (value?.file &&
                                    URL.createObjectURL(value?.file)) ||
                                  getPathEnv(value?.profilePhoto?.path)
                                }
                              />
                              <div style={{ display: "flex", gap: "1rem" }}>
                                <span
                                  style={{
                                    backgroundColor: "red",
                                    color: "white",
                                    paddingInline: "1rem",
                                    paddingBlock: ".5rem",
                                    borderRadius: ".5rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setConfirmWindow("profilePhoto");
                                  }}
                                >
                                  Delete
                                </span>
                                {pfpSource ? (
                                  <span
                                    style={{
                                      backgroundColor: "#5555bb",
                                      color: "white",
                                      paddingInline: "1rem",
                                      paddingBlock: ".5rem",
                                      borderRadius: ".5rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const queryParams = new URLSearchParams(
                                        location.search
                                      );
                                      queryParams.set("crop", "start");
                                      navigate(`?${queryParams.toString()}`);
                                      SetCropView(true);
                                    }}
                                  >
                                    Crop
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>

                            {confirmWindow == "profilePhoto" ? (
                              <ConfirmationModal
                                message={"delete the profile photo"}
                                onConfirm={() => {
                                  setValue((prev) => ({
                                    ...prev,
                                    file: null,
                                    profilePhoto: "delete",
                                  }));
                                }}
                                close={() => {
                                  setConfirmWindow(false);
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                // width: "100%",
                                // appearance: "none",
                                height: "27.5rem",
                                margin: "auto",
                                marginBottom: ".4rem",
                                overflow: "hidden",
                                borderRadius: "4px",
                              }}
                            >
                              <input
                                style={{
                                  // width: "100%",
                                  // appearance: "none",
                                  height: "100%",
                                  margin: "auto",
                                  marginBottom: ".4rem",
                                }}
                                id="myPhotos"
                                accept="image/*"
                                type="file"
                                onChange={(e) => {
                                  if (e.target.files[0].size > 5342880) {
                                    toast.warn(
                                      "Profile picture should be less than 5MB in size"
                                    );
                                    return;
                                  }

                                  setValue((prev) => ({
                                    ...prev,
                                    file: e.target.files[0],
                                  }));

                                  setPfpSource(e.target.files[0]);
                                }}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div class="subtitle">
                        <div class="bg-color">
                          <label>For business, can add logo</label>
                          <label>Max File size 5 MB</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-8 col-sm-8">
                    <div class="box ">
                      <p>Profile Summary *</p>
                      <textarea
                        style={{
                          height: "27.5rem",
                          marginBottom: ".4rem",
                          padding: "1rem",
                        }}
                        name="profileSummary"
                        defaultValue={value?.profileSummary}
                        // value={value?.profileSummary}
                        onChange={changeValue}
                        maxLength={800}
                        required
                      ></textarea>
                      <div class="subtitle">
                        <div class="bg-color2">
                          <label style={{ margin: "0px" }}>
                            800 Max Characters
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="row align-items-center">
                      <div class="col-md-7 col-sm-6">
                        <div class="box">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "5px",
                            }}
                          >
                            <p>Profile Header *</p>
                            <div class="subtitle">
                              <div
                                class="bg-color"
                                style={{ color: "#8e8e8e" }}
                              >
                                {/* <label>For business, can add company brief</label> */}
                                <label>Max length 60</label>
                              </div>
                            </div>
                          </div>
                          <textarea
                            type="name"
                            name="profileHeader"
                            style={{
                              height: "1rem",
                            }}
                            class="input"
                            required
                            defaultValue={value?.profileHeader}
                            onChange={changeValue}
                            maxLength={60}
                          />
                        </div>
                      </div>
                      <div class="col-md-5 col-sm-6   ">
                        <div class="box">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p>Company Name</p>
                            <div class="subtitle">
                              <div
                                class="bg-color"
                                style={{ color: "#8e8e8e" }}
                              >
                                {/* <label>For business, can add company brief</label> */}
                                <label> Max length 60</label>
                              </div>
                            </div>
                          </div>
                          <input
                            type="name"
                            name="companyName"
                            class="input"
                            defaultValue={value?.companyName}
                            onChange={changeValue}
                            maxLength={60}
                          />
                        </div>
                      </div>
                      <div class="col-12"></div>

                      <div class="col-12">
                        <div class="box">
                          <p>LinkedIn Profile Link </p>
                          <input
                            type="name"
                            name="linkedinLink"
                            class="input"
                            onChange={changeValue}
                            ref={linkedInRef}
                            placeholder="https://www.linkedin.com/"
                            // onClick={() => {
                            //   if (!value?.linkedinLink) {
                            //     setValue((prev) => ({
                            //       ...prev,
                            //       linkedinLink: "https://www.linkedin.com/",
                            //     }));
                            //   }
                            // }}
                            defaultValue={value?.linkedinLink}
                          />
                        </div>
                      </div>
                      <div class="col-md-7 col-sm-6">
                        <div class="box">
                          <p>Personal / Business Page Link</p>
                          <input
                            type="name"
                            name="personalLink"
                            class="input"
                            // required
                            defaultValue={
                              value?.personalLink?.length
                                ? value?.personalLink
                                : ""
                            }
                            placeholder="https://"
                            onChange={changeValue}
                          />
                        </div>
                      </div>
                      <div class="col-md-5 col-sm-6   ">
                        <div class="box">
                          <p>Legal Business Name</p>
                          <input
                            type="name"
                            name="legalName"
                            class="input"
                            // required
                            defaultValue={value?.legalName}
                            onChange={changeValue}
                          />
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-9 col-sm-8 col-8">
                        <div class="box photos2">
                          <p>Upload Resume *</p>

                          {value?.resume?.fileName || value?.resume?.name ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <label
                                class="input"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor: "white",
                                }}
                              >
                                {/* {JSON.stringify(value?.resume?.name)} */}
                                <span>
                                  {value?.resume?.name ||
                                    value?.resume?.fileName}
                                </span>
                                <label>
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setConfirmWindow("resume");
                                      // setValue((prev) => ({
                                      //   ...prev,
                                      //   resume: "delete",
                                      // }))
                                    }}
                                  >
                                    change
                                  </p>
                                </label>
                              </label>
                              {confirmWindow == "resume" && (
                                <ConfirmationModal
                                  message={"delete the resume"}
                                  onConfirm={() => {
                                    setValue((prev) => ({
                                      ...prev,
                                      resume: "delete",
                                    }));
                                  }}
                                  close={() => {
                                    setConfirmWindow("");
                                  }}
                                />
                              )}
                            </div>
                          ) : (
                            <input
                              id="myPhotos"
                              type="file"
                              style={{ cursor: "pointer" }}
                              placeholder={
                                value?.resume && value?.resume?.fileName
                              }
                              accept="application/pdf"
                              onChange={(e) => {
                                if (e.target.files[0].size > 5242880) {
                                  toast.warn(
                                    "Resume should be less than 5MB in size"
                                  );
                                  return;
                                }
                                setValue((prev) => ({
                                  ...prev,
                                  resume: e.target.files[0],
                                }));
                              }}
                            />
                          )}
                          <div class="subtitle">
                            <div class="bg-color">
                              <label>For business, can add company brief</label>
                              <label>PDF & Max File 5 MB</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-3 col-sm-4 col-4">
                        <div class="box">
                          <button
                            type="button"
                            style={{ margin: "0px", marginTop: "0.4rem" }}
                            onClick={ViewResume}
                          >
                            <a>Review Resume</a>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12" style={{ marginTop: "10px" }}>
                    {/* <KeyskillsSelect
                      setSelectedSkills={setSelectedSkills}
                      selectedSkills={selectedSkills}
                    /> */}

                    <SkillsSelect
                      set={setSelectedSkills}
                      initialData={value?.skills}
                      horizontal
                      label={"Skills *"}
                      info={
                        "Click Add Skill button after skill selection (Max. 10 skills)"
                      }
                      propose={true}
                      fixOverflow={true}
                      letters={true}
                    />
                  </div>

                  {fields.map((field) => (
                    <ExperienceSchemaSelect
                      key={field?.title}
                      title={field?.title}
                      input={field?.input}
                      name={field?.name}
                      field={field}
                      setValue={setValue}
                      value={value?.experience}
                    />
                  ))}

                  <div class="box col-lg-2 col-md-4 col-sm-6">
                    <p>Start Date *</p>

                    <DateSelectorFreelancer
                      current={
                        value?.experience?.startDate &&
                        value?.experience?.startDate
                      }
                      setValueDate={(value) =>
                        setValue((prev) => ({
                          ...prev,
                          experience: { ...prev.experience, startDate: value },
                        }))
                      }
                    />
                  </div>

                  <div class="row">
                    <div class="col-lg-4">
                      <div class="box-content">
                        <h6>I am a professional:</h6>
                        <div>
                          <input
                            type="checkbox"
                            id="Trainer"
                            name="Trainer"
                            checked={value?.Iam?.includes("Trainer")}
                            onChange={IamCheckBox}
                          />
                          <label htmlFor="Trainer" class="btn-color1">
                            Trainer
                          </label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="consultant"
                            name="Consultant"
                            onChange={IamCheckBox}
                            checked={value?.Iam?.includes("Consultant")}
                          />
                          <label htmlFor="consultant" class="btn-color2">
                            Consultant
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-8">
                      <div class="box-content">
                        <div>
                          <input
                            type="checkbox"
                            id="emailVac"
                            name="vehicle1"
                            checked={
                              value?.emailVacancies == true ||
                              value?.emailVacancies == false
                                ? value?.emailVacancies
                                : defaultValues?.emailVacancies
                            }
                            onChange={(e) =>
                              setValue((prev) => ({
                                ...prev,
                                emailVacancies: e.target.checked,
                              }))
                            }
                          />
                          <label htmlFor="emailVac">
                            I would like to receive vacancy alert by email that
                            match my selected skills above
                          </label>
                        </div>
                        {/* <div>
                          <input
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Car"
                          />
                          <label>
                            I agree to pay voluntarily 1% of my collected
                            revenues *
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    class="heder-content"
                    style={{ justifyContent: "end", marginBottom: "0px" }}
                  >
                    <div
                      class="a-btns"
                      style={{
                        display: "flex",
                        marginTop: "3rem",
                        alignItems: "flex-start",
                      }}
                    >
                      <Preview button="Preview profile">
                        <FreelancerBox2
                          freelancer={{
                            ...freelancer,
                            ...value,
                            file: value?.file && value?.file,
                            resume: value?.resume,
                          }}
                          preview
                        />
                      </Preview>
                      <button
                        style={{
                          backgroundColor: "#00000000",
                          border: "0px solid black",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        <a>Save Profile</a>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {CropView && (
          <ImageCropper
            src={pfpSource}
            cancel={() => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.delete("crop");
              navigate(`?${queryParams.toString()}`);
              SetCropView(false);
            }}
            onSuccess={(croppedImg) => {
              const queryParams = new URLSearchParams(location.search);
              queryParams.delete("crop");
              navigate(`?${queryParams.toString()}`);
              setValue((prev) => ({ ...prev, file: croppedImg }));
            }}
            title={"Profile picture"}
          />
        )}
      </>
    );
};

function ExperienceSchemaSelect({ setValue, value, field }) {
  const title = field?.title;
  const input = field?.input;
  const name = field?.name;
  const notReq = field?.notrequired;

  function changeVal(e) {
    setValue((prev) => ({
      ...prev,
      experience: { ...prev.experience, [name]: e.target.value },
    }));
  }

  function AllSelecOptions() {
    return (
      <>
        {input?.map((val) => (
          <>
            <option key={val} selected={val == value?.[name]} value={val}>
              {capsFirstLetter(val)}
            </option>
            {val == "Yemen" && (
              <option value="" disabled="disabled" key={"---"}>
                ------------------------------------------------------------------------------
              </option>
            )}
          </>
        ))}
      </>
    );
  }

  let selectStyle = {};
  if (field?.warningState && field?.warningState == value?.[name]) {
    selectStyle = { ...selectStyle, border: "1.5px solid red" };
  }
  if (field?.safeState && field?.safeState == value?.[name]) {
    selectStyle = { ...selectStyle, border: "1.9px solid #00bd0f" };
  }

  return (
    <>
      <div class="box col-lg-2 col-md-4 col-sm-6">
        <p>
          {title}
          {notReq ? <></> : <>*</>}
        </p>
        <select
          name="Country"
          class="input"
          onChange={changeVal}
          required
          style={selectStyle}
        >
          {!!value?.[name] ? (
            <AllSelecOptions />
          ) : (
            <>
              <option selected hidden disabled>
                Select {title}
              </option>
              <AllSelecOptions />
            </>
          )}
        </select>
      </div>
    </>
  );
}

function HoverMenu() {
  return (
    <>
      <input
        type="name"
        name="check in"
        className="input show"
        value="To Change go to My Account"
        disabled
        required
      />
    </>
  );
}

export default FreelancerProfileMain;
