import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fieldsData } from "../utils/fields";
import RecaptchaComponent from "../Components/Recaptcha/Recaptcha";
import { useMutation } from "react-query";
import axiosInstance from "../axios/axios";

const Contact = (props) => {
  const [isNotRobo, setIsNotRobo] = useState(false);
  const [data, setData] = useState({});

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {}, [data]);
  const contactusMutation = useMutation(ContactUs, {
    onSuccess: () => {
      for (var i = 0; i < document.forms.length; i++) {
        document.forms[i].reset();
      }
      setData((prev) => {
        return {};
      });
    },
  });

  async function ContactUs() {
    await axiosInstance.post("/auth/contact-us", {
      ...data,
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isNotRobo) return toast.warning("Please select I'm not a robot");
    const fields = fieldsData.CONTACT_US;
    for (var i = 0; i < fields.length; i++) {
      if (data[fields[i].name]) {
        switch (fields[i].type) {
          case "select":
            break;
          case "textarea":
          case "text": {
            if (data[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                <span>
                  <b>
                    {fields[i]?.name?.charAt(0).toUpperCase() +
                      fields[i]?.name?.slice(1)}
                  </b>{" "}
                  should have minimum {fields[i].min} characters
                </span>
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field = fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }

    toast.promise(contactusMutation.mutateAsync(), {
      pending: "Please wait...",
      success: "Thank you for Contacting us",
      error: "Contact us failed",
    });
  };

  return (
    <>
      <section className="sign-log">
        <div className="container">
          <div className="box-center">
            <h6 className="headding">
              Email us at{" "}
              <a href="mailto:info@menafreelancer.com">
                info@menafreelancer.com
              </a>{" "}
              or use the form below
            </h6>
            <div class="box-bg">
              <h3>Contact Us</h3>
              <form onSubmit={handleSubmit}>
                <div class="login-list-flex">
                  <div class="box">
                    <p>Name *</p>
                    <input
                      type="name"
                      name="name"
                      onChange={handleChange}
                      onInput={handleChange}
                      class="input"
                      required
                    />
                  </div>
                  <div class="box">
                    <p>Email *</p>
                    <input
                      type="email"
                      name="email"
                      onInput={handleChange}
                      onChange={handleChange}
                      class="input"
                      required
                    />
                  </div>
                  <div class="box">
                    <p>Subject *</p>
                    <input
                      type="text"
                      name="subject"
                      onChange={handleChange}
                      onInput={handleChange}
                      class="input"
                      required
                    />
                  </div>
                  <div class="box">
                    <p>Inquiry *</p>
                    <textarea
                      name="inquiry"
                      className="input"
                      cols="30"
                      rows="10"
                      onInput={handleChange}
                      onChange={handleChange}
                      maxLength={800}
                    ></textarea>
                  </div>
                  <div class="subtitle">
                    <div class="bg-color2">
                      <label style={{ margin: "0px", color: "#8e8e8e" }}>
                        800 Max Characters
                      </label>
                    </div>
                  </div>
                </div>
                <div className="login-btn-flex">
                  <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
