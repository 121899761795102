import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";
import { useNavigate } from "react-router-dom";
export function fetchProfile() {
  const isLoggedIn = !!localStorage.getItem("Auth");
  const navigate = useNavigate();

  const fetchData = async () => {
    if (localStorage.getItem("Auth")) {
      const res = await axiosInstance
        .get("/user/myprofile")
        .then((res) => res.data.data)
        .catch((err) => {
          localStorage.clear();
        });

      if (!res.data) {
        localStorage.clear();
        navigate("/login");
      }

      return res;
    } else {
      // navigate("/login");
      return null;
    }
  };

  const { isLoading, error, data } = useQuery({
    queryKey: ["profile", { Auth: localStorage.getItem("Auth") }],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // enabled: !!isLoggedIn,
  });

  return { isLoading, error, data };
}
