import { useRef, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios/axios";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { fullName, getPathEnv } from "../../helper";
import { queryClient } from "../../routes/Reactquery";
import { ProfileMenuModal } from "./Header/ProfileModal";
import logo from "../../css/images/logo.png";

import defaultUserPhoto from "../../css/images/freelance-list/profile_1.png";
const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <header className="header">
        <div className="container">
          <div className="grid">
            <div className="logo">
              <a onClick={() => navigate("/")}>
                <img
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "70px",
                    objectFit: "cover",
                    objectPosition: "50% 50%",
                    imageRendering: "crisp-edges",
                  }}
                  src={"/images/logo.png"}
                  alt="logo"
                />
              </a>
            </div>

            <Profile />
          </div>
        </div>
      </header>
    </>
  );
};

function Profile() {
  const [menuOpen, setMenuOpen] = useState(false);

  const { isLoading, error, data } = fetchProfile();

  const ref = useRef();

  const user = data?.data?.user;
  const pfp = data?.data?.profilePhoto;

  const profilePhoto = pfp ? getPathEnv(pfp?.path) : defaultUserPhoto;
  return (
    <>
      <div className="profile" style={{ height: "7rem" }} ref={ref}>
        {user && (
          <>
            <div style={{ position: "reletive", marginRight: "4vw" }}>
              {menuOpen && (
                <ProfileMenuModal setMenuOpen={setMenuOpen} refComp={ref} />
              )}
            </div>
            <div
              onClick={() => setMenuOpen((a) => !a)}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p style={{ textAlign: "right" }}>
                {fullName(user?.firstName, user?.lastName)}
              </p>
              <img
                style={{
                  width: "5rem",
                  height: "5rem",
                  borderRadius: "100%",
                  objectFit: "cover",
                }}
                src={profilePhoto}
                alt="profile"
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Header;
