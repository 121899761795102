import { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import { useSwiper } from "swiper/react";
import { fetchCMS } from "../../../fetch/cms/main";

const SwiperComponent = ({ video, page }) => {
  const { isLoading, error, data } = fetchCMS();

  const sliderData = data?.works?.slider2;

  const caption = sliderData?.[page || "IndexPage"];

  const swiper = useSwiper();

  const videoRef = useRef(null);
  const handlePlay = () => {
    // Disable full-screen mode when the video starts playing
    if (videoRef.current) {
      videoRef.current.webkitEnterFullscreen = null;
    }
  };

  if (isLoading)
    return (
      <>
        <div className="video-container">
          <Swiper
            className="video-container"
            spaceBetween={50}
            slidesPerView={1}
            navigation
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: "5000" }}
            modules={[Autoplay, Navigation]}
            speed={"1500"}
            loop
          >
            <SwiperSlide key={"slide"}>
              <div className="slideDiv" style={{ backgroundColor: "#04225e" }}>
                <p className="slidePara">Please Wait the page is Loading</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </>
    );
  else if (data)
    return (
      <>
        <div className="video-container">
          <Swiper
            className="video-container"
            spaceBetween={50}
            slidesPerView={1}
            navigation
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: "5000" }}
            modules={[Autoplay, Navigation]}
            speed={"1500"}
            loop
          >
            <video
              ref={videoRef}
              src={video || "video/production52_1.mp4"}
              autoPlay
              muted
              loop
              controls={false}
              onPlay={handlePlay}
            ></video>
            {caption?.map((slide) => (
              <SwiperSlide key={"slide"}>
                <div className="slideDiv">
                  <p className="slidePara">{slide}</p>
                </div>
              </SwiperSlide>
            ))}{" "}
          </Swiper>
        </div>
      </>
    );
};

export default SwiperComponent;
