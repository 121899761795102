import { useEffect, useRef, useState } from "react";
import { PreviewBox } from "./Previewbox";
import { useOnClickOutside } from "../Hook/clickOutSide";
import { FreelancerBox2 } from "./FreelancerBox2";

export const Preview = ({ button, children }) => {
  const [visible, setVisible] = useState(false);
  const previewRef = useRef(null);
  useOnClickOutside(previewRef, () => setVisible(false));

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        setVisible(false);
      }
    }
    if (visible) window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [visible]);
  if (children)
    return (
      <>
        <div
          type="div"
          className="btn2"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          onClick={() => setVisible(true)}
        >
          <a style={{ cursor: "pointer" }}>{button}</a>
        </div>
        {visible && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              maxWidth: "100vw",
              height: "100vh",
              maxHeight: "100vh",
              zIndex: "9998",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                position: "fixed",
                width: "100%",
                // backgroundColor: "blue",
                display: "flex",
                flexDirection: "column",
                overflow: "scroll",
                cursor: "default",
              }}
            >
              <div ref={previewRef}>
                {/* <PreviewBox freelancer={freelancer} /> */}
                {children}
              </div>
            </div>
            <button
              // href="#"
              type="button"
              style={{
                position: "fixed",
                bottom: "2rem",
                color: "#fff",
                // display: "inline-block",
                // position: "relative",
                backgroundColor: "#345fb4",
                padding: "1rem",
                width: "17rem",
                border: "1px solid #345fb4",
                borderradius: "8px",
                zindex: 1,
                overflow: "hidden",
                // transition: "0.5s",
                borderRadius: "8px",
                fontSize: "12px",

                // width: "80%",
                // height: "80%",
                // backgroundColor: "blue",
                // display: "flex",
                // flexDirection: "column",
                // overflow: "scroll",
              }}
              onClick={() => setVisible(false)}
            >
              close
            </button>
          </div>
        )}
      </>
    );
};
