import React, { useEffect, useRef } from "react";

const ConfirmationModal = ({ message, onConfirm, close, hint }) => {
  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Escape" || event.keyCode === 27) {
        close();
      }
    }
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#e8e8e810",
        backgroundSize: "cover",
        backdropFilter: "blur(8px)",
        WebkitBackdropFilter: "blur(8px)",
        left: "0px",
        top: "0px",
        height: "100vh",
        width: "100vw",
        zIndex: 19999,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "fixed",
          backgroundColor: "#fff",
          padding: "5rem",
          borderRadius: "10px",
        }}
      >
        <p style={{ color: "#555" }}>Are you sure you want to {message}?</p>
        <div style={{ display: "flex", gap: "2rem" }}>
          <button
            style={{ backgroundColor: "#f44", color: "black" }}
            type="button"
            onClick={() => {
              onConfirm();
              close();
            }}
          >
            Yes
          </button>
          <button onClick={close} type="button">
            No
          </button>
        </div>
        {hint && <p style={{ color: "#555", marginTop: "1rem" }}>{hint}</p>}
      </div>
    </div>
  );
};

export default ConfirmationModal;
