import React, { useRef, useState } from "react";
import { fetchInput } from "../../fetch/input/main";
import { KeyskillsSelect } from "../FreelancerProfile/KeySkills";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useOnClickOutside } from "../Hook/clickOutSide";
import SkillsSelect from "./SkillsSelect";
import { Puff } from "../Layout/Loading";

export const FreelancerSearch = ({ setFilters }) => {
  const { data: input, error, isLoading } = fetchInput();

  const [data, setData] = useState({});
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem("Auth");

  useEffect(() => {
    setData((prev) => ({ ...prev, skills: selectedSkills }));
  }, [selectedSkills]);

  function skillVals() {
    return data?.skills?.map((cat) => {
      const catName = cat?.category;
      const skill = cat?.skills;
      return `${skill}`;
    });
  }

  function selectChange(e) {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  function Search(e) {
    e.preventDefault();

    console.log(data);
    const nonSkillFilters = data
      ? Object.keys(data).filter((a) => a != "skills")
      : [];

    if (!data || (!data?.skills?.length && !nonSkillFilters?.length)) {
      toast.warning("Please enter your search inputs");
      return setFilters();
    }

    setFilters({
      skills: selectedSkills,
      ...data,
    });
  }
  function Reset() {
    setData();
    setFilters();
    setSelectedSkills();
  }

  if (isLoading)
    return (
      <div>
        <section className="f-list-1">
          <div className="container">
            <div className="bg-col">
              <h6>Search For Freelancers</h6>
              <form onSubmit={Search}>
                <div
                  className="list-flex"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "10rem",
                    alignItems: "center",
                  }}
                >
                  <Puff />
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );

  if (input)
    return (
      <div>
        <section className="f-list-1">
          <div className="container">
            <div className="bg-col">
              {/* {JSON.stringify(data)} */}
              <h6>Search For Freelancers</h6>
              <form onSubmit={Search}>
                <div className="list-flex">
                  {/* <div className="box">
                  <p>Key Word (s) *</p>

                  <input
                    type="name"
                    name="check in"
                    className="input"
                    placeholder=" Enter keywords"
                    readOnly
                    value={"hi"}
                  />
                </div> */}
                  <SkillsSelect
                    set={setSelectedSkills}
                    initialData={selectedSkills}
                    emptyInitial
                    label={"Key Word (s) *"}
                    searchParam
                  />

                  <div className="box">
                    <p>Freelancer Name</p>
                    <input
                      type="name"
                      name="title"
                      className="input"
                      placeholder="Enter freelancer name"
                      value={data?.title?.length ? data.title : ""}
                      onChange={selectChange}
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                  <div className="box">
                    <p>Country</p>
                    <select
                      name="country"
                      className="input"
                      onChange={selectChange}
                      value={data?.country ? data?.country : "none"}
                      style={{ backgroundColor: "white" }}
                    >
                      <SelectAnOption name={"country"} />

                      {input?.country?.map((country) => (
                        <>
                          <option value={country} key={country}>
                            {country}
                          </option>
                          {country == "Yemen" && (
                            <option value="" disabled="disabled">
                              ------------------------------------------------------------------------------
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>

                  <div className="box">
                    <p>City</p>
                    <input
                      type="name"
                      name="city"
                      className="input"
                      placeholder="Enter city"
                      value={data?.city?.length ? data.city : ""}
                      onChange={selectChange}
                      style={{ backgroundColor: "white" }}
                    />
                  </div>
                  <div className="box">
                    <p>Language</p>
                    <select
                      name="language"
                      className="input"
                      onChange={selectChange}
                      style={{ backgroundColor: "white" }}
                      value={data?.language ? data?.language : "none"}
                    >
                      <SelectAnOption name={"language"} />

                      {input?.language?.map((lang) => (
                        <option value={lang} key={lang}>
                          {lang}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="box">
                    <p>Nationality</p>
                    <select
                      name="nationality"
                      className="input"
                      style={{ backgroundColor: "white" }}
                      onChange={selectChange}
                      value={data?.nationality ? data?.nationality : "none"}
                    >
                      <SelectAnOption name={"nationality"} />
                      {input?.country?.map((country) => (
                        <>
                          <option value={country} key={country}>
                            {country}
                          </option>
                          {country == "Yemen" && (
                            <option value="" disabled="disabled">
                              ------------------------------------------------------------------------------
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                  </div>
                  <div className="box">
                    <p>Sort By *</p>
                    <select
                      className="input"
                      name="sort"
                      style={{ backgroundColor: "white" }}
                      onChange={selectChange}
                      value={data?.sort ? data?.sort : "none"}
                    >
                      {/* <SelectAnOption name={"sort"} /> */}
                      <option value={""}>Default sort</option>

                      <option value={"recent"}>Most Recent</option>
                      <option value={"name"}>Freelancer Name</option>
                      <option value="years">Years of Experience</option>
                      <option value="rate">Hourly Rate</option>
                      <option value="availability">Availability</option>
                      {/* <option value="residency">Residency Visa</option> */}
                      <option value="E-Consultant">e-Consultants</option>
                      <option value="Consultant">Consultants</option>
                      <option value="Trainer">Trainers</option>
                    </select>
                  </div>
                </div>
                <div className="buttons">
                  <div className="left-btn">
                    <button onClick={Search}>
                      <a className="abtn1">Search</a>
                    </button>
                    <button type="button" onClick={Reset}>
                      <a className="abtn2">Reset</a>
                    </button>
                  </div>
                  <p style={{ fontSize: "1.2rem", color: "#8e8e8e" }}>
                    For more results keep search broader
                  </p>
                  {!isLoggedIn && (
                    <div className="right-btn">
                      <a
                        className="abtn3"
                        href="#"
                        onClick={() => navigate("/signup")}
                      >
                        New Freelancer Sign Up
                      </a>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    );
};
function SelectAnOption({ name }) {
  return (
    <>
      <option selected value={""}>
        Any {name}
      </option>
      {/* {name == "sort" ? (
        <option value={""}>Default</option>
      ) : (
        <option value={""}>{"Any"}</option>
      )} */}
    </>
  );
}

function SkillsSelectModal({ close, setSelectedSkills, selectedSkills }) {
  const previewRef = useRef(null);
  useOnClickOutside(previewRef, close);
  return (
    <div
      style={{
        position: "fixed",

        top: 0,
        height: "100vh",
        width: "100vw",
        backgroundColor: "#aaa",
        zIndex: "1001",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "fixed" }}>
        <div style={{ width: "70rem" }}>
          <section className="f-list-1">
            <div className="container" ref={previewRef}>
              <div className="bg-col">
                <form action="" method="post">
                  <div className="list-flex">
                    <div className="box">
                      <KeyskillsSelect
                        setSelectedSkills={setSelectedSkills}
                        selectedSkills={selectedSkills}
                      />
                    </div>
                  </div>
                  <div className="buttons">
                    <div
                      className="left-btn"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <a className="abtn1" href="#" onClick={close}>
                        set
                      </a>
                      {/* <a className="abtn2" href="#">
                        Reset
                      </a> */}
                    </div>
                    {/* <div className="right-btn">
                      <a class="abtn3" href="#">
                        New Freelancer Sign Up
                      </a>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
