import React, { useEffect, useState, useRef } from "react";

export function DateSelectorFreelancer({ setValueDate, current }) {
  const [selectedOption, setSelectedOption] = useState("Anytime");
  const [date, setDate] = useState("Anytime");
  const first = useRef(null);
  const newD = JSON.stringify(new Date());
  const today = newD.slice(1, 11);

  function handleOptionChange(event) {
    setValueDate(event.target.value);
    // setDate(event.target.value);
  }

  if (!current) {
    console.log("FIRING value date");
    setValueDate("Anytime");
  }
  useEffect(() => {}, []);

  return (
    <div
      class="input"
      style={{
        backgroundColor: "#EFEFEF",
        paddingTop: current != "Anytime" ? "0px" : "",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "2rem",
          margin: current == "Anytime" ? "0px" : "",
          padding: current == "Anytime" ? "0px" : "",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", gap: ".5rem" }}>
          <input
            type="radio"
            value="Anytime"
            id="Anytime"
            style={{
              padding: current == "Anytime" ? "0px" : "",
              // justifyContent: "space-evenly",
            }}
            checked={!current || current === "Anytime"}
            onChange={handleOptionChange}
          />
          <label htmlFor="Anytime">Any Time</label>
        </div>
        <div style={{ display: "flex", gap: ".5rem" }}>
          <input
            type="radio"
            value={today}
            id="date"
            checked={current && current !== "Anytime"}
            onChange={handleOptionChange}
          />
          <label htmlFor="date">Date</label>
        </div>
      </div>

      {current !== "Anytime" && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="date"
            ref={first}
            id="date-input"
            value={current}
            style={{ fontSize: "1.3rem" }}
            // onSelect={(e) => setDate(e.target.value)}
            onChange={(e) => setValueDate(e.target.value)}
          />
        </div>
      )}
    </div>
  );
}
