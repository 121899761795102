import React, { useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";

async function base64ToBlob(base64String) {
  const mimeType = base64String.match(
    /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,/
  )[1];
  const response = await fetch(base64String);
  const blob = await response.blob();
  return new Blob([blob], { type: mimeType });
}

const ImageCropper = ({ src, cancel, onSuccess, title }) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [image, setImage] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(1);
  const [preview, setPreview] = useState(null);

  const handleImageChange = (src) => {
    const reader = new FileReader();

    reader.onload = () => {
      setImage(reader.result);
    };

    if (src) {
      reader.readAsDataURL(src);
    }
  };
  useEffect(() => {
    handleImageChange(src);
    console.log(src);
  }, [src]);

  const handleScaleChange = (e) => {
    const scaleValue = parseFloat(e.target.value);
    setScale(scaleValue);
  };

  const handleCrop = () => {
    if (editor) {
      const canvas = editor.getImageScaledToCanvas().toDataURL();
      setPreview(canvas);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "#efefef",
        height: "100vh",
        overflow: "scroll",
        width: "100vw",
        zIndex: 1001,
        top: 0,
      }}
    >
      <div
        style={{
          display: "flex",

          alignItems: "center",
          justifyContent: "center",
          marginTop: "6rem",
          maxHeight: "100%",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            display: "flex",
            fontSize: "2rem",
            gap: "1rem",
            color: "red",
            cursor: "pointer",
            justifyContent: "center",
            marginTop: "5rem",
            alignItems: "center",
            marginBlock: "1rem",
          }}
          onClick={cancel}
        >
          Cancel and go back
        </p>
        {image && !preview ? (
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                marginBlock: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label htmlFor="scale" style={{ fontSize: "1.5rem" }}>
                  Scale &nbsp;
                </label>
                <input
                  id="scale"
                  type="range"
                  min="1"
                  max="2"
                  step="0.01"
                  value={scale}
                  onChange={handleScaleChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    backgroundColor: "#292a2d",
                    color: "white",
                    fontSize: "1.5rem",
                    paddingInline: "1rem",
                    paddingBlock: ".3rem",
                    borderRadius: ".4rem",
                  }}
                  onClick={handleCrop}
                >
                  Crop
                </button>
                <button
                  style={{
                    backgroundColor: "#e46962",
                    color: "white",
                    fontSize: "1.5rem",
                    paddingInline: "1rem",
                    paddingBlock: ".3rem",
                    borderRadius: ".4rem",
                  }}
                  onClick={cancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <AvatarEditor
              ref={(editorRef) => setEditor(editorRef)}
              image={image}
              width={screenWidth < 500 ? screenWidth - 50 : 500}
              height={screenWidth < 500 ? screenWidth - 50 : 500}
              border={100}
              color={[255, 255, 255, 0.6]}
              scale={scale}
              rotate={0}
            />
          </div>
        ) : (
          <></>
        )}
        {preview && (
          <div
            style={{
              marginBlock: "2rem",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
              backgroundColor: "#fff",
              marginTop: "5rem",
              padding: "5rem",
              borderRadius: "2rem",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Apply the drop shadow here
            }}
          >
            <h2 style={{ textAlign: "center" }}>Cropped preview</h2>
            <img
              src={preview}
              alt="Cropped Image"
              style={{
                height: screenWidth < 500 ? screenWidth - 150 : 500,
                width: screenWidth < 500 ? screenWidth - 150 : 500,
                borderRadius: "100%",
                border: "1px solid black",
              }}
            />
            <div
              style={{ display: "flex", justifyContent: "center", gap: "2rem" }}
            >
              <button
                style={{
                  backgroundColor: "#345fb4",
                  color: "white",
                  fontSize: "1.5rem",
                  paddingInline: "1rem",
                  paddingBlock: ".3rem",
                  borderRadius: ".4rem",
                }}
                onClick={async () => {
                  const blob = await base64ToBlob(preview);
                  onSuccess(blob);
                  cancel();
                }}
              >
                Done
              </button>
              <button
                style={{
                  backgroundColor: "grey",
                  color: "white",
                  fontSize: "1.5rem",
                  paddingInline: "1rem",
                  paddingBlock: ".3rem",
                  borderRadius: ".4rem",
                }}
                onClick={async () => {
                  setPreview();
                }}
              >
                Undo
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageCropper;
