import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";
import { toast } from "react-toastify";

export function fetchFreelancers({ page }) {
  const isLoggedIn = !!localStorage.getItem("Auth");

  console.log("LAST");
  console.log(page);
  const fetchData = async () =>
    await axiosInstance
      .get(
        isLoggedIn
          ? `/freelancer/page?page=${page}`
          : `/unauth/freelancers/page?page=${page}`
      )
      .then((res) => res.data.data);

  const { isLoading, error, data } = useQuery({
    queryKey: [`freelancers`, page, { auth: localStorage.getItem("Auth") }],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { isLoading, error, data };
}

export function searchFreelancers(filter) {
  const skills = filter?.skills;
  const country = filter?.country;
  const city = filter?.city;
  const title = filter?.title;
  const language = filter?.language;
  const nationality = filter?.nationality;
  const sort = filter?.sort;
  const page = filter?.page ? filter?.page : 0;

  const skillsKey = skills?.map((cat) => `${cat}`)?.join();
  const isLoggedIn = !!localStorage.getItem("Auth");

  // const skillsKey = skills?.map((sk) => sk);
  console.log(skillsKey);
  function getQuery() {
    let string = "";
    if (skills?.length) string += `skills=${skillsKey}&`;
    if (country) string += `country=${country}&`;
    if (city) string += `city=${city}&`;
    if (title) string += `title=${title}&`;
    if (language) string += `language=${language}&`;
    if (nationality) string += `nationality=${nationality}&`;
    if (sort) string += `sort=${sort}&`;
    string += `page=${page}&`;
    return string;
  }
  const query = getQuery();
  console.log(query);
  const fetchData = async () =>
    await axiosInstance
      .get(
        isLoggedIn
          ? `/freelancer/search?${query}`
          : `/unauth/freelancers?${query}`
      )
      .then((res) => res.data.data);

  const { isLoading, error, data } = useQuery({
    queryKey: [`freelancers`, query, { auth: localStorage.getItem("Auth") }],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  });

  return { isLoading, error, data };
}

export function fetchFreelancer(freelancerId) {
  const isLoggedIn = !!localStorage.getItem("Auth");
  const url = isLoggedIn
    ? `/freelancer/${freelancerId}`
    : `/unauth/freelancer/${freelancerId}`;

  const fetchData = async () =>
    await axiosInstance.get(url).then((res) => res?.data?.data?.data);

  const { isLoading, error, data } = useQuery({
    queryKey: [`freelancer`, freelancerId, isLoggedIn],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    // enabled: !!freelancerId,
  });

  return { isLoading, error, data };
}

export function fetchFreelancerForEconsultancy(freelancerId, enabled) {
  const url = `/freelancer/econsultancy/${freelancerId}`;
  const fetchData = async () =>
    await axiosInstance.get(url).then((res) => {
      return res?.data?.data?.data;
    });

  const { isLoading, error, data } = useQuery({
    queryKey: [`freelancerforeconsultancy`, freelancerId],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!freelancerId && enabled,
  });

  return { isLoading, error, data };
}
