const validation = {
  login: {
    username: {
      ISEMPTY: "This field cant be empty",
      ISVALID: "Invalid username",
    },
  },
  ISEMPTY: "This field can not be empty",
};

const appendToFormData = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((element) => {
    if (typeof data[element] === "object") {
      formData.append(element, JSON.stringify(data[element]));
    } else {
      formData.append(element, data[element]);
    }
  });
  return formData;
};

const REQUIRED_FIELDS = {
  LOGIN: ["email", "password"],
};

const types = {};
export { validation, types, appendToFormData, REQUIRED_FIELDS };
