import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";

export function fetchEconsultancyBookings() {
  const fetchData = async () => {
    console.log("rerererere");
    return await axiosInstance
      .get(`/econsultancybooking/all/id`)
      .then((res) => res?.data?.data?.data);
  };

  const { isLoading, error, data } = useQuery({
    queryKey: [`econsultancy`],
    queryFn: fetchData,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  return { isLoading, error, data };
}

export function fetchEconsultancyBooking(econsultancyId) {
  const fetchData = async () =>
    await axiosInstance
      .get(`/econsultancybooking/${econsultancyId}`)
      .then((res) => res?.data?.data?.data);

  const { isLoading, error, data } = useQuery({
    queryKey: [`econsultancy`, econsultancyId],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { isLoading, error, data };
}
