//TODO: remove emails
import { useState, useEffect } from "react";
import { useMutation } from "react-query";
import { fieldsData } from "../utils/fields";
import axiosInstance from "../axios/axios";
import { sendRequest } from "../redux/actions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import RecaptchaComponent from "../Components/Recaptcha/Recaptcha";

const Invite = (props) => {
  const [fields, setFields] = useState(fieldsData.INVITE_FRIEND);
  const [isNotRobo, setIsNotRobo] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formVals = Object.fromEntries(formData);
    const mailsToSend = Object.keys(formVals).map(
      (key) => key?.includes("email") && formVals[key]
    );
    //remove false from array
    mailsToSend.pop();
    const parsedMails = mailsToSend[0].split(";");
    if (!isNotRobo) return toast.warning("Please select I'm not a robot");
    toast.promise(inviteMutation.mutateAsync(parsedMails), {
      pending: "Inviting friends...",
      success: "Successfully invited friends.",
      error: "Some error occured while inviting, Please try again.",
    });
  }

  const inviteMutation = useMutation(Invite, {
    onSuccess: () => {
      for (var i = 0; i < document.forms.length; i++) {
        document.forms[i].reset();
      }
      setFields(fieldsData.INVITE_FRIEND);
      setIsNotRobo(false);
    },
  });

  async function Invite(mails) {
    const body = { emails: [...mails] };
    await axiosInstance.post("/auth/invite-friend", body);
  }

  const updateMailField = (field) => {
    if (field) {
      let newFields = fields.filter((f) => f != field);
      setFields(newFields);
    } else {
      let newFields = [
        ...fields,
        {
          name: `email${fields.length + 1}`,
          type: "text",
          extra: null,
          label: "Email",
        },
      ];
      setFields(newFields);
    }
  };

  return (
    <>
      <div>
        <section class="sign-log">
          <div class="container">
            <div class="box-center">
              <div class="box-bg">
                <h3>Invite Friend</h3>

                <form action="" onSubmit={handleSubmit}>
                  <div class="login-list-flex">
                    <div class="box">
                      {fields?.map((field) => (
                        <>
                          <p>{field?.label}</p>
                          <input
                            type="text"
                            name={field?.name}
                            class="input"
                            required
                          />
                          {field?.extra ? (
                            <div class="subtitle">
                              <div class="bg-color2">
                                <label>
                                  Add multiple emails with ; in between
                                </label>
                              </div>
                            </div>
                          ) : (
                            <div class="subtitle">
                              <div class="bg-color2">
                                <label
                                  style={{ fontSize: "15px" }}
                                  onClick={(e) => updateMailField(field)}
                                >
                                  {" "}
                                  &#10006;
                                </label>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div class="btn-flex">
                    <div className="login-btn-flex">
                      <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                    </div>
                  </div>
                  <button type="submit">Send</button>
                  {/* <p className="title">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={(e) => updateMailField()}
                    >
                      <span>Add another &nbsp;</span>
                    </a>
                  </p> */}
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const mapToStateProps = (state) => ({
  ...state.inviteFriend,
});

export default connect(mapToStateProps, { sendRequest })(Invite);
