import { useQuery } from "react-query";
import axiosInstance from "../../axios/axios";
export function fetchFavFreelancers(page) {
  const isLoggedIn = !!localStorage.getItem("Auth");

  console.log("Fetching favorite freelancers");
  console.log("page", page);
  let pageNum = page ? page : 0;

  //   console.log(page);
  let url = `/freelancer/favorite`;
  url = url + `?page=${pageNum}`;
  // if (page) {
  // }
  console.log(url);
  const fetchData = async () =>
    isLoggedIn
      ? await axiosInstance.get(url).then((res) => res.data.data)
      : null;

  const { isLoading, error, data } = useQuery({
    queryKey: [
      `favfreelancers`,
      pageNum,
      { Auth: localStorage.getItem("Auth") },
    ],
    queryFn: fetchData,
    refetchOnWindowFocus: false,
  });

  return { isLoading, error, data };
}
