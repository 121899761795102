import React, { useEffect, useState } from "react";
import { fetchInput } from "../../fetch/input/main";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { KeyskillsSelect } from "../../Components/FreelancerProfile/KeySkills";
import { useMutation } from "react-query";
import axiosInstance from "../../axios/axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModalBuilder from "../../Components/ModalBuilder";
import VacancyBox from "./VacancyBox";
import { checkRegisteredUser, dateFormatter } from "../../utils/helper";
import { DefaultOption } from "../../utils/components";
import SkillsSelect from "../../Components/Freelancer/SkillsSelect";
import { fieldsData } from "../../utils/fields";
import { Preview } from "../../Components/Freelancer/Preview";
import ConfirmationModal from "../../Components/Layout/ConfirmationModal";
const VacancyFormCreate = () => {
  const [file, setFile] = useState("");
  const [isPreviewOn, setIsPreviewOn] = useState(false);
  const [confirmWindow, setConfirmWindow] = useState(false);

  const {
    isLoading: profileLoading,
    error: profileError,
    data: profileData,
  } = fetchProfile();

  const customFileStyle = {
    fontWeight: "700",
    cursor: "pointer",
    color: "#0e71b9",
    fontSize: "12px",
  };

  const navigate = useNavigate();
  useEffect(() => {
    !checkRegisteredUser() && navigate("/login");
  }, []);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [formData, setFormData] = useState({});

  const { isLoading, error, data } = fetchInput();

  useEffect(() => {}, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataProps = { ...defaultVals, ...formData };
    let parsedData = {};
    Object.keys(dataProps).map((x) => {
      if (!dataProps[x]?.toString()?.includes("Any")) {
        parsedData = { ...parsedData, [x]: dataProps[x] };
      }
    });
    if (!selectedSkills?.length) {
      return toast.warn(`Please select atleast one skill.`);
    }

    const finalData = {
      ...parsedData,
      requiredSkills: [...selectedSkills],
    };

    const fields = fieldsData.VACANCY_CREATE;
    for (var i = 0; i < fields.length; i++) {
      if (finalData[fields[i].name]) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (finalData[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                `${fields[i].name} should have minimum ${fields[i].min} characters`
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field =
          fields[i].name.split(".").length > 1
            ? fields[i].name.split(".")[1]
            : fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }

    if (formData?.publish == "false")
      toast.warn(
        "Your vacancy wont be visible to other users since you selected no in publish profile."
      );

    toast.promise(vacancyCreateMutation.mutateAsync(), {
      pending: "Vacancy is creating...",
      success: "Vacancy created successfully",
      error: "Vacancy create failed",
    });
  };

  const vacancyCreateMutation = useMutation(CreateVacancy, {
    onSuccess: () => {
      setTimeout(() => {
        navigate("/vacancies-posted");
      }, 2000);
    },
  });

  let defaultVals = {
    "time.startDate": new Date().toISOString().substr(0, 10),
    "contact.name": `${profileData?.data?.user?.firstName} ${profileData?.data?.user?.lastName}`,
    "contact.email": profileData?.data?.user?.email,
    "contact.phone": profileData?.data?.user?.phone,
    publish: "true",
  };

  async function CreateVacancy() {
    await axiosInstance.post(
      "/vacancy/create",
      {
        ...defaultVals,
        ...formData,
        requiredSkills: [...selectedSkills],
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }

  const handleChange = (e) => {
    setIsPreviewOn(true);
    if (e.target.value == "none") {
      setFormData({
        ...formData,
        [e.target.name]: `Any`,
      });
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]?.name);
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleCheckbox = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  const handlePreview = (e) => {
    e.preventDefault();
  };

  //make expiry date atleast one day away from today
  const today = new Date();
  today.setDate(today.getDate() + 1);
  const minDate = today.toISOString().split("T")[0];

  return (
    <section style={{ margin: "2rem 0rem" }} className="vacan-2 f-list-3">
      <div className="container">
        <div className="bg-color">
          <form>
            <div className="box-flex" style={{ marginBottom: ".5rem" }}>
              <h6>Vacancy Information</h6>
              <div className="btn" style={{ alignItems: "baseline" }}>
                <Preview button="Preview">
                  {isPreviewOn && (
                    <div style={{ textAlign: "left" }}>
                      <VacancyBox
                        noBtn
                        isPreview={isPreviewOn}
                        mode="normal"
                        {...{
                          ...formData,
                          ...defaultVals,
                          requiredSkills: [...selectedSkills],
                        }}
                      />
                    </div>
                  )}
                </Preview>
                <button onClick={handleSubmit} className="btn3" type="submit">
                  Create Vacancy
                </button>
              </div>
            </div>
            <div className="subtitle">
              <div className="titel-color">
                <h6
                  className="title-requ "
                  style={{ textAlign: "left", marginBlock: "1rem" }}
                >
                  Only fully completed vacancy will be approved
                </h6>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-6 col-lg-5 col-12 box">
                <p>Vacancy Title *</p>
                <input
                  type="name"
                  name="title"
                  onChange={handleChange}
                  onInput={handleChange}
                  className="input"
                  required=""
                  maxLength={50}
                />
                <div className="subtitle" style={{ marginTop: "5px" }}>
                  <div className="titel-color">
                    <label>50 Max Characters </label>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4 box">
                <p>Publish Vacancy *</p>
                <select
                  name="publish"
                  onChange={handleChange}
                  style={{
                    border:
                      formData?.publish == "false"
                        ? "1.9px solid red"
                        : "1.9px solid #00bd0f",
                  }}
                >
                  {/* <DefaultOption name="publish" /> */}

                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>
              <div className="col-xl-2 col-lg-2 col-sm-4 box">
                <p>Expiry Date *</p>
                <input
                  name="expiryDate"
                  type="date"
                  min={minDate}
                  className="input"
                  onChange={handleChange}
                ></input>
                {/* <div className="subtitle">
                  <div className="titel-color">
                    <label>DD-MMM-YYYY</label>
                  </div>
                </div> */}
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-4 box photos">
                <p>Upload More Vacancy Info.</p>
                {!file && (
                  <input
                    id="myPhotos"
                    type="file"
                    accept="application/pdf"
                    name="file"
                    onChange={handleFile}
                  />
                )}
                {file && (
                  <div className="upload-vac">
                    <div>{file}</div>
                    <div onClick={() => setConfirmWindow("file")}>Change</div>
                  </div>
                )}
                {confirmWindow == "file" && (
                  <ConfirmationModal
                    message={"delete the file"}
                    onConfirm={() => {
                      setFile("");
                    }}
                    close={() => {
                      setConfirmWindow("");
                    }}
                  />
                )}
                <div className="subtitle">
                  <div className="titel-color" style={{ marginTop: ".5rem" }}>
                    <label>PDF & Max File 5 MB</label>
                  </div>
                </div>
              </div>
              <div className="col-12 box">
                <SkillsSelect
                  set={setSelectedSkills}
                  horizontal
                  label={"Required Main Skills * (Key Words)"}
                  info={
                    "Click Add Skill button after skill selection (Max. 10 skills)"
                  }
                  propose={true}
                  letters={true}
                />

                <div className="subtitle">
                  <div className="titel-color">
                    <label>Max 10 Skills</label>
                  </div>
                </div>
              </div>
              <div className="col-12 box" style={{ marginBottom: "2rem" }}>
                <p>Vacancy Requirements *</p>
                <textarea
                  type="name"
                  style={{ height: "85%" }}
                  name="requirements"
                  className="input"
                  onInput={handleChange}
                  onChange={handleChange}
                  required=""
                  maxLength={800}
                />
                <div className="subtitle">
                  <div className="titel-color">
                    <label>800 Max Characters</label>
                  </div>
                </div>
              </div>
              <div className="box-flex2">
                <div className="">
                  <h6 className="text-center">Candidate</h6>
                  <div className="box-col">
                    <div className="box">
                      <p>Min Experience *</p>

                      <select
                        name="candidate.minExperience"
                        onChange={handleChange}
                      >
                        {/* <DefaultOption name="min experience" /> */}
                        <DefaultHiddenOption />
                        {data?.experiences?.map((exp) => (
                          <option key={exp} value={exp}>
                            {exp}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>
                        1<sup>st</sup> Language *
                      </p>
                      <select
                        name="candidate.firstLanguage"
                        className="input"
                        required=""
                        onChange={handleChange}
                      >
                        {/* <DefaultOption name="first language" /> */}
                        <DefaultHiddenOption />

                        {data?.language?.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>
                        2<sup>nd</sup> Language{" "}
                      </p>
                      <select
                        name="candidate.secondLanguage"
                        className="input"
                        required=""
                        onChange={handleChange}
                      >
                        {/* <DefaultOption name="second language" /> */}
                        <DefaultHiddenOption />

                        {data?.language?.map((lang) => (
                          <option key={lang} value={lang}>
                            {lang}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Nationality</p>
                      <select
                        onChange={handleChange}
                        name="candidate.nationality"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="nationality" /> */}
                        <option
                          key={"Any nationality"}
                          value={"Any nationality"}
                        >
                          Any nationality
                        </option>

                        {data?.country?.map((cou) => (
                          <>
                            <option key={cou} value={cou}>
                              {cou}
                            </option>
                            {cou == "Yemen" && (
                              <option value="" disabled="disabled">
                                ------------------------------------------------------------------------------
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-center">Location</h6>
                  <div className="box-col">
                    <div className="box">
                      <p>City *</p>
                      <input
                        onChange={handleChange}
                        onInput={handleChange}
                        type="name"
                        name="location.city"
                        className="input"
                        placeholder="Enter city"
                        required=""
                      />
                    </div>
                    <div className="box">
                      <p>Country *</p>
                      <select
                        onChange={handleChange}
                        name="location.country"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="country" /> */}
                        <DefaultHiddenOption />
                        {data?.country?.map((cou) => (
                          <>
                            <option key={cou} value={cou}>
                              {cou}
                            </option>
                            {cou == "Yemen" && (
                              <option value="" disabled="disabled">
                                ------------------------------------------------------------------------------
                              </option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Work Station *</p>
                      <select
                        onChange={handleChange}
                        name="location.workstation"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="workstation" /> */}
                        <DefaultHiddenOption />
                        {data?.workStation?.map((work) => (
                          <option key={work} value={work}>
                            {work}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Travel Required *</p>
                      <select
                        onChange={handleChange}
                        name="location.travelRequired"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="Travel required" /> */}
                        <DefaultHiddenOption />
                        {data?.travelWillingness?.map((travel) => (
                          <option key={travel} value={travel}>
                            {travel}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-center">Time</h6>
                  <div className="box-col ">
                    <div className="box">
                      <p>Start Date *</p>

                      <input
                        onChange={handleChange}
                        type="date"
                        name="time.startDate"
                        className="input"
                        defaultValue={new Date().toISOString().substr(0, 10)}
                        placeholder="Enter startdate"
                        required=""
                      />
                      {/* <div className="subtitle">
                        <div className="titel-color">
                          <label>DD-MMM-YYYY</label>
                        </div>
                      </div> */}
                    </div>
                    <div className="box">
                      <p>Time Period *</p>
                      <select
                        onChange={handleChange}
                        name="time.timePeriod"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="time period" /> */}
                        <DefaultHiddenOption />

                        {data?.timePeriod?.map((time) => (
                          <option key={time} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Min. Availability *</p>
                      <select
                        onChange={handleChange}
                        name="time.minAvailability"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="min availability" /> */}
                        <DefaultHiddenOption />

                        {data?.availability?.map((avail) => (
                          <option key={avail} value={avail}>
                            {avail}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Project Phase *</p>
                      <select
                        onChange={handleChange}
                        name="time.projectPhase"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="project phase" /> */}
                        <DefaultHiddenOption />

                        {data?.projectPhase?.map((phase) => (
                          <option key={phase} value={phase}>
                            {phase}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-center">Payment</h6>
                  <div className="box-col">
                    <div className="box">
                      <p>Hourly Rate ($) *</p>
                      {/* <div className="box-flex"> */}
                      <div>
                        <select
                          onChange={handleChange}
                          name="payment.hourlyRate"
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="hourly rate" /> */}
                          <DefaultHiddenOption />

                          {data?.hourlyRate?.map((rate) => (
                            <option key={rate} value={rate}>
                              {rate}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="box">
                      <p>Pay Type *</p>
                      <select
                        onChange={handleChange}
                        name="payment.payType"
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="pay type" /> */}
                        <DefaultHiddenOption />

                        {data?.payType?.map((pay) => (
                          <option key={pay} value={pay}>
                            {pay}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="box">
                      <p>Flights *</p>
                      <select
                        name="payment.flights"
                        onChange={handleChange}
                        className="input"
                        required=""
                      >
                        {/* <DefaultOption name="flights" /> */}
                        <DefaultHiddenOption />

                        <option value="Paid">Paid</option>
                        <option value="Not Paid">Not Paid</option>
                      </select>
                    </div>
                    <div className="box">
                      <p>Hotel *</p>
                      <select name="payment.hotel" onChange={handleChange}>
                        {/* <DefaultOption name="hotel" /> */}
                        <DefaultHiddenOption />

                        <option value="Paid">Paid</option>
                        <option value="Not Paid">Not Paid</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <h6 className="text-center">Contact</h6>
                  <div className="">
                    <div className="box">
                      <div className="box">
                        <p>Name *</p>
                        <input
                          type="name"
                          disabled
                          name="contact.name"
                          className="input"
                          required=""
                          value={`${profileData?.data?.user?.firstName} ${profileData?.data?.user?.lastName}`}
                        />
                      </div>
                    </div>
                    <div className="box">
                      <p>Email *</p>
                      <input
                        type="email"
                        name="contact.email"
                        className="input"
                        disabled
                        required=""
                        value={profileData?.data?.user?.email}
                      />
                    </div>
                    <div className="box">
                      <p>Phone</p>
                      <input
                        type="name"
                        name="contact.phone"
                        className="input"
                        required=""
                        disabled
                        value={profileData?.data?.user?.phone}
                      />
                      <p style={{ fontWeight: "normal" }}>
                        Registered freelancers with same vacancy “skills” will
                        receive an email to apply to this vacancy.
                      </p>
                      {/* <div className="subtitle" style={{ direction: "ltr" }}>
                        {" "}
                        <div
                          style={{ gap: "1rem", justifyContent: "flex-start" }}
                        >
                          <input
                            type="checkbox"
                            name="isPhonePublic"
                            onClick={handleCheckbox}
                          />
                          <label>Show phone to public</label>
                        </div>
                        <div
                          style={{ gap: "1rem", justifyContent: "flex-start" }}
                        >
                          <input
                            type="checkbox"
                            name="isPhoneRegistered"
                            onClick={handleCheckbox}
                          />
                          <label>Show phone to registered people</label>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="box-content">
                  <h6>Vacancy Highlight:</h6>
                  <div>
                    <input
                      type="checkbox"
                      name="isTrainer"
                      onClick={handleCheckbox}
                      id="trainerButton"
                    />
                    <label htmlFor="trainerButton" className="btn-color1">
                      Trainer
                    </label>
                  </div>
                  <div>
                    <input
                      type="checkbox"
                      name="isConsultant"
                      onClick={handleCheckbox}
                      id="consultantButton"
                    />
                    <label htmlFor="consultantButton" className="btn-color2">
                      Consultant
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <h6 className="title-requ">* Required Field</h6>
              </div>
            </div>

            <div className="box-flex" style={{ justifyContent: "flex-end" }}>
              <div className="btn" style={{ alignItems: "baseline" }}>
                <Preview button="Preview">
                  {isPreviewOn && (
                    <div style={{ textAlign: "left" }}>
                      <VacancyBox
                        noBtn
                        isPreview={isPreviewOn}
                        mode="normal"
                        {...{
                          ...formData,
                          ...defaultVals,
                          requiredSkills: [...selectedSkills],
                        }}
                      />
                    </div>
                  )}
                </Preview>
                <button onClick={handleSubmit} className="btn3" type="submit">
                  Create Vacancy
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

function DefaultHiddenOption() {
  return (
    <option value="" hidden>
      Choose an option
    </option>
  );
}

export default VacancyFormCreate;
