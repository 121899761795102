import { useEffect, useState } from "react";
import { Puff } from "../Layout/Loading";

const SITE_KEY = "6LdjCXklAAAAABXrZsPAE6alZBscSQrmx9gD2uoT";
const URL =
  process.env.NODE_ENV == "development"
    ? `http://localhost:3000/api/v1.0/auth/verifyrecaptchav3`
    : `/api/v1.0/auth/verifyrecaptchav3`;

const loadScriptByURL = (id, url, callback) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url;
    script.id = id;
    script.onload = function () {
      if (callback) callback();
    };
    document.body.appendChild(script);
  }

  if (isScriptExist && callback) callback();
};

function RecaptchaComponent({ setIsNotRobo }) {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {}
    );
  }, []);

  const handleOnClick = (e) => {
    if (isLoading) return;
    if (response?.success) return;
    setIsLoading(true);
    e.preventDefault();

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          submitData(token);
        });
    });
  };

  const submitData = async (token) => {
    await fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        token,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setResponse(res);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (response?.success) {
      setIsNotRobo(true);
    }
  }, [response]);

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        height: "2rem",
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: "2rem",
            height: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Puff />
        </div>
      ) : (
        <input
          onClick={handleOnClick}
          type="checkbox"
          id="robot"
          name="vehicle1"
          value="Car"
          checked={response?.success}
          required
          disabled={isLoading}
        />
      )}
      <label htmlFor="robot">I'm not a Robot</label>
    </div>
  );
}

export default RecaptchaComponent;
