import { useState } from "react";
import { FreelancerBox2 } from "../Components/Freelancer/FreelancerBox2";
import { FreelancerBoxLoading } from "../Components/Freelancer/FreelancerBoxLoading";
import { fetchFreelancerProfile } from "../fetch/freelancer/profile";
import { fetchFavFreelancers } from "../fetch/freelancer/fav";
import { NoData } from "../Components/NoData";

import SecureComponent from "../Components/SecureComponent";

const FavFreelancers = () => {
  const [page, setPage] = useState(0);
  const [filter, setFilters] = useState();
  const { isLoading, error, data } = fetchFavFreelancers(page);
  const nextPage = data?.doesNextExist;
  fetchFavFreelancers(nextPage ? page + 1 : page);

  const { data: freelancerprofile } = fetchFreelancerProfile();
  const loggedInFreelancer = freelancerprofile?.data;
  const allFavFreelancers = loggedInFreelancer?.favoriteFreelancers;

  function Pagination() {
    const buttonStyles = {
      backgroundColor: "#202020",
      color: "white",
      paddingInline: ".5rem",
      // paddingBlock: ".7rem",
      fontSize: "1.2rem",
      borderRadius: "5px",
      display: "flex",
      alignItems: "center",
      // backgroundColor: "#04225e",
    };
    const spanStyle = {
      fontSize: "1.5rem",
      marginInline: ".5rem",
      // color: "#04225e",
      // backgroundColor: "#04225e",
    };
    return (
      <div
        style={{
          display: "flex",
          fontSize: "2rem",
          justifyContent: "center",
          textAlign: "center",
          gap: "2rem",
          marginBlock: "1rem",
          // backgroundColor: "purple",
        }}
      >
        {(page > 0 || filter?.page > 0) && (
          <>
            <button
              style={buttonStyles}
              onClick={() =>
                filter
                  ? setFilters((prev) => ({
                      ...prev,
                      page: prev?.page > 0 ? prev.page - 1 : 0,
                    }))
                  : setPage((prev) => prev - 1)
              }
            >
              <span style={spanStyle}>&lt;</span>
              previous
            </button>
          </>
        )}
        {filter ? (
          <>
            <div style={buttonStyles}>
              <span style={spanStyle}>
                {filter?.page ? filter?.page + 1 : 1}
              </span>
            </div>
          </>
        ) : (
          <>
            <div style={buttonStyles}>
              <span style={spanStyle}>{page + 1}</span>
            </div>
          </>
        )}
        {nextPage && (
          <>
            <button
              style={buttonStyles}
              onClick={() =>
                filter
                  ? setFilters((prev) => ({
                      ...prev,
                      page: prev?.page ? prev.page + 1 : 1,
                    }))
                  : setPage((prev) => prev + 1)
              }
            >
              next
              <span style={spanStyle}>&gt;</span>{" "}
            </button>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      <section className="company1" style={{ marginTop: "2rem" }}>
        <div className="container">
          <div>
            <h1
              className="head "
              style={{
                fontWeight: "bold",
                fontSize: "1.8rem",
                textAlign: "center",
                // color: "#0070c0",
                // color: "#04225e",
              }}
            >
              {<>Favorite Freelancers</>}
            </h1>
          </div>
        </div>
      </section>
      <Pagination />

      {isLoading && <FreelancerBoxLoading />}
      {!isLoading && !data?.data?.length && (
        <NoData description={"No favorite freelancer added."} />
      )}

      {data?.data?.map(
        (freelancer) =>
          allFavFreelancers?.includes(freelancer?.id) && (
            <FreelancerBox2
              freelancer={freelancer}
              fav={true}
              key={freelancer.id}
            />
          )
      )}
      {/* show pagination in bottom if number of freelancers is more that 5 */}
      {data?.data?.length > 5 && <Pagination />}
    </>
  );
};

const Wrapper = () => (
  <SecureComponent>
    <FavFreelancers />
  </SecureComponent>
);

export default Wrapper;
