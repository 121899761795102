import { useEffect, useState } from "react";
import {
  checkRegisteredUser,
  validateData,
  dateFormatter,
} from "../../utils/helper";
import VacancyBox from "./VacancyBox";
import { fetchVacancies } from "../../fetch/vacancy/all";
import { fetchProfile } from "../../fetch/profile/myProfile";
import VacancySearch from "./VacancySearch";
import axiosInstance from "../../axios/axios";
import { NoData } from "../../Components/NoData";
import { useNavigate } from "react-router-dom";
import { FreelancerBoxLoading } from "../../Components/Freelancer/FreelancerBoxLoading";
import { queryClient } from "../../routes/Reactquery";
import PaginationView from "../../Components/Freelancer/PaginationView";

const VacanciesList = ({ mode }) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState({});

  const [listData, setListData] = useState();
  const [noVacancy, setNoVacancy] = useState(false);
  const [totalPages, setTotalPages] = useState(-1);

  let { isLoading, error, data } = fetchVacancies(page, search);
  const nextPage = page < totalPages - 1;

  const isApplied = mode == "applied";
  const isPost = mode == "posted";
  const buttonStyle = {
    backgroundColor: "#202020",
    color: "white",
    paddingInline: ".5rem",
    // paddingBlock: ".7rem",
    fontSize: "1.2rem",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
  };

  const isAppliedOrPost = ["applied", "posted"].includes(mode);

  if (isAppliedOrPost) {
    !checkRegisteredUser() && navigate("/login");
  }

  const {
    isLoading: profileLoading,
    error: profileError,
    data: profileData,
  } = fetchProfile();

  if (mode == "applied") {
    let newVacancies = [];
    profileData?.data?.appliedVacancies?.map((v) =>
      newVacancies.push({ ...v.vacancy, on: v.on })
    );
    data = { data: [...newVacancies]?.reverse() };
  } else if (mode == "posted") {
    let newVacancies = [];
    profileData?.data?.postedVacancies?.map((x) => newVacancies.push(x));
    data = { data: [...newVacancies].reverse() };
  }

  const handleSearch = (val) => {
    setPage(1);
    setSearch(val);
  };

  useEffect(() => {
    if (mode == "posted") {
      console.log("REFETCH");
      queryClient.invalidateQueries(["profile"]);
    }
  }, []);

  useEffect(() => {
    if (data?.totalPages) {
      if (totalPages != data?.totalPages) setTotalPages(data?.totalPages);
    } else {
      if (totalPages != 0) {
        setTotalPages(0);
      }
    }
  }, [data]);

  return (
    <>
      <section className="f-list-1">
        {isAppliedOrPost && (
          <div className="container">
            <div
              style={{
                display: "flex",
                fontSize: "2rem",
                justifyContent: "start",
                textAlign: "left",
                gap: "5rem",
              }}
            >
              <h2 style={{ color: "rgb(17 115 186)" }}>
                {" "}
                {isApplied ? "Applied To " : "Posted "} Vacancies
              </h2>
            </div>
          </div>
        )}
        {!isAppliedOrPost && (
          <VacancySearch apiCall={(data) => handleSearch(data)} />
        )}
      </section>

      {!isAppliedOrPost && (
        <div>
          <PaginationView
            page={page - 1}
            totalPages={totalPages}
            gotoPage={(goto) => {
              setPage(goto + 1);
            }}
          />
          {/* <div
            style={{
              display: "flex",
              fontSize: "2rem",
              justifyContent: "center",
              textAlign: "center",
              gap: "1rem",
              marginBlock: "1rem",
              height: "max-content",
            }}
          >
            {page > 1 && (
              <button
                style={buttonStyle}
                onClick={() => setPage((prev) => (prev != 0 ? prev - 1 : 0))}
              >
                <span style={{ fontSize: "1.5rem", marginRight: ".5rem" }}>
                  &lt;
                </span>
                previous
              </button>
            )}
            {!data?.data?.length == 0 && (
              <div style={buttonStyle}>
                <span style={{ fontSize: "1.5rem", marginInline: ".5rem" }}>
                  {page}
                </span>
              </div>
            )}
            {true && (
              <button
                style={buttonStyle}
                onClick={() =>
                  setPage((prev) => (data?.data?.length > 0 ? prev + 1 : prev))
                }
              >
                next
                <span style={{ fontSize: "1.5rem", marginLeft: ".5rem" }}>
                  &gt;
                </span>
              </button>
            )}
          </div> */}
        </div>
      )}
      {data?.data &&
        data?.data?.map((vacancy, index) => (
          <>
            <VacancyBox
              noBtn={isAppliedOrPost}
              on={vacancy?.on}
              key={index}
              {...vacancy}
              mode={mode}
            />
          </>
        ))}

      {isLoading && !data?.data?.length ? (
        <FreelancerBoxLoading />
      ) : (
        <>
          {noVacancy && (
            <NoData description={"No vacancies found with match query"} />
          )}
          {!data?.data?.length && isPost && !profileLoading && (
            <NoData description={"No posted vacancies found"} />
          )}
          {!data?.data?.length && isApplied && !profileLoading && (
            <NoData description={"No applied vacancies found"} />
          )}
          {!isLoading &&
            !data?.data?.length &&
            !noVacancy &&
            !isAppliedOrPost && <NoData description={"No vacancies found"} />}
        </>
      )}
    </>
  );
};

export default VacanciesList;
