import React, { useRef } from "react";
import { TIMES, fieldsData } from "../../utils/fields";
import { DefaultOption, DefaultOptionTime } from "../../utils/components";
import { FreelancerBox2 } from "../../Components/Freelancer/FreelancerBox2";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { fetchFreelancerForEconsultancy } from "../../fetch/freelancer/all";
import { useState } from "react";
import { FreelancerBoxLoading } from "../../Components/Freelancer/FreelancerBoxLoading";
import axiosInstance from "../../axios/axios";
import { toast } from "react-toastify";
import RecaptchaComponent from "../../Components/Recaptcha/Recaptcha";
import { queryClient } from "../../routes/Reactquery";
import { fetchEconsultancyBookings } from "../../fetch/econsultancy/all";
import { fetchInput } from "../../fetch/input/main";
import { durationFinder, totalFeeCalc } from "../../utils/helper";
import { Preview } from "../../Components/Freelancer/Preview";
import { useOnClickOutside } from "../../Components/Hook/clickOutSide";
import { isValidMail } from "../../utils/validate";

const EconsultationBooking = () => {
  const navigate = useNavigate();
  const { freelancerId } = useParams();
  const [isNotRobo, setIsNotRobo] = useState(false);
  const [times, setTimes] = useState();
  const [data, setData] = useState({});
  const [showAvailability, setShowAvailability] = useState(false);

  const {
    isLoading: freelancerLoading,
    error: freelancerError,
    data: freelancer,
  } = fetchFreelancerForEconsultancy(
    freelancerId,
    window.location.pathname.includes("econsultancy")
  );

  const {
    isLoading: inputLoading,
    error: inputError,
    data: inputData,
  } = fetchInput();

  const availableDaysFreelancer =
    freelancer?.freelancer?.eConsultation?.availability;
  const selectedDate = data?.["bookingDetails.preferenceDate"];
  const selectedDay = new Date(selectedDate).toLocaleString("en-US", {
    weekday: "long",
  });

  const timesOfSelectedDay = availableDaysFreelancer?.find(
    (days) => days.day == selectedDay
  );
  const dayAvailableTimes = !!timesOfSelectedDay
    ? TIMES.slice(
        TIMES.indexOf(timesOfSelectedDay?.from),
        TIMES.indexOf(timesOfSelectedDay?.to)
      )
    : null;

  const timeFrom = dayAvailableTimes?.slice(0, -1);
  const timeTo = dayAvailableTimes?.slice(data["bookingDetails.startTime"], 0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fields = fieldsData.ECONSULTATION_BOOKING;
    for (var i = 0; i < fields.length; i++) {
      if (data[fields[i].name]) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (data[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                `${fields[i].name} should have minimum ${fields[i].min} characters`
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field =
          fields[i].name.split(".").length > 1
            ? fields[i].name.split(".")[1]
            : fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }

    const validMail = isValidMail(data?.["clientInformation.email"]);

    if (!validMail) {
      return toast.warning("Please enter a valid email");
    }
    if (!isNotRobo) return toast.warning("Please select I'm not a robot");

    const createBooking = async () =>
      await axiosInstance
        .post("/econsultancybooking", {
          "bookingDetails.timezone":
            freelancer?.freelancer?.eConsultation?.timezone,
          ...data,
          totalFees: document.getElementById("totalFees")?.placeholder,
          freelancer: freelancer?.freelancer?.id,
        })
        .then((res) => {
          setTimeout(async () => {
            await queryClient.invalidateQueries(["econsultancy"]);
            navigate("/econsultancy/bookings");
          }, 2000);
        });

    toast.promise(createBooking, {
      pending: "Econsultation booking started....",
      success: "Econsultation booked successfully",
      error: "Econsultation booking failed",
    });
  };
  fetchEconsultancyBookings();

  const handleChange = (e) => {
    if (
      e.target.name == "bookingDetails.startTime" ||
      e.target.name == "bookingDetails.endTime"
    ) {
      switch (e.target.name) {
        case "bookingDetails.startTime": {
          setTimes(
            TIMES?.slice(
              TIMES.indexOf(e.target.value) + 1,
              TIMES.indexOf(timesOfSelectedDay?.to) + 1
            )
          );
          break;
        }
      }
    }
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name == "bookingDetails.preferenceDate") {
      const selectedDate = e.target.value;
      const selectedDay = new Date(selectedDate).toLocaleString("en-US", {
        weekday: "long",
      });

      const timesOfSelectedDay = availableDaysFreelancer?.find(
        (days) => days.day == selectedDay
      );
      if (timesOfSelectedDay == null) {
        toast.warning("Freelancer is not available on the selected day.");
      }
    }
  };

  const handleChecked = (e) =>
    setData({ ...data, [e.target.name]: e.target.checked });

  const freelancerHourlyRate = freelancer?.freelancer?.eConsultation?.hourlyRate
    ? freelancer?.freelancer?.eConsultation?.hourlyRate == "Negotiable"
      ? "Negotiable"
      : freelancer?.freelancer?.eConsultation?.hourlyRate?.slice(
          1,
          freelancer?.freelancer?.eConsultation?.hourlyRate?.length
        )
    : "NIL";

  return (
    <>
      {freelancerLoading ? (
        <FreelancerBoxLoading />
      ) : (
        freelancer && (
          <FreelancerBox2
            econsultBtn={false}
            freelancer={freelancer?.freelancer}
            fav={freelancer?.isFav}
            invalidateFav={[`freelancerforeconsultancy`, freelancerId]}
          />
        )
      )}
      <form>
        <section className="company1">
          <div className="container">
            <div className="bg-color">
              <div className="row">
                <div className="col-6">
                  <h3 className="head">
                    Client Information &amp; Requirements
                  </h3>
                </div>
                <div className="col-6">
                  <p className="title-requ">* Required Field</p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="box col-md-6">
                      <p>Name *</p>
                      <input
                        type="name"
                        name="clientInformation.name"
                        className="input"
                        required=""
                        onInput={handleChange}
                        onChange={handleChange}
                        placeholder="Enter name"
                      />
                      <div className="box col-md-6"></div>

                      <p>Organization</p>
                      <input
                        type="text"
                        name="clientInformation.organization"
                        className="input"
                        required=""
                        placeholder="Enter organization"
                        onInput={handleChange}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="box col-md-6">
                      <p>Email *</p>
                      <input
                        type="email"
                        name="clientInformation.email"
                        className="input"
                        required
                        onChange={handleChange}
                        onInput={handleChange}
                        placeholder="Enter email"
                      />
                      {/* <div className="checkbox">
                      <div className="bg-color">
                        <label>Email Confirmed</label>
                      </div>
                    </div> */}
                      <p>Phone</p>
                      <input
                        type="name"
                        name="clientInformation.phone"
                        className="input"
                        required=""
                        placeholder="Enter phone"
                        onInput={handleChange}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="box ">
                    <p>Requirements *</p>
                    <textarea
                      name="clientInformation.requirements"
                      id=""
                      onChange={handleChange}
                      onInput={handleChange}
                      defaultValue={""}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="company1">
          <div className="container">
            <div className="bg-color">
              <div
                className="row"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div className="col-6">
                  <h3 className="head">Requested Booking Date &amp; Time </h3>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="box">
                    <p>Select Preferred Date *</p>
                    <input
                      type="date"
                      name="bookingDetails.preferenceDate"
                      min={new Date().toISOString().split("T")[0]}
                      className="input"
                      onChange={handleChange}
                    />
                    <div className="subtitle">
                      <div className="sub-text">
                        <label>Pending on freelancer confirmation</label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {JSON.stringify(freelancer?.freelancer?.eConsultation?.timezone)} */}
                <div className="col-lg-2">
                  <div className="box ">
                    <p>Client Time Zone *</p>

                    <select
                      name="bookingDetails.timezone"
                      className="input"
                      type="text"
                      onChange={handleChange}
                      required=""
                    >
                      <DefaultOption name="timezone" />

                      {inputData?.timezones?.map((timezone) => (
                        <option
                          key={timezone}
                          value={timezone}
                          selected={
                            freelancer?.freelancer?.eConsultation?.timezone ==
                            timezone
                          }
                        >
                          {timezone}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="box ">
                    <p>Meeting Start Time *</p>
                    <select
                      name="bookingDetails.startTime"
                      className="input"
                      onChange={handleChange}
                      required=""
                    >
                      <DefaultOptionTime name="HH:MM" />
                      {dayAvailableTimes?.map((time) => (
                        <option key={time} value={time}>
                          {" "}
                          {time}
                        </option>
                      ))}
                    </select>
                    <div className="subtitle">
                      <div className="sub-text">
                        {!dayAvailableTimes?.length ? (
                          <label>Select a day</label>
                        ) : (
                          <label>Based on client time-zone</label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="box">
                    <p>Meeting End Time * </p>
                    <select
                      name="bookingDetails.endTime"
                      className="input"
                      onChange={handleChange}
                      required=""
                    >
                      <DefaultOptionTime name="HH:MM" />
                      {times?.map((time) => (
                        <option key={time} value={time}>
                          {" "}
                          {time}
                        </option>
                      ))}
                    </select>
                    <div className="subtitle">
                      <div className="sub-text">
                        {/* <label>Based on client time-zone</label> */}
                        {!times?.length && (
                          <label>Select meeting start time</label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box ">
                    <p>Propose Alternative Date &amp; Time</p>
                    <input
                      type="Propose Alternative Date & Time"
                      name="bookingDetails.proposeAlternateDate"
                      className="input"
                      placeholder="Any Mon. and Tues - any time before 6 PM"
                      required=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <br />

              <AvailabilityUI freelancer={freelancer} />
            </div>
          </div>
        </section>
        <section className="company1">
          <div className="container">
            <div className="bg-color">
              <form action="">
                <div className="row">
                  <div
                    className="col-lg-2 col-md-3 col-sm-4 "
                    style={{ minWidth: "20%" }}
                  >
                    <h3 className="head"> Booking </h3>
                    <button onClick={handleSubmit} type="submit">
                      <a
                        href=""
                        className="a-btn1"
                        style={{ paddingInline: "2rem", paddingBlock: "1rem" }}
                      >
                        {" "}
                        Book Freelancer e-Consultation Appointment{" "}
                      </a>
                    </button>
                  </div>
                  <div
                    className="col-lg-7 col-md-9 col-sm-8"
                    style={{ width: "55%", flexBasis: "content" }}
                  >
                    <ul>
                      <li>
                        Notice: - This is a paid e-Consultation due to be paid
                        after completion (based on actual hours)
                      </li>
                      <li>
                        - Total charges will be billed by and paid directly to
                        the e-Consultant as per preferred channel.{" "}
                      </li>
                      <li>
                        - Selected preferred date and time are subject to
                        e-Consultant availability.
                      </li>
                      <li>
                        - Mena Freelancer is providing the connection only and
                        not liable for any quality or financial issues.
                      </li>
                    </ul>
                    <div className="list-flex position">
                      <div className="checkbox">
                        <input
                          type="checkbox"
                          id="terms"
                          name="isAccept"
                          onChange={handleChecked}
                          defaultValue="Car"
                          required
                        />
                        <label htmlFor="terms" className="day-time">
                          I accept and confirm the above information *
                        </label>
                      </div>
                      <div className="checkbox">
                        <RecaptchaComponent setIsNotRobo={setIsNotRobo} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-3"
                    style={{
                      minWidth: "20%",
                      margin: "auto",
                      marginTop: "2rem",
                    }}
                  >
                    <div className="list-flex">
                      <div className="box box-flex">
                        <p>Hourly Rate (In US $)</p>
                        <input
                          type="Propose Alternative Date & Time"
                          name="Hourly Rate"
                          className="input"
                          placeholder={freelancerHourlyRate}
                          disabled
                          required=""
                        />
                      </div>
                      <div className="box box-flex">
                        <p>Total Fees (In US $)</p>
                        <p></p>
                        <input
                          type="Propose Alternative Date & Time"
                          id="totalFees"
                          name="totalFees"
                          className="input"
                          placeholder={totalFeeCalc(
                            data["bookingDetails.startTime"],
                            data["bookingDetails.endTime"],
                            freelancerHourlyRate != "Negotiable" &&
                              freelancerHourlyRate != "NIL" &&
                              freelancerHourlyRate
                          )}
                          disabled
                          required=""
                        />
                        <div className="subtitle">
                          <div className="sub-text">
                            <label>Includes Taxes (If Any)</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

function AvailabilityUI({ freelancer }) {
  const availRef = useRef();
  const [visible, setVisible] = useState(false);
  useOnClickOutside(availRef, () => setVisible(false));

  useEffect(() => {
    function handleEscape(event) {
      if (event.keyCode === 27) {
        console.log("ESCAPE");
        setVisible(false);
      }
    }

    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div ref={availRef}>
      <div type="div" className="btn2">
        <a
          style={{
            cursor: "pointer",
            color: "#345fb4",
            fontSize: "1.2rem",
          }}
          onClick={() => setVisible((prev) => !prev)}
        >
          ⓘ {!visible ? <>Show</> : <>Hide</>} freelancer eConsultation
          availability
        </a>
      </div>
      {visible && (
        <div
          style={{
            padding: "15px",
            display: "flex",
            justifyContent: "flex-start",
            position: "absolute",
            color: "#333",
            fontSize: "1.3rem",
            // borderRadius: "30px",
            marginTop: "1rem",
            // backgroundColor: "white",
            backgroundColor: "#eee",
            zIndex: 3,
            border: "1px solid #bbb",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{ width: "100%", textAlign: "right", cursor: "pointer" }}
              onClick={() => setVisible(false)}
            >
              ✕
            </p>
            <table
              style={{
                borderCollapse: "separate",
                // borderSpacing: "0 1rem",
                marginTop: ".5rem",
                background: "white",
                padding: "4px",
                borderRadius: "7px",
              }}
            >
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    background: "#04225e",
                    color: "white",
                  }}
                >
                  <th
                    style={
                      {
                        // paddingInline: "5px",
                      }
                    }
                  >
                    Day
                  </th>
                  <th
                    style={
                      {
                        // padding: "5px",
                      }
                    }
                  >
                    From
                  </th>
                  <th
                    style={
                      {
                        // padding: "5px",
                      }
                    }
                  >
                    To
                  </th>
                </tr>
              </thead>
              {freelancer?.freelancer?.eConsultation?.availability?.map(
                (availability) => (
                  <>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            paddingBlock: "5px",
                            paddingInline: "3px",
                          }}
                        >
                          {availability?.day}
                        </td>
                        <td
                          style={{
                            paddingBlock: "5px",
                            paddingInline: "3px",
                          }}
                        >
                          {availability?.from}
                        </td>
                        <td
                          style={{
                            paddingBlock: "5px",
                            paddingInline: "3px",
                          }}
                        >
                          {availability?.to}
                        </td>
                      </tr>
                    </tbody>
                  </>
                )
              )}
            </table>

            <p
              style={{
                // maxWidth: "30rem",
                // overflow: "clip",
                whiteSpace: "initial",
                marginTop: "1.5rem",
              }}
            >
              <b>Freelancer timezone </b>
              <br />
              {freelancer?.freelancer?.eConsultation?.timezone}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
export default EconsultationBooking;
