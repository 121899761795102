import { formatData } from "../../helper";

/**
 * get Data
 * @param {*} type
 * @param {*} url
 * @param {*} params
 * @param {*} method
 */

export function sendRequest(type, url, params, method, data = {}) {
  return {
    type,
    payload: {
      request: {
        url,
        method,
        params,
        data: formatData(data),
      },
    },
  };
}
