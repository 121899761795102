import React from "react";
import ReactDOM from "react-dom/client";
import Query from "./routes/Reactquery";
import { RouterProvider } from "react-router-dom";
import router from "./routes/routes";
import store from "./redux/store";
import { Provider } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import "./css/bootstrap.css";
import "./css/bootstrap.min.css";
import "./css/responsive.css";
import "./css/style.css";
import "./css/stylesReact.css";
import "./assets/icon/all.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <Provider store={store}>
      <Query>
        <RouterProvider router={router} />
      </Query>
    </Provider>
  </React.StrictMode>
);

//not this
