import { useNavigate, Navigate } from "react-router-dom";
import { isLoggedIn } from "../utils/helper";
import { useEffect } from "react";

const SecureComponent = ({ children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn()) {
      console.log("HERE", isLoggedIn());

      navigate("/login");
    }
  }, []);

  return <>{children}</>;
};

export default SecureComponent;
