import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOnClickOutside } from "../Components/Hook/clickOutSide";
import { fetchCMS } from "../fetch/cms/main";
import ReactPlayer from "react-player";
import { Puff } from "../Components/Layout/Loading";
import { fetchInput } from "../fetch/input/main";
import { fetchFreelancers } from "../fetch/freelancer/all";
import { fetchVacancies } from "../fetch/vacancy/all";

function AdBox({ banner }) {
  const redirectToExternalWebsite = () => {
    window.location.href = banner?.link;
  };
  return (
    <>
      <section class="section-add">
        <div class="container" onClick={redirectToExternalWebsite}>
          <img
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            src={banner?.img}
          ></img>
        </div>
      </section>
    </>
  );
}

const HowItWorksBox = ({ data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const vidRef = useRef(null);
  const ref = useRef(null);
  // useOnClickOutside(ref, () => {
  //   console.log(vidRef.current.pause());
  // });

  return (
    <div
      class="col-lg-6 box"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <h3 class="">{data?.title}</h3>
        <div
          style={{ fontSize: "1.7rem" }}
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        />
      </div>
      <div
        ref={ref}
        style={{
          cursor: "pointer",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          marginTop: "2rem",
          width: "auto",
          height: "auto",
          paddingTop: "56.25%",
        }}
      >
        <video
          class="video3"
          controls
          height="100%"
          width="100%"
          style={{ position: "absolute", top: 0, left: 0, height: "100%" }}
          ref={vidRef}
        >
          <source src={data?.img} />
        </video>

        {/* <ReactPlayer
          url={data?.img}
          ref={vidRef}
          controls
          playsinline
          height="100%"
          width="100%"
          style={{ position: "absolute", top: 0, left: 0 }}
        /> */}
      </div>{" "}
    </div>
  );
};

const Index = () => {
  const { isLoading, error, data } = fetchCMS();
  const dataWorks = data?.works;

  const navigate = useNavigate();

  if (error) return <>error</>;

  return (
    <>
      <MainBox />
      <AdBox banner={data?.works?.banners} />
      <section class="section2" style={{ marginBottom: "10rem" }}>
        <div class="container">
          <h1 class="heading">How it works</h1>
          {isLoading ? (
            <div
              class="row"
              style={{
                height: "30rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Puff />
            </div>
          ) : (
            <div class="row">
              <HowItWorksBox data={dataWorks?.forBusiness} key={"forbusivid"} />
              <HowItWorksBox
                data={dataWorks?.forFreelancer}
                key={"forfreevid"}
              />
            </div>
          )}
        </div>
      </section>

      <section class="section3">
        <div class="container">
          <div class="cover-img">
            <h1
              class="heading"
              dangerouslySetInnerHTML={{
                __html: dataWorks?.freelancerContent?.title,
              }}
            />
            {/* <div style={{ height: "30rem", backgroundColor: "blue" }}>hi</div> */}
            <p
              class="title"
              // style={{ fontSize: "1.7rem" }}
              style={{ fontSize: "2rem" }}
              dangerouslySetInnerHTML={{
                __html: dataWorks?.freelancerContent?.content,
              }}
            />
            <div class="cards" style={{ color: "white" }}>
              {dataWorks?.freelancerContent?.subContent?.map((val, idx) => (
                <ImgCaptionBox
                  img={val.img}
                  header={val.title}
                  para={val.content}
                  bigImg
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section class="section4">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 col-md-8 col-sm-12">
              <h1
                class="heading"
                dangerouslySetInnerHTML={{
                  __html: dataWorks?.eConsultant?.title,
                }}
              />
              <p
                class="title"
                style={{ fontSize: "2rem" }}
                dangerouslySetInnerHTML={{
                  __html: dataWorks?.eConsultant?.content,
                }}
              />
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 align-self-center text-center">
              <img src="images/h-icon4.png" alt="" />
            </div>
          </div>
          {/* <hr /> */}
        </div>
      </section>
      <section class="section5" style={{ marginBottom: "4rem" }}>
        <div class="container">
          <div class="cards">
            {dataWorks?.eConsultant?.subContent?.map((val) => (
              <ImgCaptionBox
                img={val.img}
                header={val.title}
                para={val.content}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

function MainBox() {
  const navigate = useNavigate();

  const { isLoading, error, data } = fetchCMS();
  const dataWorks = data?.works;

  const isLoggedIn = !!localStorage.getItem("Auth");

  //pre fetching

  fetchInput();
  fetchFreelancers({ page: 0 });
  fetchVacancies(1, {});

  function iAmLink() {
    isLoggedIn ? navigate("/vacancies") : navigate("/signup");
  }
  function iNeedLink() {
    isLoggedIn ? navigate("/vacancies/create") : navigate("/signup");
  }

  if (error) return <>error</>;
  return (
    <>
      <section class="section2" style={{ marginBlock: "8rem" }}>
        {isLoading ? (
          <div
            style={{
              display: "flex ",
              justifyContent: "center",
              height: "20rem",
              alignItems: "center",
            }}
          >
            <Puff />
          </div>
        ) : (
          <div
            class="container"
            style={{ display: "flex", flexDirection: "column", gap: "2rem" }}
          >
            <h1 class="heading">{data.title}</h1>
            <p
              class="title"
              style={{ fontSize: "2rem" }}
              dangerouslySetInnerHTML={{
                __html: data?.titleContent,
              }}
            />

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: "3rem",
              }}
            >
              <a className="LinkButtonReact" onClick={iNeedLink}>
                I NEED A FREELANCER
              </a>
              <a className="LinkButtonReact" onClick={iAmLink}>
                I AM A FREELANCER
              </a>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

function ImgCaptionBox({ img, header, para, bigImg }) {
  const elRef = useRef();

  const navigate = useNavigate();
  const link = header?.toLowerCase()?.includes("booking")
    ? "/freelancers"
    : false;

  // For styling data fetched from backend

  useLayoutEffect(() => {
    if (elRef.current) {
      const firstPara = elRef.current.querySelectorAll("p")[0];
      if (firstPara) firstPara.style.fontSize = "1.5rem";
    }
  });
  return (
    <div
      class="box"
      style={{
        display: "flex",
        flexDirection: "column",
        cursor: link && "pointer",
      }}
      onClick={() => {
        if (link) navigate(link);
      }}
    >
      <div
        style={{
          height: bigImg ? "15rem" : "10rem",
        }}
      >
        <img style={{ height: bigImg ? "13rem" : "6rem" }} src={img} alt="" />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h6 style={{ fontSize: "1.9rem" }}>{header}</h6>
        <div
          className="htmlinnerpara"
          ref={elRef}
          style={{ fontSize: "1.5rem" }}
          dangerouslySetInnerHTML={{
            __html: para,
          }}
        />
      </div>
    </div>
  );
}

export default Index;
