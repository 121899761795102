import React, { useEffect, useState } from "react";
import { fetchInput } from "../../fetch/input/main";
import { fetchProfile } from "../../fetch/profile/myProfile";
import { KeyskillsSelect } from "../../Components/FreelancerProfile/KeySkills";
import { useMutation } from "react-query";
import axiosInstance from "../../axios/axios";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ModalBuilder from "../../Components/ModalBuilder";
import VacancyBox from "./VacancyBox";
import { checkRegisteredUser } from "../../utils/helper";
import { DefaultOption } from "../../utils/components";
import SkillsSelect from "../../Components/Freelancer/SkillsSelect";
import { queryClient } from "../../routes/Reactquery";
import { fullName } from "../../helper";
import { fieldsData } from "../../utils/fields";
import { Preview } from "../../Components/Freelancer/Preview";
import ConfirmationModal from "../../Components/Layout/ConfirmationModal";

const VacancyForm = ({ mode }) => {
  const [file, setFile] = useState("");
  const [confirmWindow, setConfirmWindow] = useState(false);

  let { vacancyId } = useParams();

  const isCreate = mode == "CREATE";

  const [load, setLoad] = useState({});

  const isEdit = mode == "EDIT";

  const {
    isLoading: profileLoading,
    error: profileError,
    data: profileData,
  } = fetchProfile();

  useEffect(() => {
    vacancyId && loadData();
  }, [profileData, vacancyId]);

  const customFileStyle = {
    fontWeight: "700",
    cursor: "pointer",
    color: "#0e71b9",
    fontSize: "12px",
    textTransform: "uppercase",
  };

  const loadData = async () => {
    // const data = await axiosInstance.get(`/vacancy/${vacancyId}`);
    // setLoad(data?.data?.data?.data);
    const data = profileData?.data?.postedVacancies.find(
      (vacancy) => vacancy.id == vacancyId
    );
    if (profileData) {
      setLoad(data);
      setIsPreviewOn(true);
      // console.log("SETTING DATA");
      // console.log(data?.requiredSkills);
      // setSelectedSkills(data?.requiredSkills);
    }
  };

  useEffect(() => {
    console.log("LOADLOAD");
    console.log(load?.requiredSkills);
    if (load?.requiredSkills) setSelectedSkills(load?.requiredSkills);
  }, [load]);

  const navigate = useNavigate();
  useEffect(() => {
    !checkRegisteredUser() && navigate("/login");
  }, []);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [formData, setFormData] = useState({});
  const [isPreviewOn, setIsPreviewOn] = useState(false);

  const { isLoading, error, data } = fetchInput();

  const handleLoad = () => {
    console.log(isCreate);
  };
  useEffect(() => {}, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let exit = false;
    let dataProps = { ...formData };
    let parsedData = {};
    if (!selectedSkills?.length) {
      return toast.warn(`Please select atleast one skill.`);
    }
    Object.keys(dataProps).map((x) => {
      if (!dataProps[x]?.toString()?.includes("Any")) {
        parsedData = { ...parsedData, [x]: dataProps[x] };
      } else if (!exit) {
        toast.warn(
          <span>
            Please select{" "}
            <b>{x?.split(".")?.length > 1 ? x?.split(".")[1] : x}</b>
          </span>
        );
        exit = true;
        return false;
      }
    });
    if (exit) return;
    const finalData = {
      ...load,
      publish: JSON.stringify(load?.publish),
      ...parsedData,
      requiredSkills: [...selectedSkills],
    };
    console.log(finalData);
    const fields = fieldsData.VACANCY_CREATE;
    for (var i = 0; i < fields.length; i++) {
      const multipleFields = fields[i].name.split(".").length;
      if (finalData[fields[i].name] && multipleFields <= 1) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (finalData[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                `${fields[i].name} should have minimum ${fields[i].min} characters`
              );
              return;
            }
          }
          default:
            break;
        }
      } else if (
        multipleFields > 1 &&
        finalData[fields[i].name.split(".")[0]][fields[i].name.split(".")[1]]
      ) {
        switch (fields[i].type) {
          case "select":
            break;
          case "text": {
            if (finalData[fields[i].name]?.length < fields[i]?.min) {
              toast.warn(
                `${fields[i].name} should have minimum ${fields[i].min} characters`
              );
              return;
            }
            continue;
          }
          default:
            break;
        }
      } else {
        const field =
          fields[i].name.split(".").length > 1
            ? fields[i].name.split(".")[1]
            : fields[i].name.split(".")[0];
        toast.warn(
          <span>
            Please enter the required field{" "}
            <b>{field.charAt(0).toUpperCase() + field.slice(1)}</b>
          </span>
        );
        return;
      }
    }
    if (mode == "EDIT") {
      toast.promise(vacancyUpdateMutation.mutateAsync(), {
        pending: "Vacancy is updating...",
        success: "Vacancy updated successfully",
        error: "Vacancy update failed",
      });
    } else {
      vacancyCreateMutation.mutate();
    }
  };

  const vacancyCreateMutation = useMutation(CreateVacancy, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
      console.log("INVALIDATE PROFILE");
      toast.success("Vacancy created");
      // profile

      setTimeout(() => {
        navigate("/vacancies");
      }, 2000);
    },
  });

  const textFormatter = (chars) => {
    return chars.length > 10 ? chars.slice(0, 10) + "...." : chars;
  };

  const vacancyUpdateMutation = useMutation(UpdateVacancy, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
      // setTimeout(() => {}, 2000);
      queryClient.invalidateQueries(["profile"]);
      navigate("/vacancies-posted");
    },
  });

  async function UpdateVacancy() {
    await axiosInstance.put(
      `/vacancy/${load["id"]}`,
      {
        ...formData,
        ...defaultVals,
        requiredSkills: [...selectedSkills],
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
  let defaultVals = {
    "contact.name": `${profileData?.data?.user?.firstName} ${profileData?.data?.user?.lastName}`,
    "contact.email": profileData?.data?.user?.email,
    "contact.phone": profileData?.data?.user?.phone,
  };

  async function CreateVacancy() {
    await axiosInstance.post("/vacancy/create", {
      ...formData,
      ...defaultVals,
      requiredSkills: [...selectedSkills],
    });
  }

  const handleChange = (e) => {
    if (e.target.value == "none") {
      setFormData({
        ...formData,
        [e.target.name]: `Any`,
      });
      return;
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]?.name);
    setFormData({
      ...formData,
      [e.target.name]: e.target.files[0],
    });
  };

  const handleCheckbox = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked,
    });
  };

  async function DeleteVacancy() {
    await axiosInstance.delete(`/vacancy/${load["id"]}`, {});
  }

  const vacancyDeleteMutation = useMutation(DeleteVacancy, {
    onSuccess: () => {
      queryClient.invalidateQueries(["profile"]);
      navigate("/vacancies-posted");
    },
    onError: (err) => err,
  });

  const handleDelete = (e) => {
    e.preventDefault();
    toast.promise(vacancyDeleteMutation.mutateAsync(), {
      pending: "Vacancy is deleting...",
      success: "Vacancy deleted successfully",
      error: "Vacancy delete failed",
    });
  };
  const handlePreview = (e) => {
    e.preventDefault();
  };

  const user = profileData?.data?.user;

  if (profileData && load)
    return (
      <section style={{ margin: "2rem 0rem" }} className="vacan-2 f-list-3">
        <div className="container">
          <div className="bg-color">
            <form>
              <div className="box-flex" style={{ marginBottom: "2rem" }}>
                <h6>Vacancy Information</h6>

                <div className="btn" style={{ alignItems: "baseline" }}>
                  {mode == "EDIT" && (
                    <button className="btn1" onClick={handleDelete}>
                      Delete
                    </button>
                  )}
                  <Preview button="Preview">
                    <VacancyBox
                      noBtn
                      mode="normal"
                      isPreview={isPreviewOn}
                      {...{
                        ...load,
                        ...formData,
                        ...defaultVals,
                        requiredSkills: [...selectedSkills],
                      }}
                    />
                  </Preview>
                  {/* <button className="btn2" onClick={handlePreview}>
                    <ModalBuilder
                      btnText="Preview"
                      isPreview={isPreviewOn}
                      children={
                        <VacancyBox
                          noBtn
                          mode="normal"
                          isPreview={isPreviewOn}
                          {...{
                            ...load,
                            ...formData,
                            ...defaultVals,
                            requiredSkills: [...selectedSkills],
                          }}
                        />
                      }
                    ></ModalBuilder>
                  </button> */}
                  <button onClick={handleSubmit} className="btn3" type="submit">
                    {mode == "EDIT" ? "Update" : "Create"}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6 col-lg-5 col-12 box">
                  <p>Vacancy Title *</p>
                  <input
                    type="name"
                    name="title"
                    onChange={handleChange}
                    onInput={handleChange}
                    defaultValue={load["title"] && load["title"]}
                    className="input"
                    required=""
                    maxLength={50}
                  />
                  <div className="subtitle" style={{ marginTop: "5px" }}>
                    <div className="titel-color">
                      <label>50 Max Characters </label>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-sm-4 box">
                  <p>Publish Vacancy *</p>
                  <select
                    name="publish"
                    onChange={handleChange}
                    defaultValue={load["publish"]}
                  >
                    {/* <DefaultOption name="publish" /> */}
                    <DefaultHiddenOption />

                    <option
                      selected={load["publish"] == true ? true : false}
                      value="true"
                    >
                      Yes
                    </option>
                    <option
                      selected={load["publish"] == false ? true : false}
                      value="false"
                    >
                      No
                    </option>
                  </select>
                </div>
                <div className="col-xl-2 col-lg-2 col-sm-4 box">
                  <p>Expiry Date *</p>
                  <input
                    name="expiryDate"
                    type="date"
                    className="input"
                    defaultValue={load["expiryDate"]?.slice(0, 10)}
                    onChange={handleChange}
                  ></input>
                  {/* <div className="subtitle">
                    <div className="titel-color">
                      <label>DD-MMM-YYYY</label>
                    </div>
                  </div> */}
                </div>
                <div className="col-xl-2 col-lg-3 col-sm-4 box photos">
                  <p>Upload More Vacancy Info.</p>
                  {!load["file"] && !file && (
                    <input
                      id="myPhotos"
                      type="file"
                      accept="application/pdf"
                      name="file"
                      onChange={handleFile}
                    />
                  )}
                  {load["file"] && !file && (
                    <div className="upload-vac">
                      <a href={`/${load["file"]}`}>
                        {load["fileName"] &&
                          textFormatter(`${load["fileName"]}`)}
                      </a>
                      <div
                        onClick={() => {
                          setConfirmWindow("file");
                        }}
                      >
                        Change
                      </div>
                      {confirmWindow == "file" && (
                        <ConfirmationModal
                          message={"delete the file"}
                          onConfirm={() => {
                            setFile("");

                            setFormData((prev) => {
                              return { ...prev, file: "" };
                            });
                            setLoad((prev) => {
                              const { file, ...oldState } = prev;
                              return oldState;
                            });
                          }}
                          close={() => {
                            setConfirmWindow("");
                          }}
                        />
                      )}
                    </div>
                  )}

                  {file && (
                    <div className="upload-vac">
                      <a>{textFormatter(file)}</a>
                      <div onClick={() => setFile("")}>Change</div>
                    </div>
                  )}
                  {/* <input
                    id="myPhotos"
                    type="file"
                    accept="application/pdf"
                    name="file"
                    onChange={handleFile}
                  />
                  {!file.length ? (
                    <a href={`/${load["file"]}`} style={customFileStyle}>
                      {load["fileName"] && `${load["fileName"]}`}
                    </a>
                  ) : (
                    <div style={customFileStyle}>{file}</div>
                  )} */}
                  <div className="subtitle">
                    <div className="titel-color" style={{ marginTop: ".5rem" }}>
                      <label>PDF & Max File 5 MB</label>
                    </div>
                  </div>
                </div>

                <div className="col-12 box">
                  <SkillsSelect
                    set={setSelectedSkills}
                    horizontal
                    label={"Required Main Skills * (Key Words)"}
                    info={
                      "Click Add Skill button after skill selection (Max. 10 skills)"
                    }
                    propose={true}
                    letters={true}
                    initialData={selectedSkills}
                  />

                  <div className="subtitle">
                    <div className="titel-color">
                      <label>Max 10 Skills</label>
                    </div>
                  </div>
                </div>
                <div className="col-12 box" style={{ marginBottom: "2rem" }}>
                  <p>Vacancy Requirements *</p>
                  <textarea
                    style={{ height: "85%" }}
                    type="name"
                    name="requirements"
                    className="input"
                    defaultValue={load["requirements"]}
                    onInput={handleChange}
                    onChange={handleChange}
                    required=""
                    maxLength={500}
                  />
                  <div className="subtitle">
                    <div className="titel-color">
                      <label>500 Max Characters</label>
                    </div>
                  </div>
                </div>
                <div className="box-flex2">
                  <div className="">
                    <h6 className="text-center">Candidate</h6>
                    <div className="box-col">
                      <div className="box">
                        <p>Min Experience *</p>

                        <select
                          defaultValue={load["candidate"]?.minExperience}
                          name="candidate.minExperience"
                          onChange={handleChange}
                        >
                          {/* <DefaultOption name="min experience" /> */}
                          <DefaultHiddenOption />
                          {data?.experiences?.map((exp) => (
                            <option
                              selected={
                                load["candidate"]?.minExperience == exp
                                  ? true
                                  : false
                              }
                              key={exp}
                              value={exp}
                            >
                              {exp}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>
                          1 <sup>st</sup> Language *
                        </p>
                        <select
                          defaultValue={load["candidate"]?.firstLanguage}
                          name="candidate.firstLanguage"
                          className="input"
                          required=""
                          onChange={handleChange}
                        >
                          {/* <DefaultOption name="first language" /> */}
                          <DefaultHiddenOption />
                          {data?.language?.map((lang) => (
                            <option
                              selected={
                                load["candidate"]?.firstLanguage == lang
                                  ? true
                                  : false
                              }
                              key={lang}
                              value={lang}
                            >
                              {lang}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>
                          2<sup>nd</sup> Language{" "}
                        </p>
                        <select
                          defaultValue={load["candidate"]?.secondLanguage}
                          name="candidate.secondLanguage"
                          className="input"
                          required=""
                          onChange={handleChange}
                        >
                          {/* <DefaultOption name="second language" /> */}
                          <DefaultHiddenOption />

                          {data?.language?.map((lang) => (
                            <option
                              key={lang}
                              selected={
                                load["candidate"]?.secondLanguage == lang
                                  ? true
                                  : false
                              }
                              value={lang}
                            >
                              {lang}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Nationality</p>
                        <select
                          onChange={handleChange}
                          name="candidate.nationality"
                          className="input"
                          defaultValue={load["candidate"]?.nationality}
                          required=""
                        >
                          {/* <DefaultOption name="nationality" /> */}
                          <option
                            key={"Any nationality"}
                            value={"Any nationality"}
                          >
                            Any nationality
                          </option>
                          {data?.country?.map((cou) => (
                            <>
                              <option
                                key={cou}
                                selected={
                                  load["candidate"]?.nationality == cou
                                    ? true
                                    : false
                                }
                                value={cou}
                              >
                                {cou}
                              </option>
                              {cou == "Yemen" && (
                                <option value="" disabled="disabled">
                                  ------------------------------------------------------------------------------
                                </option>
                              )}
                            </>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h6 className="text-center">Location</h6>
                    <div className="box-col">
                      <div className="box">
                        <p>City *</p>
                        <input
                          onChange={handleChange}
                          onInput={handleChange}
                          defaultValue={load["location"]?.city}
                          type="name"
                          name="location.city"
                          className="input"
                          required=""
                        />
                      </div>
                      <div className="box">
                        <p>Country *</p>
                        <select
                          onChange={handleChange}
                          defaultValue={load["location"]?.country}
                          name="location.country"
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="country" /> */}
                          <DefaultHiddenOption />
                          {data?.country?.map((cou) => (
                            <>
                              <option
                                key={cou}
                                selected={
                                  load["location"]?.country == cou
                                    ? true
                                    : false
                                }
                                value={cou}
                              >
                                {cou}
                              </option>
                              {cou == "Yemen" && (
                                <option value="" disabled="disabled">
                                  ------------------------------------------------------------------------------
                                </option>
                              )}
                            </>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Work Station *</p>
                        <select
                          onChange={handleChange}
                          name="location.workstation"
                          defaultValue={load["location"]?.workstation}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="workstation" /> */}
                          <DefaultHiddenOption />

                          {data?.workStation?.map((work) => (
                            <option
                              key={work}
                              selected={
                                load["location"]?.workstation == work
                                  ? true
                                  : false
                              }
                              value={work}
                            >
                              {work}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Travel Required</p>
                        <select
                          onChange={handleChange}
                          name="location.travelRequired"
                          defaultValue={load["location"]?.travelRequired}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="Travel required" /> */}
                          <DefaultHiddenOption />

                          {data?.travelWillingness?.map((travel) => (
                            <option
                              key={travel}
                              selected={
                                load["location"]?.travelRequired == travel
                                  ? true
                                  : false
                              }
                              value={travel}
                            >
                              {travel}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h6 className="text-center">Time</h6>
                    <div className="box-col ">
                      <div className="box">
                        <p>Start Date *</p>
                        <input
                          onChange={handleChange}
                          type="date"
                          placeholder="Immediate"
                          name="time.startDate"
                          className="input"
                          defaultValue={load["time"]?.startDate}
                          required=""
                        />
                        {/* <div className="subtitle">
                          <div className="titel-color">
                            <label>DD-MMM-YYYY</label>
                          </div>
                        </div> */}
                      </div>
                      <div className="box">
                        <p>Time Period *</p>
                        <select
                          onChange={handleChange}
                          name="time.timePeriod"
                          defaultValue={load["time"]?.timePeriod}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="time period" /> */}
                          <DefaultHiddenOption />

                          {data?.timePeriod?.map((time) => (
                            <option
                              key={time}
                              selected={
                                load["time"]?.timePeriod == time ? true : false
                              }
                              value={time}
                            >
                              {time}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Min. Availability *</p>
                        <select
                          onChange={handleChange}
                          name="time.minAvailability"
                          defaultValue={load["time"]?.minAvailability}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="min availability" /> */}
                          <DefaultHiddenOption />

                          {data?.availability?.map((avail) => (
                            <option
                              key={avail}
                              selected={
                                load["time"]?.minAvailability == avail
                                  ? true
                                  : false
                              }
                              value={avail}
                            >
                              {avail}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Project Phase: *</p>
                        <select
                          onChange={handleChange}
                          name="time.projectPhase"
                          defaultValue={load["time"]?.projectPhase}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="project phase" /> */}
                          <DefaultHiddenOption />

                          {data?.projectPhase?.map((phase) => (
                            <option
                              key={phase}
                              selected={
                                load["time"]?.projectPhase == phase
                                  ? true
                                  : false
                              }
                              value={phase}
                            >
                              {phase}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h6 className="text-center">Payment</h6>
                    <div className="box-col">
                      <div className="box">
                        <p>Hourly Rate ($) *</p>
                        {/* <div className="box-flex"> */}
                        <div>
                          <select
                            onChange={handleChange}
                            name="payment.hourlyRate"
                            defaultValue={load["payment"]?.hourlyRate}
                            className="input"
                            required=""
                          >
                            {/* <DefaultOption name="hourly rate" /> */}
                            <DefaultHiddenOption />

                            {data?.hourlyRate?.map((rate) => (
                              <option
                                key={rate}
                                selected={
                                  load["payment"]?.hourlyRate == rate
                                    ? true
                                    : false
                                }
                                value={rate}
                              >
                                {rate}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="box">
                        <p>Pay Type *</p>
                        <select
                          onChange={handleChange}
                          name="payment.payType"
                          defaultValue={load["payment"]?.payType}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="pay type" /> */}
                          <DefaultHiddenOption />

                          {data?.payType?.map((pay) => (
                            <option
                              key={pay}
                              selected={
                                load["payment"]?.payType == pay ? true : false
                              }
                              value={pay}
                            >
                              {pay}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="box">
                        <p>Flights *</p>
                        <select
                          name="payment.flights"
                          defaultValue={load["payment"]?.flights}
                          onChange={handleChange}
                          className="input"
                          required=""
                        >
                          {/* <DefaultOption name="flights" /> */}
                          <DefaultHiddenOption />

                          <option
                            selected={
                              load["payment"]?.flights == "Paid" ? true : false
                            }
                            value="Paid"
                          >
                            Paid
                          </option>
                          <option
                            selected={
                              load["payment"]?.flights == "Not Paid"
                                ? true
                                : false
                            }
                            value="Not Paid"
                          >
                            Not Paid
                          </option>
                        </select>
                      </div>
                      <div className="box">
                        <p>Hotel *</p>
                        <select
                          name="payment.hotel"
                          defaultValue={load["payment"]?.hotel}
                          onChange={handleChange}
                        >
                          {/* <DefaultOption name="hotel" /> */}
                          <DefaultHiddenOption />

                          <option
                            selected={
                              load["payment"]?.hotel == "Paid" ? true : false
                            }
                            value="Paid"
                          >
                            Paid
                          </option>
                          <option
                            selected={
                              load["payment"]?.hotel == "Not Paid"
                                ? true
                                : false
                            }
                            value="Not Paid"
                          >
                            Not Paid
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h6 className="text-center">Contact</h6>
                    <div className="">
                      <div className="box">
                        <div className="box">
                          <p>Name *</p>
                          <input
                            type="name"
                            disabled
                            name="contact.name"
                            className="input"
                            required=""
                            value={fullName(user?.firstName, user?.lastName)}
                          />
                        </div>
                      </div>
                      <div className="box">
                        <p>Email *</p>
                        <input
                          type="email"
                          name="contact.email"
                          className="input"
                          disabled
                          required=""
                          value={user?.email}
                        />
                      </div>
                      <div className="box">
                        <p>Phone</p>
                        <input
                          type="name"
                          name="contact.phone"
                          className="input"
                          required=""
                          disabled
                          value={user?.phone}
                        />
                        <div className="subtitle" style={{ direction: "ltr" }}>
                          <div
                            style={{
                              gap: "1rem",
                              justifyContent: "flex-start",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="isPhonePublic"
                              defaultChecked={load["isPhonePublic"]}
                              onClick={handleCheckbox}
                            />
                            <label>Show phone to public</label>
                          </div>
                          <div
                            style={{
                              gap: "1rem",
                              justifyContent: "flex-start",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="isPhoneRegistered"
                              defaultChecked={load["isPhoneRegistered"]}
                              onClick={handleCheckbox}
                            />
                            <label>Show phone to registered people</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="box-content">
                    <h6>Vacancy Highlight:</h6>
                    <div>
                      <input
                        type="checkbox"
                        name="isTrainer"
                        defaultChecked={load["isTrainer"]}
                        onClick={handleCheckbox}
                        id="trainerButton"
                      />
                      <label htmlFor="trainerButton" className="btn-color1">
                        Trainer
                      </label>
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        name="isConsultant"
                        defaultChecked={load["isConsultant"]}
                        onClick={handleCheckbox}
                        id="consultantButton"
                      />
                      <label htmlFor="consultantButton" className="btn-color2">
                        Consultant
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <h6 className="title-requ">* Required Field</h6>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
};

function DefaultHiddenOption() {
  return (
    <option value="" hidden>
      Choose an option
    </option>
  );
}
export default VacancyForm;
